import React from 'react'

function GoogleAdsOverview() {
  return (
    <div className="bg-white">
      <h1 className="text-4xl p-4 font-helvetica font-semibold">Overview</h1>
    </div>
  )
}

export default GoogleAdsOverview;
