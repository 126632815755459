/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from "react";
import {
  FaFilter,
  FaColumns,
  FaExpand,
  FaCompress,
  FaGripLines,
  FaLayerGroup,
} from "react-icons/fa";
import LoadingAnimation from "../components/LoadingAnimation";
import axios from "axios";
import { DateRangePicker } from "react-date-range";
import "../styles/CustomDateRangePicker.css";
import { format, isYesterday, isToday } from "date-fns";
import { checkMark } from "../assets";
import { MdOutlineSegment } from "react-icons/md";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdOutlineFileDownload } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import CampaignsViewBy from "./CampaignsViewBy";
import Switcher7 from "./Tools/Switcher";
import {
  Autocomplete,
  TextField,
  Button,
  Box,
  Checkbox,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Chip,
} from "@mui/material";

import CampaignsGroupBy from "./CampaignsGroupBy";
import { Link } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";

const CampaignsTable = () => {
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [isGroupDropdownVisible, setGroupDropdownVisible] = useState(false);
  const [groups, setGroups] = useState(false);
  const [showCustomColumnForm, setShowCustomColumnForm] = useState(false);
  const [showColumnsMenu, setShowColumnsMenu] = useState(false);
  const [isDialogOpenCreateGroup, setIsDialogOpenCreateGroup] = useState(false);

  const [showDownloadOptions, setShowDownloadOptions] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [isVisibleGroupInput, setIsVisibleGroupInput] = useState(false);

  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const metricRef = useRef(null);
  const operatorRef = useRef(null);
  const metricInputRef = useRef(null);
  const nameInputRef = useRef(null);

  function filterSearchData() {
    let inputName = nameInputRef.current.value;
    let inputMetric = metricInputRef.current.value;
    let metricValue = metricRef.current.value;
    let operatorValue = operatorRef.current.value;

    console.log(inputName, inputMetric, metricValue, operatorValue);

    // Filter
    let prevData = data;
    const startDate = format(state[0].startDate, "yyyy-MM-dd");
    const endDate = format(state[0].endDate, "yyyy-MM-dd");

    let requestBody = {
      customer_id:
        localStorage.getItem("customer_id") == "Not Connected"
          ? "4643036315"
          : localStorage.getItem("customer_id"),
    };

    if (startDate === endDate) {
      requestBody = { ...requestBody, single_date: startDate };
    } else {
      requestBody = {
        ...requestBody,
        start_date: startDate,
        end_date: endDate,
      };
    }

    setData([]);

    axios
      .post(
        "https://api.confidanto.com/get-datewise-campaign-data",
        requestBody
      )
      .then((res) => {
        let rawData = res.data;

        // let tempData = rawData.filter(item=> (item.name.toLowerCase()).startsWith(e.target.value.toLowerCase()))

        let tempData = rawData.filter((item) =>
          item.name.toLowerCase().includes(inputName.toLowerCase())
        );

        tempData = tempData.filter((item) => {
          if (operatorRef.current.value == "more") {
            return item[metricRef.current.value] > inputMetric;
          } else if (operatorRef.current.value == "less") {
            return item[metricRef.current.value] < inputMetric;
          } else {
            return item[metricRef.current.value] == inputMetric;
          }
        });

        console.log("Some Function ", tempData);
        if (tempData.length <= 0) {
          console.log("No data Found");
          alert("No Data Found");
          setData(rawData);
        } else {
          console.log("Data Found");
          setData(tempData);
        }
      })
      .catch(() => {
        setData(prevData);
      });
  }

  const [filterStatus, setFilterStatus] = useState([]);
  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilterStatus(typeof value === "string" ? value.split(",") : value);

    // Auto-apply filter after selection
    const filteredData = tempdata.filter((item) =>
      // console.log(item.status,value)
      value.includes(item.status)
    );

    if (value.length > 0) {
      setData(filteredData);
    } else {
      setData(tempdata);
    }
  };

  const [random, setRandom] = useState(Math.random());

  let d = new Date();
  let year = d.getFullYear();
  let month = d.getMonth();
  const [columns, setColumns] = useState([
    {
      id: "1",
      title: "Campaign",
      key: "name",
      visible: true,
      locked: true,
      category: "Recommended",
    },
    {
      id: "2",
      title: "Budget",
      key: "budget_micros",
      visible: true,
      category: "Recommended",
    },
    {
      id: "3",
      title: "Status",
      key: "status",
      visible: true,
      locked: true,
      category: "Recommended",
    },
    {
      id: "4",
      title: "Interaction rate",
      key: "interaction_rate",
      visible: true,
      category: "Performance",
    },
    {
      id: "5",
      title: "Avg. cost",
      key: "avg_cost",
      visible: true,
      category: "Performance",
    },
    {
      id: "6",
      title: "Cost",
      key: "costs",
      visible: true,
      category: "Performance",
    },
    {
      id: "7",
      title: "Clicks",
      key: "clicks",
      visible: true,
      category: "Performance",
    },
    {
      id: "8",
      title: "Invalid Clicks",
      key: "invalid_clicks",
      visible: false,
      category: "Performance",
    },
    {
      id: "9",
      title: "Avg. CPC",
      key: "average_cpc",
      visible: true,
      category: "Performance",
    },
    {
      id: "10",
      title: "Impr.(Abs.Top)%",
      key: "absolute_top_impression_percentage",
      visible: false,
      category: "Performance",
    },
    {
      id: "11",
      title: "Invalid click rate",
      key: "invalid_click_rate",
      visible: false,
      category: "Performance",
    },
    {
      id: "12",
      title: "Impr. (Top) %",
      key: "top_impression_percentage",
      visible: false,
      category: "Performance",
    },
    {
      id: "13",
      title: "Avg. Target Roas",
      key: "average_target_roas",
      visible: false,
      category: "Performance",
    },
    {
      id: "14",
      title: "CTR",
      key: "ctr",
      visible: false,
      category: "Performance",
    },
    {
      id: "15",
      title: "Avg.target CPA",
      key: "average_target_cpa_micros",
      visible: false,
      category: "Performance",
    },
    {
      id: "16",
      title: "Interactions",
      key: "interactions",
      visible: false,
      category: "Performance",
    },
    {
      id: "17",
      title: "Conversions",
      key: "conversion",
      visible: true,
      category: "Conversions",
    },
    {
      id: "18",
      title: "Cost/conv.",
      key: "cost_per_conv",
      visible: true,
      category: "Conversions",
    },
    {
      id: "19",
      title: "New Customer Lifetime Value",
      key: "new_customer_lifetime_value",
      visible: false,
      category: "Conversions",
    },
    {
      id: "20",
      title: "All New Customer Lifetime Value",
      key: "all_new_customer_lifetime_value",
      visible: false,
      category: "Conversions",
    },
    {
      id: "21",
      title: "Cross Sell Cost Of Goods Sold",
      key: "cross_sell_cost_of_goods_sold_micros",
      visible: false,
      category: "Conversions",
    },
    {
      id: "22",
      title: "Lead Cost Of Goods Sold ",
      key: "lead_cost_of_goods_sold_micros",
      visible: false,
      category: "Conversions",
    },
    {
      id: "23",
      title: "Cross Sell Revenue Micros",
      key: "cross_sell_revenue_micros",
      visible: false,
      category: "Conversions",
    },
    {
      id: "24",
      title: "Lead Gross Profit Micros",
      key: "lead_gross_profit_micros",
      visible: false,
      category: "Conversions",
    },
    {
      id: "25",
      title: "Lead Revenue Micros",
      key: "lead_revenue_micros",
      visible: false,
      category: "Conversion",
    },
    {
      id: "26",
      title: "Conv.value",
      key: "conversions_value",
      visible: false,
      category: "Conversion",
    },
    {
      id: "27",
      title: "Revenue",
      key: "revenue_micros",
      visible: false,
      category: "Conversion",
    },
    {
      id: "28",
      title: "all Conv.value",
      key: "all_conversions_value",
      visible: false,
      category: "Performance",
    },
    {
      id: "29",
      title: "Cost Of Goods Sold",
      key: "cost_of_goods_sold_micros",
      visible: false,
      category: "Conversion",
    },
    {
      id: "30",
      title: "Gross Profit Margin",
      key: "gross_profit_margin",
      visible: false,
      category: "Conversion",
    },
    {
      id: "31",
      title: "Cost/Conv.",
      key: "cost_per_conversion",
      visible: false,
      category: "Conversion",
    },
    {
      id: "32",
      title: "Cross-sell Gross Profit ",
      key: "cross_sell_gross_profit_micros",
      visible: false,
      category: "Conversion",
    },
    {
      id: "33",
      title: "Cross-device Conversions Value ",
      key: "cross_device_conversions_value_micros",
      visible: false,
      category: "Conversion",
    },
    {
      id: "34",
      title: "Conv.rate",
      key: "conversions_from_interactions_rate",
      visible: false,
      category: "Conversion",
    },
    {
      id: "35",
      title: "All Conversions Value By Conversion Date",
      key: "all_conversions_value_by_conversion_date",
      visible: false,
      category: "Conversion",
    },
    {
      id: "36",
      title: "Cross-device Conv.",
      key: "cross_device_conversions",
      visible: false,
      category: "Conversion",
    },
    {
      id: "37",
      title: "Cost/all Conv.",
      key: "cost_per_all_conversions",
      visible: false,
      category: "Conversion",
    },
    {
      id: "38",
      title: "Cross-sell Units Sold",
      key: "cross_sell_units_sold",
      visible: false,
      category: "Conversion",
    },

    {
      id: "39",
      title: "View-through Conv.",
      key: "view_through_conversions",
      visible: false,
      category: "Conversion",
    },
    {
      id: "40",
      title: "Lead Units Sold",
      key: "lead_units_sold",
      visible: false,
      category: "Conversion",
    },
    {
      id: "41",
      title: "Units Sold",
      key: "units_sold",
      visible: false,
      category: "Conversion",
    },
    {
      id: "42",
      title: "Value/Conv.",
      key: "value_per_conversion",
      visible: false,
      category: "Conversion",
    },
    {
      id: "43",
      title: "Search Budget Lost Absolute Top Impression Share",
      key: "search_budget_lost_absolute_top_impression_share",
      visible: false,
      category: "Attribution",
    },
    {
      id: "44",
      title: "Current Model Attributed Conversions Value Per Cost",
      key: "current_model_attributed_conversions_value_per_cost",
      visible: false,
      category: "Attribution",
    },
    {
      id: "45",
      title: "Bid strategy",
      key: "bidding_strategy",
      visible: false,
      category: "Attributes",
    },
    {
      id: "46",
      title: "Target Roas",
      key: "target_roas",
      visible: false,
      category: "Attributes",
    },
    {
      id: "47",
      title: "Bidding Strategy Type",
      key: "bidding_strategy_type",
      visible: false,
      category: "Attributes",
    },
    {
      id: "48",
      title: " Conv./Value(Current Model)",
      key: "current_model_attributed_conversions_from_interactions_value_per_interaction",
      visible: false,
      category: "Attributes",
    },

    {
      id: "49",
      title: "Target CPA",
      key: "target_cpa_micros",
      visible: false,
      category: "Attributes",
    },
    {
      d: "50",
      title: "Search exact match IS ",
      key: "search_exact_match_impression_share",
      visible: false,
      category: "Competitive metrics",
    },
    {
      id: "51",
      title: "Impressions",
      key: "impressions",
      visible: true,
      category: "Competitive metrics",
    },
    {
      id: "52",
      title: "Search Lost IS (Rank)",
      key: "search_rank_lost_impression_share",
      visible: false,
      category: "Competitive metrics",
    },
    {
      id: "53",
      title: "Search lost top IS (Rank)",
      key: "search_rank_lost_top_impression_share",
      visible: false,
      category: "Competitive metrics",
    },
    {
      id: "54",
      title: "Search lost top IS(Budget)",
      key: "search_budget_lost_top_impression_share",
      visible: false,
      category: "Competitive metrics",
    },

    {
      id: "55",
      title: "Search Impr. Share",
      key: "search_impression_share",
      visible: false,
      category: "Competitive metrics",
    },
    {
      id: "56",
      title: "Click Share",
      key: "search_click_share",
      visible: false,
      category: "Competitive metrics",
    },
    {
      id: "57",
      title: "Search lost Abs top IS (Rank)",
      key: "search_rank_lost_absolute_top_impression_share",
      visible: false,
      category: "Competitive metrics",
    },
    {
      id: "58",
      title: "Search Top IS",
      key: "search_top_impression_share",
      visible: false,
      category: "Competitive metrics",
    },
    {
      id: "59",
      title: "Search lost IS (Budget)",
      key: "search_budget_lost_impression_share",
      visible: false,
      category: "Competitive metrics",
    },
    {
      id: "60",
      title: "Search Abs top IS",
      key: "search_absolute_top_impression_share",
      visible: false,
      category: "Competitive metrics",
    },
    {
      id: "61",
      title: "Phone Calls",
      key: "phone_calls",
      visible: false,
      category: "Call details",
    },
    {
      id: "62",
      title: "Phone Impressions",
      key: "phone_impressions",
      visible: false,
      category: "Call details",
    },
    {
      id: "63",
      title: "Phone Through Rate",
      key: "phone_through_rate",
      visible: false,
      category: "Call details",
    },
    {
      id: "64",
      title: "Avg. Cpm",
      key: "avg_cpm",
      visible: false,
      category: "Performance",
    },
    {
      id: "64",
      title: "Group Name",
      key: "group_name",
      visible: false,
      category: "Attributes",
    },
    {
      id: "65",
      title: "Value/All Conversions By Conversion Date",
      key: "value_per_all_conversions_by_conversion_date",
      visible: false,
      category: "Performance",
    },
    {
      id: "66",
      title: "Advertising Channel Sub Type",
      key: "advertising_channel_sub_type",
      visible: false,
      category: "Attribution",
    },
    {
      id: "67",
      title: "Advertising Channel Type",
      key: "advertising_channel_type",
      visible: false,
      category: "Attribution",
    },
    {
      id: "68",
      title: "Optimization Score",
      key: "optimization_score",
      visible: false,
      category: "Performance",
    },
    {
      id: "69",
      title: "Conversions By Conversion Date",
      key: "conversions_by_conversion_date",
      visible: false,
      category: "Performance",
    },
    // {id: "1", title: 'Group Id', key: 'group_id', visible: false },
    {
      id: "70",
      title: "Average Cart Size",
      key: "average_cart_size",
      visible: false,
      category: "Performance",
    },
    // {id: "1", title: 'Amount Micros', key: 'amount_micros', visible: false },
    {
      id: "70",
      title: "Id",
      key: "id",
      visible: false,
      category: "Attributes",
    },
    {
      id: "71",
      title: "Customer Id",
      key: "customer_id",
      visible: false,
      category: "Attributes",
    },
    {
      id: "72",
      title: "Channel Type",
      key: "channel_type",
      visible: false,
      category: "Attributes",
    },
  ]);

  const PercentColumns = [
    "impressions_percent_diff",
    "costs_percent_diff",
    "clicks_percent_diff",
    "conversion_percent_diff",
    "ctr_percent_diff",
    "cost_per_conv_percent_diff",
    "average_cpc_percent_diff",
  ];

  const [defaultCampaignColumns, setDefaultCampaignColumns] = useState(columns);

  let [viewByColumns, setViewByColumns] = useState([
    {
      id: "1",
      title: "date",
      key: "date",
      visible: true,
      locked: true,
      category: "Recommended",
    },
    {
      id: "2",
      title: "Cost",
      key: "cost",
      visible: true,
      locked: true,
      category: "Recommended",
    },
    {
      id: "3",
      title: "Clicks",
      key: "clicks",
      visible: true,
      locked: true,
      category: "Performance",
    },
    {
      id: "4",
      title: "Impr.",
      key: "impressions",
      visible: true,
      category: "Performance",
      locked: true,
    },
    {
      id: "5",
      title: "Conversions",
      key: "conversions",
      visible: false,
      category: "Conversion",
    },
    {
      id: "6",
      title: "Cost/Conv",
      key: "cost_per_conversion",
      visible: false,
      category: "Conversion",
    },
    {
      id: "7",
      title: "CTR",
      key: "ctr",
      visible: false,
      category: "Performance",
    },
    {
      id: "8",
      title: "Avg CPC",
      key: "average_cpc",
      visible: false,
      category: "Performance",
    },
    {
      id: "9",
      title: "Conversion Rate",
      key: "conversion_rate",
      visible: false,
      category: "Conversion",
    },
  ]);

  const [tableVisible, setTableVisible] = useState(true);
  const [data, setData] = useState([]);
  const [tempdata, setTempData] = useState([]);
  const datePickerRef = useRef(null);
  // const totalImpressions = data.reduce((sum, row) => sum + row.impressions, 0);
  // const totalClicks = data.reduce((sum, row) => sum + row.clicks, 0);
  // const totalConversions = data.reduce((sum, row) => sum + row.conversions, 0);
  // const totalCost = data.reduce((sum, row) => sum + parseFloat(row.cost), 0);
  // const totalCTR =
  //   totalClicks > 0 ? ((totalClicks / totalImpressions) * 100).toFixed(2) : 0;
  // const totalConversionRate =
  //   totalClicks > 0 ? ((totalConversions / totalClicks) * 100).toFixed(2) : 0;
  // const totalAvgCPC =
  //   totalClicks > 0 ? (totalCost / totalClicks).toFixed(2) : 0;

  const [customColumns, setCustomColumns] = useState([]);
  useEffect(() => {
    //console.log(localStorage.getItem("customer_id")== "Not Connected"?"4643036315":localStorage.getItem("customer_id"));
    axios
      .post("https://api.confidanto.com/custom_columns/get-custom-columns", {
        email: localStorage.getItem("email"),
      })
      .then((res) => {
        let resData = res.data.data;
        let temp = resData.map((i) => {
          let obj = {
            id: i.id,
            title: i.custom_column,
            key: i.custom_column,
            category: "Custom Columns",
            visible: false,
          };
          console.log("ci", i);

          return obj;
        });
        console.log("temp: ", temp);

        setCustomColumns(temp);
        setColumns([...columns, ...temp]);
        console.log("custom columns: ", customColumns, columns);
      });
    fetch("https://api.confidanto.com/get-datewise-campaign-data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_id:
          localStorage.getItem("customer_id") == "Not Connected"
            ? "4643036315"
            : localStorage.getItem("customer_id"),
        date_range: "THIS_MONTH",
        email: localStorage.getItem("email"),
        // customer_id: 4643036315,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log("campaign data:",data)
        setData(data);
        setTempData(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [random]);

  // Checkbox code
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allSelectedRows = data.map((item) => ({
        id: item.id,
        campaign_name: item.name,
      }));
      setSelectedRows(allSelectedRows);
    } else {
      setSelectedRows([]);
    }
  };
  const downloadData = (format) => {
    const visibleColumns = columns.filter((col) => col.visible);
    const headers = visibleColumns.map((col) => col.title);
    const rows = data.map((item) => visibleColumns.map((col) => item[col.key]));

    if (format === "pdf") {
      const doc = new jsPDF();
      autoTable(doc, { head: [headers], body: rows });
      doc.save("data.pdf");
    } else if (format === "csv" || format === "excel") {
      const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      if (format === "csv") {
        XLSX.writeFile(wb, "data.csv");
      } else {
        XLSX.writeFile(wb, "data.xlsx");
      }
    } else if (format === "xml") {
      const xmlContent = `
        <root>
          ${data
            .map(
              (item) => `
            <row>
              ${visibleColumns
                .map((col) => `<${col.key}>${item[col.key]}</${col.key}>`)
                .join("")}
            </row>
          `
            )
            .join("")}
        </root>
      `;
      const blob = new Blob([xmlContent], { type: "application/xml" });
      FileSaver.saveAs(blob, "data.xml");
    } else if (format === "google_sheets") {
      const csvContent = [
        headers.join(","),
        ...rows.map((row) => row.join(",")),
      ].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = `https://docs.google.com/spreadsheets/d/your-sheet-id/edit?usp=sharing`;
      window.open(url, "_blank");
      FileSaver.saveAs(blob, "data.csv");
    }

    setShowDownloadOptions(false);
  };

  const handleCheckboxChange = (id, campaign_name) => {
    const isSelected = selectedRows.some((row) => row.id === id);

    if (isSelected) {
      setSelectedRows(selectedRows.filter((row) => row.id !== id));
    } else {
      setSelectedRows([...selectedRows, { id, campaign_name }]);
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await axios.post(
        "https://api.confidanto.com/campaign-group/fetch-groups",
        {
          email: localStorage.getItem("email"),
          customer_id: localStorage.getItem("customer_id") == "Not Connected"
            ? "4643036315"
            : localStorage.getItem("customer_id"),
        }
      );

      if (response.data.groups) {
        const groupsData = response.data.groups;
        setSavedGroups(groupsData);

        // Extract group IDs
        const names = groupsData.map((group) => group.group_name);
        setGroupNames(names);
      }
    } catch (error) {
      console.error("Error fetching campaign groups:", error);
    }
  };

  const handleCreateGroup = async () => {
    setIsVisibleGroupInput(true);
  };

  const handleGroupSave = async () => {
    const groupData = {
      group_name: groupName,
      campaign_ids: selectedRows.map((row) => row.id),
      campaign_names: selectedRows.map((row) => row.campaign_name),
      number_of_campaigns: selectedRows.length,
      email: localStorage.getItem("email"), // or any other way to get the email
    };

    try {
      const response = await axios.post(
        "https://api.confidanto.com/campaign-group/create",
        groupData
      );
      if (response.status === 200) {
        // Success: group created
        setIsDialogOpenCreateGroup(true);
        setIsVisibleGroupInput(false);
        setSelectedRows([]);
        fetchGroups();
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        alert("Group name already exists. Please choose a different name.");
      } else {
        // Other errors
        console.error("Error inserting data:", error);
        alert("Failed to insert data");
      }
    }
  };
  const handleGroups = async () => {
    try {
      const email = localStorage.getItem("email");
      const customerId = localStorage.getItem("customer_id");
      const response = await axios.post(
        "https://api.confidanto.com/campaign-group/fetch-groups",
        {
          email,
          customer_id: localStorage.getItem("customer_id") == "Not Connected"
            ? "4643036315"
            : localStorage.getItem("customer_id"),
        }
      );
      setGroups(response.data.groups || []);
      setGroupDropdownVisible(true); // Show dropdown after fetching groups
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };
  const [groupNames, setGroupNames] = useState([]);
  const [savedGroups, setSavedGroups] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isGroupListVisible, setIsGroupListVisible] = useState(false);
  const groupListRef = useRef(null); // Ref for the group name list
  const segmentButtonRef = useRef(null); // Ref for the "Segment" button
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showGroups, setShowGroups] = useState(false);

  const [showViewBy, setShowViewBy] = useState(false);
  const [showViewBySection, setShowViewBySection] = useState(false);
  const [showGroupBySection, setShowGroupBySection] = useState(false);

  const [showFilterBy, setShowFilterBy] = useState(false);

  let today = new Date();
  let priorDate = new Date(today);
  priorDate.setDate(today.getDate() - 30);

  today = today.toJSON().slice(0, 10).replace(/-/g, "-");
  priorDate = priorDate.toJSON().slice(0, 10).replace(/-/g, "-");

  // //console.log('cur date',today,priorDate);
  let [viewByObject, setViewbyObject] = useState({
    groupBy: "date",
    startDate: priorDate,
    endDate: today,
  });

  const changeGroupbyView = (newGroupBy) => {
    let newObj = viewByObject;
    newObj.groupBy = newGroupBy;
    setViewbyObject(newObj);

    if (newGroupBy == "none") {
      setShowViewBySection(false);
      setTableVisible(true);
    } else {
      setShowViewBySection(true);
      setTableVisible(false);
    }

    // toggleFilterDropdown();
  };
  const changeDatebyView = (startdate, enddate) => {
    let newObj = viewByObject;
    newObj.startDate = startdate;
    newObj.endDate = enddate;

    setViewbyObject(newObj);
  };

  today = new Date();
  let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const [state, setState] = useState([
    {
      startDate: firstDayOfMonth,
      endDate: today,
      key: "selection",
    },
  ]);
  useEffect(() => {
    fetchGroups();
  }, []);

  const filterButtonRef = useRef(null);

  const [selectedFilters, setSelectedFilters] = useState({ CampaignType: [] });
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const filterDropdownRef = useRef(null);
  const filterOptions = [
    { name: "Search", value: "SEARCH" },
    { name: "Display", value: "DISPLAY" },
    { name: "Youtube", value: "VIDEO" },
    { name: "P-Max", value: "PERFORMANCE_MAX" },
  ];
  // , 'DISPLAY', 'VIDEO', 'PERFORMANCE_MAX'];

  useEffect(() => {}, []);

  const applyFilters = () => {
    let tempdata = data;

    setData([]);

    axios
      .post("https://api.confidanto.com/get-campaigns-by-campaigns-type", {
        // customer_id:4643036315,
        customer_id:
          localStorage.getItem("customer_id") == "Not Connected"
            ? "4643036315"
            : localStorage.getItem("customer_id"),

        campaign_type: selectedFilters.CampaignType,
      })
      .then((res) => {
        //console.log(res.data);
        setData(res.data);
        setShowFilterDropdown(false);
      })
      .error((e) => {
        setData(tempdata);
      });
    // setData([])
  };

  const handleCheckboxFilter = (filterType, value, checked) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (checked) {
        updatedFilters[filterType] = [
          ...(updatedFilters[filterType] || []),
          value,
        ];
      } else {
        updatedFilters[filterType] = updatedFilters[filterType].filter(
          (v) => v !== value
        );
      }

      return updatedFilters;
    });
  };

  const toggleFilterDropdown = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };

  useEffect(() => {
    if (selectedGroup) {
      const groupData = savedGroups.find(
        (group) => group.group_name === selectedGroup
      );

      if (groupData) {
        // Convert campaignIds to integers for comparison
        const campaignIds = groupData.campaign_ids.map((id) =>
          parseInt(id, 10)
        );

        // Filter the campaigns based on the campaign IDs
        const filteredCampaigns = data.filter((campaign) =>
          campaignIds.includes(campaign.id)
        );

        // Set the filtered data state
        setFilteredData(filteredCampaigns);
      }
    }
  }, [selectedGroup, savedGroups, data]);

  const formatDateDisplay = (date) => {
    if (isToday(date)) {
      return `Today ${format(date, "MMM dd, yyyy")}`;
    } else if (isYesterday(date)) {
      return `Yesterday ${format(date, "MMM dd, yyyy")}`;
    } else {
      return format(date, "MMM dd, yyyy");
    }
  };

  const [dateChanged, setDateChanged] = useState(false);
  const formatButtonLabel = () => {
    const { startDate, endDate } = state[0];

    // Check if start and end dates are in the same month and year
    if (
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear()
    ) {
      // Format as 'Nov 1 - 5, 2024'
      // console.log(" Knjghyfctdxrdfghj 1",startDate, endDate);
      return `${format(startDate, "MMM d")} - ${format(endDate, "d, yyyy")}`;
    } else {
      // Format as 'Nov 1, 2024 - Dec 5, 2024' if they differ
      // console.log(" Knjghyfctdxrdfghj 2",startDate, endDate);
      return `${format(startDate, "MMM d, yyyy")} - ${format(
        endDate,
        "MMM d, yyyy"
      )}`;
    }
  };

  const handleGroupClick = (name) => {
    const startDate = format(state[0].startDate, "yyyy-MM-dd");
    const endDate = format(state[0].endDate, "yyyy-MM-dd");

    let requestBody = {
      // customer_id: 4643036315,
      customer_id:
        localStorage.getItem("customer_id") == "Not Connected"
          ? "4643036315"
          : localStorage.getItem("customer_id"),
      email: localStorage.getItem("email"),
    };

    if (startDate === endDate) {
      // Single date request
      requestBody = { ...requestBody, single_date: startDate };
    } else {
      // Custom date range request
      requestBody = {
        ...requestBody,
        start_date: startDate,
        end_date: endDate,
      };
    }

    changeDatebyView(startDate, endDate);
    // Fetch data based on selected date range
    fetch("https://api.confidanto.com/get-datewise-campaign-data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((responseData) => {
        //console.log("Fetched data:", responseData); // Log response in console
        setData(responseData); // Update the table data
        setColumns([...defaultCampaignColumns, ...customColumns]);

        Object.keys(total).forEach((key) => delete total[key]);

        setShowDatePicker(false);

        // Change Date Format
        dateFormatFunction(startDate, endDate);
        setDateChanged(true);
        setIsSelectInputVisible(true);
      })
      .catch((error) => {
        console.error("Error fetching ad group data:", error);
      });
    setSelectedGroup(name);
    setIsGroupListVisible(false); // Optionally hide the dropdown after selection
  };

  const toggleGroupListVisibility = () => {
    setIsGroupListVisible(!isGroupListVisible);
  };
  // Function to close the group name list when clicking outside
  const handleClickOutside = (event) => {
    if (
      groupListRef.current &&
      !groupListRef.current.contains(event.target) &&
      !segmentButtonRef.current.contains(event.target)
    ) {
      setIsGroupListVisible(false);
    }
  };

  useEffect(() => {
    if (isGroupListVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isGroupListVisible]);
  const fetchAdGroupData = () => {
    if (state.length > 1) {
      const CurrstartDate = format(state[0].startDate, "yyyy-MM-dd");
      const CurrendDate = format(state[0].endDate, "yyyy-MM-dd");
      const PrevstartDate = format(state[1].startDate, "yyyy-MM-dd");
      const PrevendDate = format(state[1].endDate, "yyyy-MM-dd");

      console.log(CurrstartDate, CurrendDate, PrevstartDate, PrevendDate);

      const RequestBody = {
        customer_id: "4643036315",
        start_date: CurrstartDate, // curr date
        end_date: CurrendDate,
        previous_start_date: PrevstartDate, //prev date
        previous_end_date: PrevendDate,
      };

      // It's added 2

      
      // Loading Animation. save data in temp then empty it. if error while loading. load prev data
      let tempData = data
      setShowDatePicker(false);
      setData([])
      axios
        .post(
          "https://api.confidanto.com/get-datewise-campaign-data",
          RequestBody
        )
        .then((res) => {
          console.log("IUYREXRDCFV", res.data);
          setData(res.data); // Update the table data
          // setShowDatePicker(false);

          let newColumns = [
            {
              id: "1",
              title: "Campaign	",
              key: "name",
              visible: true,
              category: "Recommended",
            },
            {
              id: "2",
              title: "Status",
              key: "status",
              visible: true,
              category: "Recommended",
            },
            {
              id: "3",
              title: "Budget",
              key: "budget_micros",
              visible: true,
              category: "Recommended",
            },
            // {title:"Campaign id",  key:"id", visible:true},
            {
              id: "4",
              title: "Impr.",
              key: "impressions",
              visible: true,
              category: "Performance",
            },
            {
              id: "5",
              title: "Impr. %Δ",
              key: "impressions_percent_diff",
              visible: true,
              category: "Performance",
            },
            {
              id: "6",
              title: "Cost",
              key: "costs",
              visible: true,
              category: "Performance",
            },
            {
              id: "7",
              title: "Cost %Δ",
              key: "costs_percent_diff",
              visible: true,
              category: "Performance",
            },
            {
              id: "8",
              title: "Clicks",
              key: "clicks",
              visible: true,
              category: "Performance",
            },
            {
              id: "9",
              title: "Clicks %Δ",
              key: "clicks_percent_diff",
              visible: true,
              category: "Performance",
            },
            {
              id: "10",
              title: "Conversion",
              key: "conversion",
              visible: true,
              category: "Conversion",
            },
            {
              id: "11",
              title: "Conversion %Δ",
              key: "conversion_percent_diff",
              visible: true,
              category: "Conversion",
            },
            {
              id: "12",
              title: "Ctr",
              key: "ctr",
              visible: true,
              category: "Performance",
            },
            {
              id: "13",
              title: "Ctr %Δ",
              key: "ctr_percent_diff",
              visible: true,
              category: "Performance",
            },
            {
              id: "14",
              title: "Cost/Conv",
              key: "cost_per_conv",
              visible: true,
              category: "Conversion",
            },
            {
              id: "15",
              title: "Cost/Conv %Δ",
              key: "cost_per_conv_percent_diff",
              visible: true,
              category: "Conversion",
            },
            {
              id: "16",
              title: "Avg Cpc",
              key: "average_cpc",
              visible: true,
              category: "Performance",
            },
            {
              id: "17",
              title: "Avg Cpc %Δ",
              key: "average_cpc_percent_diff",
              visible: true,
              category: "Performance",
            },
          ];

          Object.keys(total).forEach((key) => delete total[key]);
          setColumns(newColumns);
        })
        .catch(res=>{
          console.log("Error Loading Compare Data");
          setData(tempData)
        })
    } else {
      const startDate = format(state[0].startDate, "yyyy-MM-dd");
      const endDate = format(state[0].endDate, "yyyy-MM-dd");

      let requestBody = {
        // customer_id: 4643036315,
        customer_id:
          localStorage.getItem("customer_id") == "Not Connected"
            ? "4643036315"
            : localStorage.getItem("customer_id"),
        email: localStorage.getItem("email"),
      };

      if (startDate === endDate) {
        // Single date request
        requestBody = { ...requestBody, single_date: startDate };
      } else {
        // Custom date range request
        requestBody = {
          ...requestBody,
          start_date: startDate,
          end_date: endDate,
        };
      }

      changeDatebyView(startDate, endDate);
      // Fetch data based on selected date range

      // Loading Animation. save data in temp then empty it. if error while loading. load prev data
      let tempData = data
      setShowDatePicker(false);
      setData([])
      fetch("https://api.confidanto.com/get-datewise-campaign-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((responseData) => {
          //console.log("Fetched data:", responseData); // Log response in console
          setData(responseData); // Update the table data
          setColumns([...defaultCampaignColumns, ...customColumns]);

          Object.keys(total).forEach((key) => delete total[key]);

          // Change Date Format
          dateFormatFunction(startDate, endDate);
          setDateChanged(true);
          setIsSelectInputVisible(true);
        })
        .catch((error) => {
          setData(tempData)
          console.error("Error fetching ad group data:", error);
        });
    }
  };

  const applyChanges = () => {
    setShowColumnsMenu(false);
    // setTableVisible(true);
  };

  const cancelChanges = () => {
    setShowColumnsMenu(false);
    // setTableVisible(true);
  };
  const toggleColumnsMenu = () => {
    setShowColumnsMenu(!showColumnsMenu);
    // setTableVisible(false);
  };
  const toggleDatePicker = () => {
    // setDateChanged(true)
    setShowDatePicker(!showDatePicker);
  };
  const [selectedGroupIds, SetSelectedGroupIds] = useState([]);
  const handleGroupCheckboxChange = (e) => {
    let checkedId = e.target.value;
    if (e.target.checked) {
      SetSelectedGroupIds([...selectedGroupIds, checkedId]);
    } else {
      SetSelectedGroupIds(selectedGroupIds.filter((id) => id !== checkedId));
    }
  };
  const deleteCustomColumn = (id, name) => {
    let con = window.confirm(`Delete ${name} Column?`);

    if (con) {
      axios
        .post(
          "https://api.confidanto.com/custom_columns/delete-custom-column",
          {
            email: localStorage.getItem("email"),
            id: id,
          }
        )
        .then((res) => {
          // console.log(res.data);
          let newColumns = columns.filter((col) => col.id != id);
          setColumns(newColumns);
          // console.log("old Cols", columns);
          // console.log("new Cols", newColumns);
          // setRandom(Math.random())
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // console.log(id, name)
  };
  const ColumnItem = ({ column, index, toggleVisibility, category }) => {
    return (
      <div className="flex flex-row items-center justify-between  p-2 mb-1 rounded cursor-pointer bg-white shadow-sm hover:bg-slate-100">
        <div className="">
          <input
            type="checkbox"
            checked={column.visible}
            onChange={() => toggleVisibility(column.key)}
            className="mr-2"
            disabled={column.locked}
          />
          <span>{column.title}</span>
        </div>
        {category == "Custom Columns" && (
          <>
            <button
              onClick={() => {
                deleteCustomColumn(column.id, column.title);
              }}
            >
              <RiDeleteBin6Line />
            </button>
          </>
        )}
      </div>
    );
  };
  const toggleColumnVisibility = (key) => {
    setColumns(
      columns.map((col) =>
        col.key === key && !col.locked ? { ...col, visible: !col.visible } : col
      )
    );
    Object.keys(total).forEach((key) => delete total[key]);
  };
  const toggleViewColumnColumnVisibility = (key) => {
    setViewByColumns(
      viewByColumns.map((col) =>
        col.key === key && !col.locked ? { ...col, visible: !col.visible } : col
      )
    );
    Object.keys(total).forEach((key) => delete total[key]);
  };

  const [isSelectInputVisible, setIsSelectInputVisible] = useState(true);
  const CampaignFilterButton = async () => {
    const selectedGroupObjs = savedGroups.filter((d) => {
      return selectedGroupIds.includes(d.group_id.toString());
    });
    const groupNames = selectedGroupObjs.map((d) => d.group_name);
    console.log(groupNames, selectedGroupObjs);

    const startDate = format(state[0].startDate, "yyyy-MM-dd");
    const endDate = format(state[0].endDate, "yyyy-MM-dd");

    let requestBody = {
      customer_id: "4643036315",
      group_names: groupNames,
      email: localStorage.getItem("email"),
    };

    if (startDate === endDate) {
      requestBody = { ...requestBody, single_date: startDate };
    } else {
      requestBody = {
        ...requestBody,
        start_date: startDate,
        end_date: endDate,
      };
    }

    // setIsLoading(true)
    const response = await axios.post(
      "https://api.confidanto.com/get-grouped-campaign-metrics",
      requestBody
    );

    const summarizedMetrics = response.data.metrics.map((group) => {
      let totalObj = group.metrics[1].totals;

      // console.log("Total obj: ",totalObj);

      return {
        group_name: group.group_name,
        // metrics: [totalObj]
        ...totalObj,
      };
    });

    let groupColumns = [
      {
        title: "Group Name",
        category: "Performance",
        visible: true,
        key: "group_name",
      },
      {
        title: "Absolute Top Impression Percentage",
        category: "Performance",
        visible: false,
        key: "absolute_top_impression_percentage",
      },
      {
        title: "All Conversions Value By Conversion Date",
        category: "Performance",
        visible: false,
        key: "all_conversions_value_by_conversion_date",
      },
      {
        title: "Average Cart Size",
        category: "Performance",
        visible: false,
        key: "average_cart_size",
      },
      {
        title: "Average Cost",
        category: "Performance",
        visible: true,
        key: "average_cost",
      },
      {
        title: "Average Cpa",
        category: "Performance",
        visible: false,
        key: "average_cpa",
      },
      {
        title: "Average Cpc",
        category: "Performance",
        visible: false,
        key: "average_cpc",
      },
      {
        title: "Average Cpm",
        category: "Performance",
        visible: false,
        key: "average_cpm",
      },
      {
        title: "Average Target Roas",
        category: "Performance",
        visible: false,
        key: "average_target_roas",
      },
      {
        title: "Clicks",
        category: "Performance",
        visible: true,
        key: "clicks",
      },
      {
        title: "Conversion From Interactions Rate",
        category: "Performance",
        visible: false,
        key: "conversion_from_interactions_rate",
      },
      {
        title: "Conversion Rate",
        category: "Performance",
        visible: false,
        key: "conversion_rate",
      },
      {
        title: "Conversion Value",
        category: "Performance",
        visible: false,
        key: "conversion_value",
      },
      {
        title: "Conversions",
        category: "Performance",
        visible: true,
        key: "conversions",
      },
      {
        title: "Conversions By Conversion Date",
        category: "Performance",
        visible: false,
        key: "conversions_by_conversion_date",
      },
      {
        title: "Cost Of Goods Sold",
        category: "Performance",
        visible: false,
        key: "cost_of_goods_sold",
      },
      {
        title: "Cost Per Conversion",
        category: "Performance",
        visible: false,
        key: "cost_per_conversion",
      },
      { title: "Costs", category: "Performance", visible: false, key: "costs" },
      {
        title: "Cross Device Conversions",
        category: "Performance",
        visible: false,
        key: "cross_device_conversions",
      },
      {
        title: "Cross Device Conversions Value",
        category: "Performance",
        visible: false,
        key: "cross_device_conversions_value",
      },
      {
        title: "Cross Sell Cost Of Goods Sold",
        category: "Performance",
        visible: false,
        key: "cross_sell_cost_of_goods_sold",
      },
      {
        title: "Cross Sell Gross Profit",
        category: "Performance",
        visible: false,
        key: "cross_sell_gross_profit",
      },
      {
        title: "Cross Sell Revenue",
        category: "Performance",
        visible: false,
        key: "cross_sell_revenue",
      },
      {
        title: "Cross Sell Units Sold",
        category: "Performance",
        visible: false,
        key: "cross_sell_units_sold",
      },
      { title: "Ctr", category: "Performance", visible: true, key: "ctr" },
      {
        title:
          "Current Model Attributed Conversions From Interactions Value Per Interaction",
        category: "Performance",
        visible: false,
        key: "current_model_attributed_conversions_from_interactions_value_per_interaction",
      },
      {
        title: "Current Model Attributed Conversions Value Per Cost",
        category: "Performance",
        visible: false,
        key: "current_model_attributed_conversions_value_per_cost",
      },
      {
        title: "Gross Profit",
        category: "Performance",
        visible: false,
        key: "gross_profit",
      },
      {
        title: "Gross Profit Margin",
        category: "Performance",
        visible: false,
        key: "gross_profit_margin",
      },
      {
        title: "Impressions",
        category: "Performance",
        visible: false,
        key: "impressions",
      },
      {
        title: "Interactions",
        category: "Performance",
        visible: false,
        key: "interactions",
      },
      {
        title: "Interactions Rate",
        category: "Performance",
        visible: false,
        key: "interactions_rate",
      },
      {
        title: "Invalid Click Rate",
        category: "Performance",
        visible: false,
        key: "invalid_click_rate",
      },
      {
        title: "Invalid Clicks",
        category: "Performance",
        visible: false,
        key: "invalid_clicks",
      },
      {
        title: "Lead Cost Of Goods Sold",
        category: "Performance",
        visible: false,
        key: "lead_cost_of_goods_sold",
      },
      {
        title: "Lead Revenue",
        category: "Performance",
        visible: false,
        key: "lead_revenue",
      },
      {
        title: "Lead Units Sold",
        category: "Performance",
        visible: false,
        key: "lead_units_sold",
      },
      {
        title: "New Customer Lifetime Value",
        category: "Performance",
        visible: false,
        key: "new_customer_lifetime_value",
      },
      {
        title: "Revenue",
        category: "Performance",
        visible: false,
        key: "revenue",
      },
      {
        title: "Top Impression Percentage",
        category: "Performance",
        visible: false,
        key: "top_impression_percentage",
      },
      {
        title: "Units Sold",
        category: "Performance",
        visible: false,
        key: "units_sold",
      },
      {
        title: "Value Per Conversion",
        category: "Performance",
        visible: false,
        key: "value_per_conversion",
      },
      {
        title: "View Through Conversions",
        category: "Performance",
        visible: false,
        key: "view_through_conversions",
      },
    ];

    Object.keys(total).forEach((key) => delete total[key]);
    setColumns(groupColumns);
    setData(summarizedMetrics);

    console.log("Response: ", summarizedMetrics);

    setIsSelectInputVisible(false);
  };

  const GroupFilterButton = () => {
    const selectedGroupObjs = savedGroups.filter((d) => {
      return selectedGroupIds.includes(d.group_id.toString());
    });
    let groupCampaignIds = [];
    selectedGroupObjs.map((obj) => {
      groupCampaignIds.push(...obj.campaign_ids);
    });
    const tempFilteredCampaigns = data.filter((obj) => {
      return groupCampaignIds.includes(obj.id.toString());
    });
    console.log(selectedGroupObjs, groupCampaignIds, tempFilteredCampaigns);

    setShowGroupBySection(!showGroupBySection);
    setTableVisible(!tableVisible);
  };
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  let [total, setTotal] = useState({});
  let [dateFormatText, setDateFormatText] = useState("This Month");

  function dateFormatFunction(startDate, endDate) {
    let tempVal = "Custom";

    // today yesterday
    if (startDate == endDate) {
      if (isToday(startDate)) {
        tempVal = "Today";
      } else if (isYesterday(startDate)) {
        tempVal = "Yesterday";
      } else {
        // show Custom
        // tempVal = startDate
      }
    }

    // this month, last month
    let thisMonthfirstDay = format(new Date(year, month, 1), "yyyy-MM-dd");
    let thisMonthlastDay = format(new Date(year, month + 1, 0), "yyyy-MM-dd");

    let lastMonthfirstDay = format(new Date(year, month - 1, 1), "yyyy-MM-dd");
    let lastMonthlastDay = format(new Date(year, month, 0), "yyyy-MM-dd");

    if (startDate == thisMonthfirstDay && endDate == thisMonthlastDay) {
      setDateChanged(false);
      tempVal = "This Month";
    }

    if (startDate == lastMonthfirstDay && endDate == lastMonthlastDay) {
      tempVal = "Last Month";
    }

    // This Week
    let first = d.getDate() - d.getDay(); // First day is the day of the month - the day of the week
    let last = first + 6; // last day is the first day + 6

    let thisWeekFirstDay = format(new Date(d.setDate(first)), "yyyy-MM-dd");
    let thisWeekLastDay = format(new Date(d.setDate(last)), "yyyy-MM-dd");

    // Get Last Week
    first = d.getDate() - d.getDay(); // First day is the day of the month - the day of the week
    first = first - 7;
    last = first + 6; // last day is the first day + 6

    let lastWeekFirstDay = format(new Date(d.setDate(first)), "yyyy-MM-dd");

    if (startDate == thisWeekFirstDay && endDate == thisWeekLastDay) {
      tempVal = "This Week";
    }

    if (startDate == lastWeekFirstDay) {
      const diffTime = Math.abs(new Date(startDate) - new Date(endDate));
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays == 6) {
        tempVal = "Last Week";
      }
    }

    setDateFormatText(tempVal);
  }

  function compareDateRanges() {
    let tempStates = state;

    if (state.length > 1) {
      setState([tempStates[0]]);
    } else {
      let newState = {
        startDate: new Date(year, month - 1, 1),
        endDate: new Date(year, month, 0),
        key: "compare",
      };

      setState([...tempStates, newState]);
    }

    console.log(state);
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function handleSelectDateRanges(ranges) {
    let key = Object.keys(ranges)[0]; //curr key
    let values = Object.values(ranges)[0]; //curr obj vals

    // Set Selection Date
    // if(key == "selection"){
    for (const i of state) {
      if (i.key == key) {
        i.startDate = values.startDate;
        i.endDate = values.endDate;
      }
    }
    setState(state);
  }

  const uniqueCategories = Array.from(
    new Set(columns.map((col) => col.category))
  );
  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return; // If the item was dropped outside the list

    const updatedColumns = Array.from(columns);
    const [movedColumn] = updatedColumns.splice(source.index, 1);
    updatedColumns.splice(destination.index, 0, movedColumn);
    setColumns(updatedColumns);
  };
  const toggleCategory = (category) => {
    setExpandedCategory(expandedCategory === category ? null : category);
  };
  const handleCategoryClick = (category) => {
    setGroupDropdownVisible(!isGroupDropdownVisible);
  };


  const toggleDropDownTabs =  (type) => {
    // Calendar
    setShowDatePicker(false)
    // Segment // toggleGroupListVisibility
    setIsGroupListVisible(false)
    // Filter
    setShowFilterDropdown(false)
    // Category
    setGroupDropdownVisible(false)
    // Download 
    setShowDownloadOptions(false)
    // Columns
    setShowColumnsMenu(false)

    if(type == "Calendar"){
      setShowDatePicker(!showDatePicker)
    }else if(type == "Segment"){
      setIsGroupListVisible(!isGroupListVisible)
    }else if(type == "Filter"){
      setShowFilterDropdown(!showFilterDropdown)
    }else if(type == "Category"){
      setGroupDropdownVisible(!isGroupDropdownVisible)
    }else if(type == "Download"){
      setShowDownloadOptions(!showDownloadOptions)
    }else{
      // Columns
      setShowColumnsMenu(!showColumnsMenu)

    }
  }

  return (
    <div>
      <div
        className={`flex h-screen bg-white ${
          isFullScreen
            ? "fixed top-0 left-0 w-full h-full z-50 overflow-x-scroll"
            : "mb-16"
        }`}
      >
        <main className="flex-grow p-6 overflow-hidden flex flex-col font-roboto">
          <div className="flex justify-end items-center mb-4">
            <div className="flex space-x-2">
              <div className="relative" ref={datePickerRef}>
                <span className="mx-2 text-lg text-[#616161]">
                  {dateFormatText}
                </span>
                <button
                  // onClick={toggleDatePicker}
                  onClick={()=>{toggleDropDownTabs("Calendar")}}
                  className="text-base border mr-2 border-gray-400 p-2 w-60"
                >
                  {formatButtonLabel()}
                </button>
                {showDatePicker && (
                  <div className="absolute z-10 mt-2 shadow-lg bg-white right-2">
                    <DateRangePicker
                      // onChange={(item) => setState([...state,item])}

                      onChange={(e) => {
                        handleSelectDateRanges(e);
                      }}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={1}
                      ranges={state}
                      direction="horizontal"
                      maxDate={new Date()}
                    />
                    <div className=" flex flex-row  justify-between items-center mb-2 mx-2">
                      <button
                        onClick={fetchAdGroupData} // Call API when dates are selected
                        className="bg-blue-500 text-white px-4 py-2 rounded text-center mt-2"
                      >
                        Apply
                      </button>

                      <Switcher7
                        onToggle={() => compareDateRanges()}
                        flag={state.length > 1}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="relative">
                <button
                  ref={segmentButtonRef}
                  className="bg-transparent text-gray-600 px-4 py-2 rounded items-center hover:bg-slate-100"
                  // onClick={toggleGroupListVisibility}
                  onClick={()=>{toggleDropDownTabs("Segment")}}
                >
                  <MdOutlineSegment cclassName="ml-5" /> Segment
                </button>

                {isGroupListVisible && (
                  <div
                    className="absolute z-20  w-60 bg-white shadow-lg rounded-lg mt-2 p-4 border border-gray-200"
                    style={{
                      top:
                        segmentButtonRef.current?.offsetTop +
                        segmentButtonRef.current?.offsetHeight,
                      left: segmentButtonRef.current?.offsetLeft,
                    }}
                  >
                    <p className="p-2 text-sm text-gray-400">By</p>

                    <button
                      className="p-2 flex items-center hover:bg-gray-50 cursor-pointer w-full justify-between"
                      onClick={() => setShowViewBy(!showViewBy)}
                    >
                      View By{" "}
                      {showViewBy ? (
                        <IoIosArrowDown className="ml-2 transform rotate-180 transition-transform duration-300 ease-out text-xl" />
                      ) : (
                        <IoIosArrowDown className="ml-2 transform rotate-0 transition-transform duration-300 ease-out text-xl" />
                      )}
                    </button>

                    {showViewBy && (
                      <ul className="mx-2">
                        <li
                          className="p-2 hover:bg-gray-100  capitalize space-x-2 cursor-pointer"
                          onClick={(e) => {
                            changeGroupbyView("none");
                          }}
                        >
                          None
                        </li>
                        <li
                          className="p-2 hover:bg-gray-100  capitalize space-x-2 cursor-pointer"
                          onClick={(e) => {
                            changeGroupbyView("date");
                          }}
                        >
                          Day
                        </li>
                        <li
                          className="p-2 hover:bg-gray-100  capitalize space-x-2 cursor-pointer"
                          onClick={(e) => {
                            changeGroupbyView("week");
                          }}
                        >
                          Week
                        </li>
                        <li
                          className="p-2 hover:bg-gray-100  capitalize space-x-2 cursor-pointer"
                          onClick={(e) => {
                            changeGroupbyView("month");
                          }}
                        >
                          Month
                        </li>
                      </ul>
                    )}

                    <button
                      className="p-2 flex items-center hover:bg-gray-50 cursor-pointer justify-between w-full"
                      onClick={() => setShowFilterBy(!showFilterBy)}
                    >
                      Campaign Types
                      {showFilterBy ? (
                        <IoIosArrowDown className="ml-2 transform rotate-180 transition-transform duration-300 ease-out text-xl" />
                      ) : (
                        <IoIosArrowDown className="ml-2 transform rotate-0 transition-transform duration-300 ease-out text-xl" />
                      )}
                    </button>

                    {showFilterBy && (
                      <div className="mx-2">
                        {/* <h3 className="text-lg font-semibold mb-2">Campaign Type</h3> */}

                        {/* Filter Options as Checkboxes */}
                        <div className="flex flex-col  space-y-2">
                          {filterOptions.map((option) => (
                            <label
                              key={option.name}
                              className="flex items-center"
                            >
                              <input
                                type="checkbox"
                                value={option.value}
                                checked={selectedFilters.CampaignType?.includes(
                                  option.value
                                )}
                                onChange={(e) =>
                                  handleCheckboxFilter(
                                    "CampaignType",
                                    e.target.value,
                                    e.target.checked
                                  )
                                }
                                className="mr-2"
                              />
                              {option.name}
                            </label>
                          ))}
                        </div>

                        <button
                          className="bg-blue-500 text-white px-4 py-2 my-2 rounded-sm w-full"
                          onClick={applyFilters}
                        >
                          Filters
                        </button>
                      </div>
                    )}
                    
                    {/* <Link
                      to="/google-ads/campaign-groups"
                      className="p-2 flex items-center hover:bg-gray-50 cursor-pointer justify-between w-full"
                    >
                      Edit Groups
                    </Link> */}
                  </div>
                  // </div>
                )}
              </div>

              <div ref={filterDropdownRef} className="relative">
                <button
                  className="bg-transparent text-gray-600 px-4 py-2 rounded items-center hover:bg-slate-100"
                  // onClick={toggleFilterDropdown}
                  onClick={()=>{toggleDropDownTabs("Filter")}}

                  ref={filterButtonRef}
                >
                  <FaFilter className="ml-5" /> Add filter
                </button>
                {showFilterDropdown && (
                  <div
                    className="absolute z-20 w-56 bg-white shadow-lg rounded-lg mt-2 p-4 border border-gray-200"
                    style={{
                      top:
                        filterButtonRef.current?.offsetTop +
                        filterButtonRef.current?.offsetHeight,
                      left: filterButtonRef.current?.offsetLeft,
                    }}
                  >
                    <p className="p-2 text-sm text-gray-400">By</p>

                    <button
                      className="p-2 flex items-center hover:bg-gray-50 cursor-pointer w-full justify-between"
                      onClick={() => setIsSearchVisible(!isSearchVisible)}
                    >
                      Search{" "}
                      {isSearchVisible ? (
                        <IoIosArrowDown className="ml-2 transform rotate-180 transition-transform duration-300 ease-out text-xl" />
                      ) : (
                        <IoIosArrowDown className="ml-2 transform rotate-0 transition-transform duration-300 ease-out text-xl" />
                      )}
                    </button>

                    {isSearchVisible && (
                      <ul className="mx-2">
                        <li className="p-2  capitalize space-x-2 ">
                          <span className="p-2 w-full">Name</span>
                          <input
                            type="text"
                            placeholder="Campaign Name"
                            className="p-2 rounded-md w-full"
                            ref={nameInputRef}
                            // onChange={(e) => searchByCampaignName(e)}
                          />
                        </li>

                        <li className="p-2  capitalize space-x-2 ">
                          <span className="p-2 w-full">Metric</span>

                          <select
                            name="metric"
                            id="metric"
                            className="p-2 rounded-md w-full"
                            ref={metricRef}
                          >
                            <option value="budget_micros">Budget</option>
                            <option value="clicks">Clicks</option>
                            <option value="cost">Cost</option>
                            <option value="impressions">Impressions</option>
                          </select>

                          <select
                            name="operator"
                            id="operator"
                            className="p-2 rounded-md w-full"
                            ref={operatorRef}
                          >
                            <option value="more">Greater than</option>
                            <option value="less">Less than</option>
                            <option value="equals">Equals</option>
                          </select>

                          <input
                            type="number"
                            placeholder="Enter number"
                            className="p-2 rounded-md w-full"
                            ref={metricInputRef}
                            // onChange={(e) => searchByCampaignMetric(e)}
                          />
                        </li>

                        <li className="p-2  capitalize space-x-2 ">
                          <button
                            className="bg-blue-500 text-white p-2 rounded-sm text-center w-full "
                            onClick={() => {
                              filterSearchData();
                            }}
                          >
                            Filter
                          </button>
                        </li>
                      </ul>
                    )}

                    <FormControl fullWidth>
                      <InputLabel>Status</InputLabel>
                      <Select
                        label="Status"
                        multiple
                        value={filterStatus}
                        onChange={handleFilterChange}
                        renderValue={(selected) => (
                          <div className="flex flex-wrap">
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                className="m-1 bg-blue-100 text-blue-600"
                              />
                            ))}
                          </div>
                        )}
                        className="bg-gray-100 border rounded-lg"
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              "& .MuiMenuItem-root": {
                                padding: "8px 16px",
                                "&:hover": {
                                  backgroundColor: "#f0f9ff",
                                },
                              },
                            },
                          },
                        }}
                      >
                        <MenuItem value="ENABLED">
                          <Checkbox
                            checked={filterStatus.indexOf("ENABLED") > -1}
                          />
                          ENABLED
                        </MenuItem>
                        <MenuItem value="PAUSED">
                          <Checkbox
                            checked={filterStatus.indexOf("PAUSED") > -1}
                          />
                          PAUSED
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
              <div className="relative">
                <button
                  className="bg-transparent text-gray-600 px-4 py-2 rounded items-center hover:bg-slate-100"
                  // onClick={() => setGroupDropdownVisible(!isGroupDropdownVisible)}
                  onClick={()=>{toggleDropDownTabs("Category")}}

                >
                  <FaLayerGroup className="ml-5" />
                  category
                </button>
                {isGroupDropdownVisible && (
                  <>
                    <div className="absolute right-0 bg-white shadow-md rounded p-4 mt-2 z-20 border w-64 border-gray-200">
                      <ul className="mx-2 h-56 overflow-y-auto">
                        <li
                          className="p-2 hover:bg-gray-100 cursor-pointer capitalize"
                          onClick={() => handleGroupClick(null)}
                        >
                          All
                        </li>
                        {savedGroups.map((data, index) => (
                          <li
                            key={index}
                            className="p-2 hover:bg-gray-100  capitalize space-x-2"
                            // onClick={() => handleGroupClick(name)}
                          >
                            <input
                              type="checkbox"
                              className="cursor-pointer"
                              name=""
                              id=""
                              value={data.group_id}
                              onChange={(e) => handleGroupCheckboxChange(e)}
                            />
                            <span>{data.group_name}</span>
                          </li>
                        ))}
                      </ul>
                      <button
                        className="bg-blue-500 text-white px-4 py-2 my-2 rounded-sm w-full"
                        onClick={CampaignFilterButton}
                      >
                        Filter
                      </button>
 
                      <Link
                        to="/google-ads/campaign-groups"
                        className="p-2 flex items-center hover:bg-gray-50 cursor-pointer justify-between w-full"
                      >
                        Edit Groups
                      </Link>
                    </div>
                  </>
                )}
              </div>
              <div className="relative">
                <button
                  className="bg-transparent text-gray-600 px-4 py-2 rounded items-center hover:bg-slate-100"
                  // onClick={() => setShowDownloadOptions(!showDownloadOptions)}
                  onClick={()=>{toggleDropDownTabs("Download")}}

                >
                  <MdOutlineFileDownload className="ml-5" />
                  Download
                </button>
                {showDownloadOptions && (
                  <div className="absolute right-0 bg-white shadow-md rounded p-4 mt-2 z-20 border border-gray-200">
                    <button
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                      onClick={() => downloadData("pdf")}
                    >
                      PDF
                    </button>
                    <button
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                      onClick={() => downloadData("csv")}
                    >
                      CSV
                    </button>
                    <button
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                      onClick={() => downloadData("excel")}
                    >
                      Excel
                    </button>
                    <button
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                      onClick={() => downloadData("xml")}
                    >
                      XML
                    </button>
                    <button
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                      onClick={() => downloadData("google_sheets")}
                    >
                      Google Sheets
                    </button>
                  </div>
                )}
              </div>
              <button
                className="bg-transparent text-gray-600 px-4 py-2 rounded items-center hover:bg-slate-100"
                onClick={toggleFullScreen}
              >
                {isFullScreen ? (
                  <FaCompress className="ml-5" />
                ) : (
                  <FaExpand className="ml-5" />
                )}{" "}
                {isFullScreen ? "Collapse" : "Expand"}
              </button>
              <div className="relative ">
                <button
                  className="bg-transparent text-gray-600 px-4 py-2 rounded items-center hover:bg-slate-100"
                  // onClick={toggleColumnsMenu}
                  onClick={()=>{toggleDropDownTabs("Columns")}}
                >
                  <FaColumns className="ml-5" /> Columns
                </button>
                {showColumnsMenu && (
                  <div className="absolute right-0 h-max bg-white shadow-md rounded p-4 mt-2 z-20 lg:w-max max-w-6xl border border-gray-200 overflow-auto mb-48">
                    <div className="font-bold mb-0 w-screen max-h-full text-lg text-gray-700 flex overflow-auto">
                      <div className=" justify-between flex items-center border-b-1 w-[1100px] ">
                        <div className=" flex border-r-1  p-4 w-3/4">
                          <h2 className="mr-11">Modify columns</h2>
                          {/* <button
                          className="text-blue-400"
                          onClick={() => setShowCustomColumnForm(true)}
                        >
                          + Custom column
                        </button> */}

                          <Link to="/custom-column" className="text-blue-400">
                            + Custom column
                          </Link>
                        </div>
                        <div className=" justify-start">
                          <h2 className="font-bold mb-4">Your columns</h2>
                        </div>
                      </div>
                    </div>

                    {/* Show Custom Column Form if active */}

                    <div className="flex flex-col overflow-auto">
                      <div className="flex ">
                        <div className="my-6  w-3/4 border-r overflow-auto ">
                          <div className=" space-x-3 space-y-2">
                            {uniqueCategories.map((category) => (
                              <div key={category}>
                                <div
                                  className=""
                                  onClick={() => toggleCategory(category)}
                                >
                                  <span className="p-2 flex items-center hover:bg-gray-50 cursor-pointer w-full justify-between">
                                    {category}{" "}
                                    {expandedCategory === category ? (
                                      <IoIosArrowDown className="ml-2 transform rotate-180 transition-transform duration-300 ease-out text-xl" />
                                    ) : (
                                      <IoIosArrowDown className="ml-2 transform rotate-0 transition-transform duration-300 ease-out text-xl" />
                                    )}
                                  </span>
                                </div>
                                {expandedCategory === category && (
                                  <div className="grid grid-cols-3">
                                    {columns
                                      .filter(
                                        (col) => col.category === category
                                      )
                                      .map((col) => (
                                        <>
                                          <ColumnItem
                                            key={col.key}
                                            column={col}
                                            toggleVisibility={
                                              toggleColumnVisibility
                                            }
                                            category={col.category}
                                          />
                                        </>
                                      ))}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="w-max p-4">
                          <p className="text-sm text-gray-500 mb-4">
                            Drag and drop to reorder
                          </p>
                          <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="columnsList">
                              {(provided) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  className="space-y-2 overflow-auto h-48 border rounded-md p-2"
                                >
                                  {columns
                                    .filter((column) => column.visible) // Only show visible columns
                                    .map((column, index) => (
                                      <Draggable
                                        key={column.id}
                                        draggableId={column.id}
                                        index={index}
                                      >
                                        {(provided) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className="flex items-center p-2 bg-gray-100 mb-1 rounded shadow"
                                          >
                                            <span className="flex items-center gap-4">
                                              <FaGripLines /> {column.title}{" "}
                                            </span>
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                          <div className="flex items-center mt-4">
                            <input
                              type="checkbox"
                              id="saveColumnSet"
                              className="mr-2"
                            />
                            <label htmlFor="saveColumnSet" className="text-sm">
                              Save your column set (name required)
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="flex space-x-2 overflow-auto">
                        <div className="">
                          <button
                            className=" bg-blue-500 text-white px-4  py-2 rounded hover:text-blue-600"
                            onClick={applyChanges}
                          >
                            Apply
                          </button>
                          <button
                            className="text-blue-500  px-4 py-2 rounded hover:text-blue-600"
                            onClick={cancelChanges}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {selectedRows.length >= 2 && (
            <div className="p-2 w-full bg-[#4142dc] flex items-center">
              <p className="text-lg text-white font-normal p-2 my-4 pr-4 border-r-1 border-white">
                {selectedRows.length} selected
              </p>
              <button
                className="bg-transparent text-white text-lg p-2 m-4 font-semibold"
                onClick={handleCreateGroup}
              >
                Create Group
              </button>
              {isVisibleGroupInput && (
                <div className="flex justify-center items-center">
                  <input
                    type="text"
                    className="text-lg text-white bg-transparent border-b-1 border-white p-2 focus:outline-none"
                    placeholder="Group Name"
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                  <button
                    className="bg-white text-[#4142dc] text-lg  m-4 font-semibold px-4 py-1.5"
                    onClick={handleGroupSave}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          )}
          {selectedGroup !== null ? (
            <>
              {tableVisible && (
                <div className="flex-grow overflow-auto">
                  <h1 className="text-2xl font-semibold capitalize py-4">
                    {selectedGroup} Group's Campaigns
                  </h1>
                  {filteredData.length > 0 ? (
                    <>
                      <table className="min-w-full bg-white rounded-lg overflow-hidden shadow-md font-roboto">
                        <thead>
                          <tr className="bg-gray-200 normal-case text-sm leading-normal">
                            <th className="py-3 px-6 text-left font-medium">
                              <input
                                type="checkbox"
                                checked={selectAll}
                                onChange={handleSelectAll}
                              />
                            </th>
                            {columns
                              .filter((col) => col.visible)
                              .map((col) => (
                                <th
                                  key={col.key}
                                  className="py-3 px-6 text-left font-medium"
                                >
                                  {col.title}
                                </th>
                              ))}
                          </tr>
                        </thead>
                        <tbody className="text-gray-600 text-sm font-light font-roboto">
                          {filteredData.map((item, index) => (
                            <tr
                              key={index}
                              className={`border-b   ${
                                selectedRows.some((row) => row.id === item.id)
                                  ? "bg-blue-100 border-gray-300"
                                  : "hover:bg-gray-100 border-gray-200"
                              }`}
                            >
                              <td className="py-3 px-6 text-left">
                                <input
                                  type="checkbox"
                                  checked={selectedRows.some(
                                    (row) => row.id === item.id
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(item.id, item.name)
                                  }
                                />
                              </td>

                              {columns
                                .filter((col) => col.visible)
                                .map((col) => (
                                  <td
                                    key={col.key}
                                    className="py-3 px-6 text-left"
                                  >
                                    {col.key !== "status" &&
                                      (Array.isArray(item[col.key])
                                        ? item[col.key].join(", ")
                                        : item[col.key])}
                                    {/* Render the status cell */}
                                    {PercentColumns.indexOf(col.key) != -1 ? (
                                      <span> %</span>
                                    ) : null}
                                    {col.key === "status" ? (
                                      <div className="flex items-center">
                                        {item.status === "ENABLED" && (
                                          <div className="w-3 h-3 bg-green-500 rounded-full mr-2"></div>
                                        )}
                                        {item.status === "PAUSED" && (
                                          <div className="w-3 h-3 bg-gray-500 rounded-full mr-2"></div>
                                        )}
                                        {item.status === "REMOVED" && (
                                          <div className="w-3 h-3 bg-red-500 rounded-full mr-2"></div>
                                        )}
                                        {/* {item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase()} */}
                                      </div>
                                    ) : null}
                                  </td>
                                ))}
                            </tr>
                          ))}
                        </tbody>
                        <tfoot className="bg-gray-100">
                          <tr className="text-gray-700 font-semibold">
                            <td className="px-4 py-2 ">Total</td>
                            {() => {
                              console.log("gvyfctdxrdctfvygbhjk: ", total);
                              Object.keys(total).forEach(
                                (key) => delete total[key]
                              );
                              console.log("gvyfctdxrdctfvygbhjk: ", total);
                            }}
                            {columns
                              .filter((col) => col.visible)
                              .map((col) => {
                                // //console.log("KEY",col.key)
                                total[col.key] = 0;
                              })}
                            {filteredData.map((d) => {
                              Object.keys(d).forEach((val) => {
                                Object.keys(total).forEach((totalVal) => {
                                  if (totalVal == val) {
                                    total[val] = total[val] + d[val];
                                  }
                                });
                              });
                            })}

                            {Object.entries(total).map((t, k) => {
                              //console.log("type",typeof(t[1]))
                              let tempval = "";
                              let ignoreColumns = [
                                "id",
                                "customer_id",
                                "amount_micros",
                                "campaign_id",
                              ];
                              if (typeof t[1] == "number") {
                                if (ignoreColumns.indexOf(t[0]) == -1) {
                                  tempval = numberWithCommas(t[1].toFixed(2));
                                }

                                if (PercentColumns.indexOf(t[0]) != -1) {
                                  tempval = String(tempval) + " %";
                                }
                              }
                              return (
                                <td className="py-3 px-6 text-left">
                                  {tempval}
                                </td>
                              );
                            })}
                          </tr>
                        </tfoot>
                      </table>
                    </>
                  ) : (
                    <div className="flex justify-center items-center h-40 mt-8">
                      <LoadingAnimation />
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              {tableVisible && (
                <div className="flex-grow overflow-auto mb-12">
                  {data.length > 0 ? (
                    <table className="min-w-full bg-white rounded-lg overflow-hidden shadow-md font-roboto">
                      <thead>
                        <tr className="bg-gray-200 normal-case text-sm leading-normal">
                          {isSelectInputVisible && (
                            <th className="py-3 px-6 text-left font-medium">
                              <input
                                type="checkbox"
                                checked={selectAll}
                                onChange={handleSelectAll}
                              />
                            </th>
                          )}
                          {columns
                            .filter((col) => col.visible)
                            .map((col) => (
                              <th
                                key={col.key}
                                className="py-3 px-6 text-left font-medium"
                              >
                                {col.title}
                              </th>
                            ))}
                        </tr>
                      </thead>
                      <tbody className="text-gray-600 text-sm font-light font-roboto">
                        {data.map((item, index) => (
                          <tr
                            key={index}
                            className={`border-b   ${
                              selectedRows.some((row) => row.id === item.id)
                                ? "bg-blue-100 border-gray-300"
                                : "hover:bg-gray-100 border-gray-200"
                            }`}
                          >
                            {isSelectInputVisible && (
                              <td className="py-3 px-6 text-left">
                                <input
                                  type="checkbox"
                                  checked={selectedRows.some(
                                    (row) => row.id === item.id
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(item.id, item.name)
                                  }
                                />
                              </td>
                            )}

                            {columns
                              .filter((col) => col.visible)
                              .map((col) => (
                                <td
                                  key={col.key}
                                  className="py-3 px-6 text-left"
                                >
                                  {col.key !== "status" &&
                                    (Array.isArray(item[col.key])
                                      ? item[col.key].join(", ")
                                      : item[col.key])}
                                  {/* Render the status cell */}
                                  {PercentColumns.indexOf(col.key) != -1 ? (
                                    <span> %</span>
                                  ) : null}
                                  {col.key === "status" ? (
                                    <div className="flex items-center">
                                      {item.status === "ENABLED" && (
                                        <div className="w-3 h-3 bg-green-500 rounded-full mr-2"></div>
                                      )}
                                      {item.status === "PAUSED" && (
                                        <div className="w-3 h-3 bg-gray-500 rounded-full mr-2"></div>
                                      )}
                                      {item.status === "REMOVED" && (
                                        <div className="w-3 h-3 bg-red-500 rounded-full mr-2"></div>
                                      )}
                                      {/* {item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase()} */}
                                    </div>
                                  ) : null}
                                </td>
                              ))}
                          </tr>
                        ))}
                      </tbody>
                      <tfoot className="bg-gray-100">
                        <tr className="text-gray-700 font-semibold">
                          {isSelectInputVisible && (
                            <td className="px-4 py-2 ">Total</td>
                          )}
                          {() => {
                            console.log("gvyfctdxrdctfvygbhjk: ", total);
                            Object.keys(total).forEach(
                              (key) => delete total[key]
                            );
                            console.log("gvyfctdxrdctfvygbhjk: ", total);
                          }}
                          {columns
                            .filter((col) => col.visible)
                            .map((col) => {
                              // //console.log("KEY",col.key)
                              total[col.key] = 0;
                            })}
                          {data.map((d) => {
                            Object.keys(d).forEach((val) => {
                              Object.keys(total).forEach((totalVal) => {
                                if (totalVal == val) {
                                  total[val] = total[val] + d[val];
                                }
                              });
                            });
                          })}

                          {Object.entries(total).map((t, k) => {
                            //console.log("type",typeof(t[1]))
                            let tempval = "";
                            let ignoreColumns = [
                              "id",
                              "customer_id",
                              "amount_micros",
                              "campaign_id",
                            ];
                            if (typeof t[1] == "number") {
                              if (ignoreColumns.indexOf(t[0]) == -1) {
                                tempval = numberWithCommas(t[1].toFixed(2));
                              }

                              if (PercentColumns.indexOf(t[0]) != -1) {
                                tempval = String(tempval) + " %";
                              }
                            }
                            return (
                              <td className="py-3 px-6 text-left">{tempval}</td>
                            );
                          })}
                        </tr>
                      </tfoot>
                    </table>
                  ) : (
                    <div className="flex justify-center items-center h-40 mt-8">
                      <LoadingAnimation />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {showViewBySection && (
            <div>
              <CampaignsViewBy
                groupBy={viewByObject.groupBy}
                startDate={viewByObject.startDate}
                endDate={viewByObject.endDate}
                //  newColumns={columns}
                columns={viewByColumns}
                setColumns={setViewByColumns}
              />
            </div>
          )}

          {showGroupBySection && (
            <>
              <CampaignsGroupBy
                startDate={viewByObject.startDate}
                endDate={viewByObject.endDate}
                campaigns={data}
                groups={savedGroups}
              />
            </>
          )}
        </main>
      </div>
      {isDialogOpenCreateGroup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          {/* Background Overlay */}
          <div
            className="fixed inset-0 bg-black opacity-70"
            onClick={() => setIsDialogOpenCreateGroup(false)}
          ></div>

          {/* Dialog Box */}
          <div className="relative bg-white p-6 pt-2 rounded shadow-lg w-96">
            {/* Close Button */}
            <button
              className="absolute top-0 right-3 text-gray-500 hover:text-gray-700 text-lg"
              onClick={() => setIsDialogOpenCreateGroup(false)}
            >
              x
            </button>

            <div className="flex flex-col justify-center items-center">
              <img src={checkMark} alt="Success" />
              <p>Your campaign group has been created.</p>
            </div>

            <div className="mt-6 flex justify-end">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                onClick={() => setIsDialogOpenCreateGroup(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignsTable;
