/* eslint-disable eqeqeq */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import LoadingAnimation from "../components/LoadingAnimation";
import { weeklyReportInitialParagraph1 } from "./helper";

import AudienceDataComponent from "./AddTable/Audiences";
import Genderinfo from "./AddTable/Genderinfo";
import Location2Filter from "./Location2Filter";

function AddCampaignTable2() {
  
  let [TableObjectArr, setTableObjectArr] = useState([]);
  let [GroupNames, setGroupNames] = useState([]);
  let [CampaignData, setCampaignData] = useState([]);

  let [formVisible, setformVisible] = useState(false);
  let [fromButtonText, setformButtonText] = useState("Add Table");

  const [selectOptionInput,setSelectOptionInput] = useState("groups")
  let tableTypeRef = useRef(null)

 // Refs
 let categoryRef = useRef(null)
 let impr_ref = useRef(null)
 let cost_ref = useRef(null)
 let click_ref = useRef(null)
 let impr_per_ref = useRef(null)
 let cost_per_ref = useRef(null)
 let click_per_ref = useRef(null)

 
 let ctr_ref = useRef(null)
 let conversion_ref = useRef(null)
 let cost_per_conv_ref = useRef(null)

 const [editingTableIndex, setEditingTableIndex] = useState(null);

  useEffect(() => {
    try {
      axios
        .post("https://api.confidanto.com/campaign-group/fetch-groups", {
          email: localStorage.getItem("email"),
        })
        .then((res) => {
          //console.log("Groups: ", GroupNames);
          setGroupNames(res.data.groups);
        });
    } catch (e) {
      //console.log(e);
    }
  }, []);

  useEffect(() => {
    try {
      axios
        .post(
          "https://api.confidanto.com/weekly-reporting-google-ads/compare-campaigns-data",
          { customer_id: 4643036315 }
        )
        .then((res) => {
          //console.log("Campaign Data: ", res.data);
          setCampaignData(res.data);
        });
    } catch (e) {
      //console.log(e);
    }
  }, []);

  const openFormButton = () => {
    setformVisible(!formVisible);

    if (!formVisible) {
      setformButtonText("Close Form");
    } else {
      setformButtonText("Add Table");
    }
  };

  const addtablebutton = () => {
    setSelectOptionInput(null)
    // //console.log("FORM DATA");
    let categoryId = categoryRef.current.value


    //console.log("BVCXZDBN",categoryId)
    
    let newObj = null

    if(categoryId == "audience" || categoryId == "gender" || categoryId == "locations"){

      newObj = categoryId

    }else{
      let groupData = GroupNames.filter((obj)=>{
        return obj.group_id == categoryId
      })
      groupData = groupData[0]
      // //console.log(groupData);
      // //console.log("Click REF: ", click_ref.current.checked);

      newObj = {
        typename: groupData.group_name,
        campaign_ids: groupData.campaign_ids,
        flags:{

          impr:impr_ref.current.checked,
          cost:cost_ref.current.checked,
          click:click_ref.current.checked,

          impr_per:impr_per_ref.current.checked,
          cost_per:cost_per_ref.current.checked,
          click_per:click_per_ref.current.checked,

          ctr:ctr_ref.current.checked,
          conversion:conversion_ref.current.checked,
          cost_per_conv:cost_per_conv_ref.current.checked,
        },
        data:[]
      }

      CampaignData.map((data)=>{
        if(newObj.campaign_ids.includes(data.id.toString())){
          //console.log(data.id.toString(),newObj.campaign_ids);
          newObj.data.push(data)
        }
      })
    }

    setTableObjectArr((prevObj)=>[
      ...prevObj,
      newObj
    ])

    setformVisible(false)
    if(!formVisible){
      setformButtonText("Close Form")
    }else{
      setformButtonText("Add Table")
    }
  }

  const deletetablebutton = (i) => {
    setTableObjectArr((curr) =>
      curr.filter((obj, index) => {
        return index != i;
      })
    );
  };


  const handleEditColumns = (index) => {
    setEditingTableIndex(index);
  }

  const saveEditedColumns = (index, newFlags) => {
    setTableObjectArr(prevTables => 
      prevTables.map((table, i) =>
      i === index ? { ...table, flags: newFlags } : table
  )
    );
    setEditingTableIndex(null);
  }

  if (!GroupNames || !CampaignData) {
    return <LoadingAnimation />;
  }

  
  return <>
      {TableObjectArr.map((table, index)=> (

      <>
      <React.Fragment key={index}>
      
        {
        typeof(table)==='object' ?              
          <CategoryTable 
            name={table.typename} 
            data={table.data} 
            index={index} 
            theWholeObject={TableObjectArr}
            deletetablebutton={deletetablebutton}
            flags={table.flags}
            handleEditColumns={handleEditColumns} // Pass the function as a prop
            editingTableIndex={editingTableIndex} // Pass the editing index
            saveEditedColumns={saveEditedColumns} // Pass the save function
            />
          : 
          <> 
            {
                table==='audience' ? 
                
                <div className='my-2'>
                  <div className='flex items-center justify-between my-2'>
                    <h3 className='text-xl'>Audience</h3>
                    <button className='p-2 text-lg bg-red-600 text-white rounded-sm' 
                    onClick={(e)=>deletetablebutton(index)}><IoMdClose /></button> 
                  </div>
                  
                  <AudienceDataComponent  />

                </div>
                
                : <></>
            }

            {
                table==='gender' ? 
                <div className='mx-2'>
                  <div className='flex items-center justify-between my-2'>
                    <h3 className='text-xl'>Gender</h3>
                    <button className='p-2 text-lg bg-red-600 text-white rounded-sm' 
                    onClick={(e)=>deletetablebutton(index)}><IoMdClose /></button> 
                  </div>
                  
                  <Genderinfo />

                </div>
                : <></>
            }

            {
                table==='locations' ? 
                <div className='mx-2'>
                  <div className='flex items-center justify-between my-2'>
                    <h3 className='text-xl'>Locations</h3>
                    <button className='p-2 text-lg bg-red-600 text-white rounded-sm' 
                    onClick={(e)=>deletetablebutton(index)}><IoMdClose /></button> 
                  </div>
                  
                  <Location2Filter targetType={"City"} />

                </div>
                : <></>
            }
          </>
        } 
        </React.Fragment>
      </>
      )
      )}

    <div className="form-div ">
      {formVisible && 
        <div className="form w-full">
          <div className='w-full my-6 p-8 space-y-4 bg-gray-100 rounded-md  '>

          
              <h4 className='text-lg font-semibold'>Select Table Type</h4>
              {/* SELECT INPUT */}
              <select name="select_category" id="" className='p-2 w-full' ref={tableTypeRef} onChange={()=>{
                setSelectOptionInput(tableTypeRef.current.value)
              }}>
                <option value="groups">Groups</option>
                <option value="device">Device</option>
                <option value="audience">Audience</option>
              </select>

              {selectOptionInput != null && 
                <>
                <h4 className='text-lg font-semibold'>Select Category</h4>

                {/* SELECT INPUT */}
                <select name="select_category" id="" className='p-2 w-full' ref={categoryRef}>

                {selectOptionInput == 'groups' && 
                  <>
                    {GroupNames && GroupNames.map(data => 
                    <option value={data.group_id}>{data.group_name}</option>
                    )}
                  </>
                }  

                
                {selectOptionInput == 'audience' && 
                  <>
                    <option value="audience">Age</option>
                    <option value="gender">Gender</option>
                  </>
                } 

                
                {selectOptionInput == 'device' && 
                  <>
                  <option value="locations">Mobile</option>
                  <option value="locations">Desktop</option>
                  <option value="locations">Tablet</option>
                  </>
                }  


                
                </select>
                </>
              }


            {/* <h4 className='text-lg font-semibold'>Select Fields</h4> */}

            <div className="form hidden grid grid-cols-4 gap-4 "> 

                  {/* CHECKBOX INPUTS */}
                  <div className="input flex space-x-1">
                    <input className='w-4 p-2' type="checkbox" defaultChecked ref={impr_ref} />
                    <p>Impressions</p>
                  </div>  
                  <div className="input flex space-x-1">
                    <input className='w-4 p-2' type="checkbox" defaultChecked ref={click_ref} />
                    <p>Clicks</p>
                  </div>  
                  <div className="input flex space-x-1">
                    <input className='w-4 p-2' type="checkbox" defaultChecked ref={cost_ref} />
                    <p>Cost</p>
                  </div>  

                  <div className="input flex space-x-1">
                    <input className='w-4 p-2' type="checkbox" ref={impr_per_ref}   />
                    <p>Impression %Δ</p>
                  </div>  
                  
                  <div className="input flex space-x-1">
                    <input className='w-4 p-2' type="checkbox" ref={click_per_ref}  />
                    <p>Click %Δ</p>
                  </div>  
                  
                  <div className="input flex space-x-1">
                    <input className='w-4 p-2' type="checkbox" ref={cost_per_ref} />
                    <p>Cost %Δ</p>
                  </div>  

                  
                  <div className="input flex space-x-1">
                    <input className='w-4 p-2' type="checkbox" ref={ctr_ref} />
                    <p>Ctr</p>
                  </div>  
                  <div className="input flex space-x-1">
                    <input className='w-4 p-2' type="checkbox" ref={conversion_ref} />
                    <p>Conversion</p>
                  </div>  
                  <div className="input flex space-x-1">
                    <input className='w-4 p-2' type="checkbox" ref={cost_per_conv_ref} />
                    <p>Cost/Conv</p>
                  </div>  

            </div>
            <button
                className="bg-blue-500 text-white px-4 py-2 my-2 rounded hover:bg-blue-600 flex items-center justify-center"       

                onClick={addtablebutton}
                >
                Create
            </button>                    
        </div>
        </div>
      }
      <button className='bg-blue-500 text-white px-4 py-2 my-4 rounded hover:bg-blue-600 flex items-center justify-center' onClick={openFormButton}>{fromButtonText}</button>
    </div>
  </>
}

function ColumnEditor({ flags, onSave }) {
  const [editedFlags, setEditedFlags] = useState(flags);

  const handleFlagChange = (key) => {
    setEditedFlags(prev => ({ ...prev, [key]: !prev[key] }));
  }

  return (
    <div className="column-editor mb-4 p-4 bg-gray-100 rounded">
      <h4 className="font-semibold mb-2">Edit Columns</h4>
      <div className="grid grid-cols-3 gap-2">
        {Object.entries(editedFlags).map(([key, value]) => (
          <div key={key} className="flex items-center">
            <input
              type="checkbox"
              id={key}
              checked={value}
              onChange={() => handleFlagChange(key)}
              className="mr-2"
            />
            <label htmlFor={key}>{key.replace('_', ' ')}</label>
          </div>
        ))}
      </div>
      <button 
        className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        onClick={() => onSave(editedFlags)}
      >
        Save Changes
      </button>
    </div>
  )
}


function Checkbox({ label, ref }) {
  return (
    <div className='input flex space-x-1'>
      <input type="checkbox" className='w-4 p-2' ref={ref} />
      <p>{label}</p>
    </div>
  )
}



function CategoryTable(props) {
  const [insightsVisible, setInsightsVisible] = useState(false)
  const [insights, setInsights] = useState(null)
  const [initialParagraph, setInitialParagraph] = useState('');

  const generateInsights = async () => {

    if (!props.data || props.data.length === 0) {
      console.error("No data available for insights");
      return;
    }

    let insightsData = {};

    if (props.flags.click) {
      let totalClicks = props.data.reduce(
        (acc, row) => acc + (row.clicks_curr_week || 0),
        0
      );
      insightsData.totalClicks = Math.round(totalClicks);
    }

    if (props.flags.cost) {
      let totalCost = props.data.reduce(
        (acc, row) => acc + (row.cost_curr_week || 0),
        0
      );
      insightsData.totalCost = Math.round(totalCost * 100) / 100;
    }

    if (props.flags.impr) {
      let totalImpressions = props.data.reduce(
        (acc, row) => acc + (row.impr_curr_week || 0),
        0
      );
      insightsData.totalImpressions = Math.round(totalImpressions);
    }

    if (props.flags.impr_per) {
      let totalImpressionsPer = props.data.reduce(
        (acc, row) => acc + (parseFloat(row.impr_change_percent) || 0),
        0
      );
      insightsData.totalImpressionsPer =
        Math.round((totalImpressionsPer / props.data.length) * 100) / 100;
    }

    if (props.flags.click_per) {
      let totalClicksPer = props.data.reduce(
        (acc, row) => acc + (parseFloat(row.clicks_change_percent) || 0),
        0
      );
      insightsData.totalClicksPer =
        Math.round((totalClicksPer / props.data.length) * 100) / 100;
    }

    if (props.flags.cost_per) {
      let totalCostPer = props.data.reduce(
        (acc, row) => acc + (parseFloat(row.cost_change_percent) || 0),
        0
      );
      insightsData.totalCostPer =
        Math.round((totalCostPer / props.data.length) * 100) / 100;
    }

    if (props.flags.ctr) {
      let totalCtr = props.data.reduce((acc, row) => acc + (row.ctr || 0), 0);
      insightsData.totalCtr =
        Math.round((totalCtr / props.data.length) * 100) / 100;
    }

    if (props.flags.conversion) {
      let totalConversions = props.data.reduce(
        (acc, row) => acc + (row.conversion || 0),
        0
      );
      insightsData.totalConversions = Math.round(totalConversions);
    }

    if (props.flags.cost_per_conv) {
      let totalCostPerConv = props.data.reduce(
        (acc, row) => acc + (row.cost_per_conv || 0),
        0
      );
      insightsData.totalCostPerConv =
        Math.round((totalCostPerConv / props.data.length) * 100) / 100;
    }

    await weeklyReportInitialParagraph1(insightsData, setInitialParagraph, props.flags);

    setInsights(insightsData);
    setInsightsVisible(!insightsVisible);
  };

  return <>
    <div className="table">
          <div className='flex items-center justify-between'>
              <h3>{props.name}</h3>
              <div className='flex space-x-2'>
              <button
          className="bg-slate-400 text-white px-4 py-2 rounded hover:bg-slate-600"
          onClick={() => props.handleEditColumns(props.index)}
        >
          Edit Columns
        </button>

              <button className='p-2 text-lg bg-red-600 text-white rounded-sm' 
              onClick={(e)=>props.deletetablebutton(props.index)}><IoMdClose /></button>
              </div>
          </div>
              

            
          <table className='mt-2 mb-6 w-full'>
            <thead className='bg-[#2930a8] text-white'>
              <tr>
                {true && <th className='p-2 border-b text-left'>Name</th>}

                {props.flags.impr && <th className='p-2 border-b text-left'>Impr</th>}
                {props.flags.impr_per && <th className='p-2 border-b text-left'>Impr %Δ</th>}
                {props.flags.click && <th className='p-2 border-b text-left'>Clicks</th>}
                {props.flags.click_per && <th className='p-2 border-b text-left'>Clicks %Δ</th>}
                {props.flags.cost && <th className='p-2 border-b text-left'>Costs</th>}
                {props.flags.cost_per && <th className='p-2 border-b text-left'>Cost %Δ</th>}
                {props.flags.ctr && <th className='p-2 border-b text-left'>Ctr</th>}
                {props.flags.conversion && <th className='p-2 border-b text-left'>Conv</th>}
                {props.flags.cost_per_conv && <th className='p-2 border-b text-left'>Cost/Conv</th>}


                
                {false && <th className='p-2 border-b text-left'>Impr </th>}
                {false && <th className='p-2 border-b text-left'>Clicks</th>}
                {false && <th className='p-2 border-b text-left'>Costs</th>}
              </tr>
            </thead>
            <tbody>
              {props.data.map(row=> 
                <tr>
                  {true && <td className='p-2 border-b text-left'>{row.campaign}</td>}

                  {props.flags.impr && <td className='p-2 border-b text-left'>{row.impr_curr_week}</td>}
                  {props.flags.impr_per && <td className='p-2 border-b text-left'>{row.impr_change_percent}</td>}
                  {props.flags.click && <td className='p-2 border-b text-left'>{row.clicks_curr_week}</td>}
                  {props.flags.click_per && <td className='p-2 border-b text-left'>{row.clicks_change_percent}</td>}
                  {props.flags.cost && <td className='p-2 border-b text-left'>{row.cost_curr_week}</td>}
                  {props.flags.cost_per && <td className='p-2 border-b text-left'>{row.cost_change_percent}</td>}
                  {props.flags.ctr && <td className='p-2 border-b text-left'>{row.ctr.toFixed(2)}</td>}
                  {props.flags.conversion && <td className='p-2 border-b text-left'>{row.conversion}</td>}
                  {props.flags.cost_per_conv && <td className='p-2 border-b text-left'>{row.cost_per_conv}</td>}


                  
                  {false && <td className='p-2 border-b text-left'>{row.impr_day_before_yesterday}</td>}
                  {false && <td className='p-2 border-b text-left'>{row.clicks_day_before_yesterday}</td>}
                  {false && <td className='p-2 border-b text-left'>{row.cost_day_before_yesterday}</td>}

                  
                </tr>  
              )}
            </tbody>
          </table>
      </div>


      
      <div className="mb-4">
        <button className="p-2 text-md bg-blue-500 text-white rounded-md mr-2" onClick={generateInsights}>
          Insights
        </button>
      </div>

      {insightsVisible && (
  <div className="insights-section p-4 bg-gray-100 border rounded-md mb-6">
    <h4 className="font-semibold">Insights for {props.name}</h4>
    
    {/* Display only the initial paragraph */}
    <p>{initialParagraph}</p>
  </div>
)}


{props.editingTableIndex === props.index && (
      <ColumnEditor
        flags={props.flags}
        onSave={(newFlags) => props.saveEditedColumns(props.index, newFlags)}
      />
    )}

  </>
}

export default AddCampaignTable2