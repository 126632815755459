import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ChartPage = ({ customerId, startDate, endDate }) => {
  const [chartData, setChartData] = useState(null);
  const [selectedMetric1, setSelectedMetric1] = useState('impressions');
  const [selectedMetric2, setSelectedMetric2] = useState('average_cpc');

  useEffect(() => {
    axios
      .post('https://api.confidanto.com/campaign-chart', {
        customer_id: customerId,
        metric1: selectedMetric1,
        metric2: selectedMetric2,
        start_date: startDate,
        end_date: endDate,
      })
      .then((response) => {
        console.log('API Response:', response.data);
        formatChartData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching the chart data:', error);
      });
  }, [customerId, startDate, endDate, selectedMetric1, selectedMetric2]);

  const formatChartData = (data) => {
    console.log('Raw Data:', data);
    const groupedData = data.reduce((acc, item) => {
      acc[item.date] = acc[item.date] || { date: item.date, [selectedMetric1]: 0, [selectedMetric2]: 0 };
      acc[item.date][selectedMetric1] += item[selectedMetric1] || 0;
      acc[item.date][selectedMetric2] += item[selectedMetric2] || 0;
      return acc;
    }, {});

    const sortedDates = Object.keys(groupedData).sort();

    const labels = sortedDates.map((date) => date);
    const metric1Data = sortedDates.map((date) => groupedData[date][selectedMetric1]);
    const metric2Data = sortedDates.map((date) => groupedData[date][selectedMetric2]);

    const formattedData = {
      labels,
      datasets: [
        {
          label: selectedMetric1.charAt(0).toUpperCase() + selectedMetric1.slice(1),
          data: metric1Data,
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          yAxisID: 'y',
        },
        {
          label: selectedMetric2.charAt(0).toUpperCase() + selectedMetric2.slice(1),
          data: metric2Data,
          borderColor: 'rgba(255, 99, 132, 1)',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          yAxisID: 'y1',
        },
      ],
    };
    console.log('Formatted Chart Data:', formattedData);
    setChartData(formattedData);
  };

  const handleMetricChange1 = (e) => {
    setSelectedMetric1(e.target.value);
  };
  const handleMetricChange2 = (e) => {
    setSelectedMetric2(e.target.value);
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: selectedMetric1.charAt(0).toUpperCase() + selectedMetric1.slice(1),
        },
      },
      y1: {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: selectedMetric2.charAt(0).toUpperCase() + selectedMetric2.slice(1),
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const metricOptions = [
    'interaction_rate',
    'avg_cost',
    'costs',
    'clicks',
    'average_cpc',
    'ctr',
    'conversions',
    'cost_per_conv',
    'cost_per_conversion',
  ];

  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="mb-4">
        <label htmlFor="metricSelect1" className="mr-2">Metric 1:</label>
        <select id="metricSelect1" value={selectedMetric1} onChange={handleMetricChange1} className="border p-2 rounded">
          {metricOptions.map((metric) => (
            <option key={metric} value={metric}>{metric.replace(/_/g, ' ')}</option>
          ))}
        </select>

        <label htmlFor="metricSelect2" className="ml-4 mr-2">Metric 2:</label>
        <select id="metricSelect2" value={selectedMetric2} onChange={handleMetricChange2} className="border p-2 rounded">
          {metricOptions.map((metric) => (
            <option key={metric} value={metric}>{metric.replace(/_/g, ' ')}</option>
          ))}
        </select>
      </div>
      <div className="w-full h-96">
        {chartData ? (
          <Line data={chartData} options={options} />
        ) : (
          <p>Loading chart...</p>
        )}
      </div>
    </div>
  );
};

export default ChartPage;
