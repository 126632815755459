import React from 'react'

function SubscriptionStatus() {
  return (
    <div>
      Subscription Status
    </div>
  )
}

export default SubscriptionStatus
