export const animals_pets = ["Pet Shop", "Pet Grooming", "Pet Food"];
export const advocacy = [
  "Case advocacy",
  "Peer advocacy",
  "Paid independent advocacy",
  "Citizen advocacy",
  "Statutory advocacy",
];
export const art_ent = [
  "Amusement parks",
  "Music",
  "Performance art",
  "Publicist",
  "Sports",
  "Artist manager",
  "Media",
  "Bounce house",
  "Casino",
  "Comedy Club",
  "Costume designer",
  "Dance studio",
  "Dinner Theatre",
  "D j",
  "Museums",
  "Promoter",
  "Ad film production",
  "Agents and agencies",
  "Animation",
  "Art Gallery",
  "Attractions",
  "Entertainment venues",
];
export const autom = [
  "Auto restoration",
  "Car dealership",
  "Car rental services",
  "Car wash",
  "Auto Accessories store",
  "Bus services",
  "Car detailing",
  "Spare parts",
  "Auto body repair shops",
  "Auto instructor",
  "Automobile Magazine",
  "Oil change",
  "Charging station",
  "Towing",
  "Auto locksmith business",
  "Automobile insurance",
  "Battery store",
  "Car service",
  "Hauling business",
  "Limo business",
];
export const attorneys = [
  "Contract lawyer",
  "Immigration lawyer",
  "Intellectual property lawyer",
  "Tax lawyer",
  "Family law",
  "Personal injury lawyer",
  "Prosecutor",
  "Bankruptcy Lawyer",
  "Entertainment lawyer",
  "Real estate lawyer",
  "Technology lawyer",
];
export const beauty = [
  "Skin care",
  "Decorative cosmetics",
  "Hair care",
  "Beauty products",
  "Oral Care",
  "Beauty product business",
  "Makeup artist",
  "Nail salon",
  "Perfumes",
  "Start a beauty school",
  "Start a spa business",
];
export const business = [
  "Management Consultant",
  "Financial Analyst",
  "Finance",
  "Sales",
  "Marketing",
  "Sales Management",
  "Accounting",
  "Event management",
  "Accountant",
  "Software Engineer",
  "Management Analyst",
  "Real Estate",
  "Construction",
  "Actuary",
  "Financial adviser",
  "Business Analyst",
  "Business consultant",
  "Auditor",
  "Office administration",
  "Chief Executive Officer",
];
export const dating = [
  "Online dating App",
  "Online Dating website",
  "Wedding Planners",
];

export const dentists = [
  "Endodontic procedures",
  "Pediatric dental services",
  "Periodontal treatments",
  "Diagnostic and preventative dental services",
  "Prosthodontic services",
  "Oral and maxillofacial surgery",
];
export const edu = [
  "Tutoring",
  "Online private/ charter school",
  "Online courses",
  "Lesson plans/material",
  "Homeschooling",
  "Teaching languages",
  "Test prep coaching",
  "Mindfulness or stress management",
];
export const finance = [
  "Commercial auto insurance",
  "Commercial property insurance",
  "General liability insurance",
  "Data breach insurance",
  "Workers' compensation insurance",
  "Business income insurance",
  "Commercial umbrella insurance",
  "Liability insurance",
  "Business interruption insurance",
  "Business owner's policy",
];

export const home_improve = [
  "Basement remodeling",
  "Lawn care",
  "Plumbing",
  "Electrical",
  "Furniture assembly",
  "Apartment cleaning",
  "Carpentry",
  "Deck building",
  "Flooring",
  "Handyman services",
  "Home security business",
  "House painter",
  "Carpeting",
  "Fence installation and repair",
  "Wallpapering",
  "Home maintenance and repair services",
  "Home automation Technician",
  "Tiling",
];
export const furniture = [
  "Furniture stores",
  "Interior design services",
  "Furniture repair shops",
  "Furniture Manufacturing",
  "Furniture distribution",
  "Specializing in specific types of furniture",
  "Reupholstery shop",
];

export const health = [
  "Yoga",
  "Bootcamp Fitness",
  "Nutrition",
  "Personal training",
  "Branded fitness apparel",
  "Meditation",
  "Pilates",
  "CrossFit",
  "Dance studio",
  "Boutique gym",
  "Fitness equipment",
  "Fitness vlogger",
  "Mobile fitness studio",
  "Outdoor classes",
  "Personalized wellness",
  "Physical therapist",
  "Powerlifting gym",
  "Zumba",
  "Bike tour",
  "Fitness affiliate",
  "Fitness Director",
  "Fitness franchise",
  "Gymnastics classes business",
];

export const health_medical = [
  "Methadone clinic",
  "Healthcare facilities",
  "Medical billing service",
  "Medical records management",
  "Transcribing services",
  "Mobile Medical screening",
  "Nutrition consultation",
  "Pharmaceutical",
  "Childbirth services",
  "Health tech",
  "Healthcare blogging",
  "Medical equipment",
  "Nursing staffing agency",
  "Mental wellness",
  "Nursing home",
  "Adult Daycare",
  "Biomedical waste Management",
  "Blood Bank",
  "D/R/U/G/S",
  "Health consulting",
  "Physical therapy clinic",
  "Start manufacturing medical equipment",
  "Diabetes Clinic",
  "Drug testing",
];

export const home_goods = [
  "Outdoor",
  "Gifts",
  "Bedding",
  "Bath",
  "Home",
  "Accents",
  "Storage",
  "Dining",
  "Wall Decor",
  "Mirrors",
  "Kitchen",
  "Essentials",
  "Seasonal",
  "Decor",
];
export const ecomm = [
  "Apparels",
  "Jewelry",
  "Handbags",
  "Electronics",
  "Books",
  "Furniture",
  "Groceries",
  "Beauty products",
  "Fashion",
  "Mobile phones",
  "Movie tickets",
  "Toys and games",
  "Auto and Parts",
  "Baby products",
  "Fitness equipment",
  "Health supplements",
  "Kitchenware",
];
export const real_estate = ["Auditor", "Renting a property", "Selling A Property"];

export const categoryMap = {
  "Animals & Pets": animals_pets,
  "Advocacy": advocacy,
  "Arts & Entertainment": art_ent,
  "Attorneys & Legal Services": attorneys,
  "Automotive": autom,
  "Beauty & Personal Care": beauty,
  "Business Services": business,
  "Dating & Personals": dating,
  "Dentists & Dental Services": dentists,
  "Education & Instruction": edu,
  "Finance & Insurance": finance,
  "Home & Home Improvement": home_improve,
  "Furniture": furniture,
  "Health & Fitness": health,
  "Health & Medical": health_medical,
  "Home Goods": home_goods,
  "E-Commerce": ecomm,
  "Real Estate": real_estate,
};

export const travel_and_tourism = [
  "Hotel booking platform"
];