import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = () => {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [error, setError] = useState(null);
  const [metric, setMetric] = useState('impressions');

  const fetchData = () => {
    setLoading(true);
    fetch("https://api.confidanto.com/gender_chart", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_id: "4643036315",
        metric: metric,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("API Response:", data); // Debugging output

        // Extract gender and impressions for chart data
        const genderSegments = data.gender_chart_data || [];

        const genderCounts = genderSegments.reduce((acc, curr) => {
          acc[curr.gender] = (acc[curr.gender] || 0) + curr.impressions;
          return acc;
        }, {});

        console.log("Gender Counts:", genderCounts); // Check gender counts

        const chartData = {
          labels: Object.keys(genderCounts),
          datasets: [
            {
              label: `Impressions by Gender (${metric})`,
              data: Object.values(genderCounts),
              backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
              hoverOffset: 4,
            },
          ],
        };

        setChartData(chartData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("Error fetching data");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [metric]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          boxWidth: 10,
        },
      },
    },
  };

  const handleMetricChange = (e) => {
    setMetric(e.target.value);
  };

  if (loading) {
    return <div className="text-lg text-center text-gray-600 py-10">Loading...</div>;
  }

  if (error) {
    return <div className="text-lg text-red-500 py-10 text-center">{error}</div>;
  }

  return (
    <div className="">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">Gender Segmentation</h2>
      
      <div className="mb-4">
        <label className="mr-2">Metric:</label>
        <select
          value={metric}
          onChange={handleMetricChange}
          className="border border-gray-300 rounded-md p-2"
        >
          <option value="impressions">Impressions</option>
          {/* Add other metrics if needed */}
        </select>
      </div>

      <div className="w-96 h-96  bg-white shadow-lg rounded-lg p-6">
        {chartData ? (
          <Doughnut data={chartData} options={options} />
        ) : (
          <div className="text-gray-600 text-center py-10">No Data Available</div>
        )}
      </div>
    </div>
  );
};

export default DonutChart;
