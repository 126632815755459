import React, { useState, useEffect, useRef } from "react";
import GoogleAdsIcon from "../data/Google_Ads_logo.png";
import BingAdsIcon from "../data/Bing_Ads_Icon.png";
import { DateRangePicker } from "react-date-range";
import { format, isYesterday, isToday } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "../styles/CustomDateRangePicker.css";
import GoogleAdsTable from "./GoogleAdsTable";

function PaidAdsPage() {
  const [currency, setCurrency] = useState("$");
  const [selectedPlatform, setSelectedPlatform] = useState("Google Ads");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [userBudget, setUserBudget] = useState({});
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState({
    clientType: "all", // lead gen, revenue-based, all
    campaignType: "all", // e.g. search, display
    region: "all", // region filter
  });

  const [topBrands, setTopBrands] = useState([]); // for top spending brands

  const datePickerRef = useRef(null); // Reference for the datepicker container

  const email = localStorage.getItem("email");

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://api.confidanto.com/fetch-project-budgets",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            platform: selectedPlatform,
            filters: filters, // Pass filters in the request
          }),
        }
      );

      const data = await response.json();

      // Check if data is valid before proceeding
      if (data && Array.isArray(data)) {
        const projects = data.map((project) => ({
          project: project.project_name,
          spend: "100",
          budget: project.project_budget,
          daily_spend_rate: "80%",
          impressions: "34,567",
          clicks: "236",
          cpc: "3.13",
        }));

        setTableData(projects);
        setTopBrands(data?.topBrands || []); // Handle possible undefined topBrands
      } else {
        console.error("Unexpected data structure:", data);
        setTableData([]); // Set empty state in case of unexpected data
        setTopBrands([]); // Set empty state for topBrands
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setTableData([]); // Set empty state in case of an error
      setTopBrands([]); // Set empty state in case of an error
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedPlatform, userBudget, filters]); // Fetch new data when filters change

  useEffect(() => {
    // Handle clicks outside of the datepicker
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const formatDateDisplay = (date) => {
    if (isToday(date)) {
      return `Today ${format(date, "MMM dd, yyyy")}`;
    } else if (isYesterday(date)) {
      return `Yesterday ${format(date, "MMM dd, yyyy")}`;
    } else {
      return format(date, "MMM dd, yyyy")};
  };

  const formatButtonLabel = () => {
    const startDateLabel = formatDateDisplay(state[0].startDate);
    const endDateLabel = formatDateDisplay(state[0].endDate);

    if (startDateLabel === endDateLabel) return startDateLabel;

    return `${startDateLabel} - ${endDateLabel}`;
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  return (
    <div className="mb-32 overflow-y-visible h-full font-roboto">
      <div className="bg-white m-4 rounded-lg shadow-md shadow-gray-500 py-8">
        <div className="flex items-center w-full justify-between mb-11">
          <img
            alt="Google Ads"
            src={GoogleAdsIcon}
            className="w-auto h-20 mx-2"
          />
          <h1 className="text-3xl mx-12 pb-2 mt-2 uppercase text-[#070a74] font-semibold text-center">
            Google Ads Overview
          </h1>
          <div className="flex items-center m-4 mr-8">
            <div className="relative" ref={datePickerRef}>
              <button
                onClick={toggleDatePicker}
                className="text-base border mr-2 border-gray-400 p-2 w-60"
              >
                {formatButtonLabel()}
              </button>
              {showDatePicker && (
                <div className="absolute z-10 mt-2 shadow-lg bg-white right-2">
                  <DateRangePicker
                    onChange={(item) => setState([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={state}
                    direction="horizontal"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Filters Section */}
        <div className="flex space-x-4 mb-6 mx-12">
          <select
            name="clientType"
            onChange={handleFilterChange}
            className="border p-2"
          >
            <option value="all">All Clients</option>
            <option value="lead-gen">Lead Gen Clients</option>
            <option value="revenue-based">Revenue-Based Clients</option>
          </select>
          <select
            name="campaignType"
            onChange={handleFilterChange}
            className="border p-2"
          >
            <option value="all">All Campaign Types</option>
            <option value="search">Search Campaigns</option>
            <option value="display">Display Campaigns</option>
          </select>
          <select
            name="region"
            onChange={handleFilterChange}
            className="border p-2"
          >
            <option value="all">All Regions</option>
            <option value="north-america">North America</option>
            <option value="europe">Europe</option>
            <option value="asia">Asia</option>
          </select>
        </div>

        <div className="mt-6 mb-32 mx-12">
          <GoogleAdsTable
            tableData={tableData}
            currency={currency}
            onSaveBudget={(newBudget) =>
              setUserBudget((prevBudget) => ({
                ...prevBudget,
                [newBudget.index]: newBudget.amount,
              }))
            }
          />
        </div>

        {/* Display Top Brands */}
        <div className="mt-6 mx-12">
          <h2 className="text-2xl font-semibold">Top Spending Brands</h2>
          <ul>
            {topBrands.map((brand, index) => (
              <li key={index} className="my-2">
                {brand.name} - {currency}
                {brand.spend}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PaidAdsPage;
