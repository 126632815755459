import axios from "axios";
import LoadingAnimation from "../components/LoadingAnimation";
import React, { useState, useEffect } from "react";

const GoogleAdsMonthlyReporting = () => {
    const [data, setData] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null); // Year selection
    const [selectedMonths, setSelectedMonths] = useState([]); // Multi-month selection
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showMetrics, setShowMetrics] = useState(false); // Toggle for showing only metrics
    const [monthNames] = useState([
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ]);

    useEffect(() => {
        if (selectedYear && selectedMonths.length > 0) {
            const fetchReportData = async () => {
                try {
                    setLoading(true);
                    const response = await axios.post('https://api.confidanto.com/monthly-overview', {
                        customer_id: "4643036315",
                        months: selectedMonths.map(monthIndex => monthIndex + 1), // Adjust for zero-based index
                        year: selectedYear,
                    });
                    setData(response.data.monthly_overviews);
                } catch (error) {
                    setError(error.message || "Something went wrong!");
                } finally {
                    setLoading(false);
                }
            };
            fetchReportData();
        }
    }, [selectedYear, selectedMonths]);

    const handleYearChange = (event) => {
        setSelectedYear(parseInt(event.target.value));
    };

    const toggleMonthSelection = (monthIndex) => {
        setSelectedMonths((prevSelectedMonths) => {
            if (prevSelectedMonths.includes(monthIndex)) {
                return prevSelectedMonths.filter((index) => index !== monthIndex);
            } else {
                return [...prevSelectedMonths, monthIndex];
            }
        });
    };

    const toggleShowMetrics = () => {
        setShowMetrics(!showMetrics);
    };

    return (
        <div className="mb-20 mx-4">
            <h1 className="text-4xl font-bold mb-4">Monthly Report</h1>

            {/* Year Selection */}
            <div className="mb-4">
                <h2 className="text-xl font-semibold mb-2">Select Year</h2>
                <select
                    value={selectedYear || ""}
                    onChange={handleYearChange}
                    className="border border-gray-300 rounded p-2 w-full"
                >
                    <option value="" disabled>Select a year</option>
                    {Array.from({ length: 5 }, (_, yearOffset) => {
                        const year = new Date().getFullYear() - yearOffset;
                        return <option key={year} value={year}>{year}</option>;
                    })}
                </select>
            </div>

            {/* Month Selection */}
            {selectedYear && (
                <div className="mb-8">
                    <h2 className="text-xl font-semibold mb-2">Select Months</h2>
                    <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-6 gap-4">
                        {monthNames.map((month, index) => (
                            <div
                                key={index}
                                className={`p-4 border rounded-lg cursor-pointer text-center 
                                    ${selectedMonths.includes(index) ? "bg-blue-500 text-white" : "bg-gray-100 text-gray-700"}
                                    hover:bg-blue-300`}
                                onClick={() => toggleMonthSelection(index)}
                            >
                                {month}
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* Toggle Show Metrics */}
            <div className="mb-4">
                <label className="inline-flex items-center">
                    <input
                        type="checkbox"
                        checked={showMetrics}
                        onChange={toggleShowMetrics}
                        className="mr-2"
                    />
                    Show Only Metrics
                </label>
            </div>

            {/* Display Loading Animation */}
            {loading && (
                <div className="flex justify-center items-center">
                    <LoadingAnimation />
                </div>
            )}

            {/* Error Display */}
            {error && <p className="text-center text-red-300">{error}</p>}

            {/* Data Table */}
            {!loading && !error && data.length > 0 && (
                <table className="min-w-full bg-gray-100 border border-gray-200 mt-4 mb-4">
                    <thead>
                        <tr className="bg-gray-200 text-center">
                            {!showMetrics && <th className="p-2 border-b">Month</th>}
                            <th className="p-2 border-b">Clicks</th>
                            <th className="p-2 border-b">Conversions</th>
                            <th className="p-2 border-b">Cost</th>
                            <th className="p-2 border-b">Average CPC</th>
                            <th className="p-2 border-b">CTR</th>
                            <th className="p-2 border-b">Impressions</th>
                            <th className="p-2 border-b">Conversion Rate</th>
                            <th className="p-2 border-b">Cost per Conversion</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index} className="text-center">
                                {!showMetrics && <td className="p-2">{monthNames[item.month - 1]}</td>}
                                <td className="p-2">{item.overview.clicks || 0}</td>
                                <td className="p-2">{item.overview.conversions || 0}</td>
                                <td className="p-2">{item.overview.cost || 0}</td>
                                <td className="p-2">{item.overview.average_cpc || 0}</td>
                                <td className="p-2">{item.overview.ctr || 0}%</td>
                                <td className="p-2">{item.overview.impressions || 0}</td>
                                <td className="p-2">{item.overview.conversion_rate || 0}%</td>
                                <td className="p-2">{item.overview.cost_per_conversion || 0}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {/* No Data */}
            {!loading && !error && data.length === 0 && (
                <p className="text-center text-gray-500">No data available for the selected year/months.</p>
            )}
        </div>
    );
};

export default GoogleAdsMonthlyReporting;
