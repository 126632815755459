import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { LiaDotCircleSolid } from "react-icons/lia";
import { PiCaretDownLight } from "react-icons/pi";
import { PiCaretUpLight } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import axios from "axios";
import { useEffect } from "react";
import { confiLogoWhite } from "../assets";
import { AiOutlineLogout } from "react-icons/ai";
import { links } from "../data/dummy";
import { GoTasklist } from "react-icons/go";
import ProjectSidebar from "../pages/ProjectSidebar";
import { IoSettingsOutline } from "react-icons/io5";
import { RiCustomerService2Line } from "react-icons/ri";
import { CiSettings } from "react-icons/ci";
import { LiaMoneyBillWaveAltSolid } from "react-icons/lia";

//Sidebar code
const Sidebar = ({ onLogout }) => {
  const { activeMenu, setActiveMenu, screenSize } = useStateContext();
  const [project_id, setProjectId] = useState(
    localStorage.getItem("project_id")
  );
  //console.log(project_id);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenKeyword, setDropdownOpenKeyword] = useState(false);
  const [dropdownView, setDropdownView] = useState(false);
  const [dropdownOpenMedia, setDropdownOpenMedia] = useState(false);
  const [dropdownOpenAlerts, setDropdownOpenAlerts] = useState(false);

  const [dropdownOpenDemographics, setDropdownOpenDemographics] =
    useState(false);

  const [dropdownOpenAds, setDropdownOpenAds] = useState(false);
  const [dropdownOpenPaidAds, setDropdownOpenPaidAds] = useState(false);
  const [dropdownOpenGoogle, setDropdownOpenGoogle] = useState(false);
  const [dropdownOpenBing, setDropdownOpenBing] = useState(false);
  const [dropdownOpenBingView, setDropdownOpenBingView] = useState(false);
  const [dropdownOpenGoogleReport, setDropdownOpenGoogleReport] =
    useState(false);

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleDropdownDemographics = () => {
    setDropdownOpenDemographics(!dropdownOpenDemographics);
  };

  const toggleDropdownKeyword = () => {
    setDropdownOpenKeyword(!dropdownOpenKeyword);
  };

  const toggleDropdownMedia = () => {
    setDropdownOpenMedia(!dropdownOpenMedia);
  };

  const toggleDropdownAlerts = () => {
    setDropdownOpenAlerts(!dropdownOpenAlerts);
  };

  const toggleDropdownView = () => {
    setDropdownView(!dropdownView);
  };

  const toggleDropdownBingView = () => {
    setDropdownOpenBingView(!dropdownOpenBingView);
  };

  const toggleDropdownPaidAds = () => {
    setDropdownOpenPaidAds(!dropdownOpenPaidAds);
  };
  const toggleDropdownAds = () => {
    setDropdownOpenAds(!dropdownOpenAds);
  };

  const toggleDropdownGoogle = () => {
    setDropdownOpenGoogle(!dropdownOpenGoogle);
  };

  const toggleDropdownGoogleReport = () => {
    setDropdownOpenGoogleReport(!dropdownOpenGoogleReport);
  };

  const toggleDropdownBing = () => {
    setDropdownOpenBing(!dropdownOpenBing);
  };

  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    onLogout();
    navigate("/");
  };

  const handleLogoNavigation = () => {
    window.location.href = "https://www.confidanto.com";
  };
  const [activeLinkName, setActiveLinkName] = useState(null);

  const handleLinkClick = (name) => {
    setActiveLinkName(name);
  };

  const [showAdditionalOpetion, setShowAdsOpt] = useState(false);
  useEffect(() => {
    const checkProjectId = () => {
      if (localStorage.getItem("project_id") !== null) {
        setShowAdsOpt(true);
      } else {
        setShowAdsOpt(false);
      }
    };

    checkProjectId();

    // Set up an interval to check every 2 seconds
    const intervalId = setInterval(checkProjectId, 500);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  //console.log(showAdditionalOpetion);
  const email = localStorage.getItem("email");
  const [userData, setUserData] = useState([]);
  const [subscription, setSubscription] = useState("");
  const url = "https://api.confidanto.com/header-data";
  const fetchInfo = () => {
    return axios
      .post(url, { email: localStorage.getItem("email") })
      .then((res) => {
        setUserData([res.data.userData]);
        const { start_date } = res.data.userData;
        localStorage.setItem("start_date", start_date);
        setSubscription(res.data.userData.subscription);
        //console.log(res.data.userData.subscription);
        //console.log();
      })
      .catch((error) => console.log(error.response));
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const handleProjectClick = () => {
    //console.log(project_id);
    setProjectId(localStorage.getItem("project_id"));
  };

  //console.log(project_id);
  const activeLink =
    "flex items-center gap-5 px-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";
  const normalLink =
    "flex items-center gap-5 px-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 hover:bg-[#495057] m-2 hover:text-[#e6e5e3] ";

  return (
    <div
      className={`flex flex-col bg-[#0f172a] font-roboto h-screen overflow-hidden`}
    >
      <div
        className={`${
          activeMenu ? "w-72" : "w-20"
        } h-screen flex flex-col  bg-[#0f172a] transition-all duration-300`}
      >
        {activeMenu ? (
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={handleLogoNavigation}
              className="relative items-center gap-3 ml-5 mt-8 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900 "
            >
              <img
                src={confiLogoWhite}
                alt="Logo"
                className={`${activeMenu ? "block" : "hidden"}`}
                style={{ width: "140px", height: "32px" }}
              />
            </button>
          </div>
        ) : (
          <div className="flex justify-center items-center mb-4">
            <button
              onClick={handleLogoNavigation}
              className="relative items-center mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900 "
            >
              <div className="p-2.5 rounded-full bg-[#4142dc] mt-1">
                <div className="p-1.5 rounded-full bg-[#ffff]"></div>
              </div>
            </button>
          </div>
        )}

        <div className="overflow-y-auto mb-12 h-[700px]">
          <div className="font-roboto mt-4">
            {subscription === "Free Trial" &&
              links.map((item) => (
                <div key={item.title}>
                  {item.title === "Dashboard" && (
                    <div
                      className={`text-lg lg:text-base ${
                        activeMenu ? "mx-4" : "mx-2"
                      }`}
                    >
                      {item.links.map((link) => (
                        <NavLink
                          to={`/${link.path}`}
                          key={link.name}
                          onClick={() => {
                            handleCloseSideBar();
                            handleProjectClick();
                          }}
                          style={({ isActive }) => ({
                            backgroundColor: isActive ? "#4e47e5" : "",
                          })}
                          className={({ isActive }) =>
                            `${isActive ? activeLink : normalLink} ${
                              isActive ? "text-white" : "text-[#e6e5e3]"
                            } ${
                              activeMenu ? "text-lg" : "text-xl rounded-full"
                            } ${
                              activeMenu ? "my-2" : "my-6 mx-0 justify-center"
                            }`
                          }
                        >
                          {link.icon}
                          <span
                            className={`capitalize  text-base  ${
                              activeMenu ? "block" : "hidden"
                            } `}
                          >
                            {link.name}
                          </span>
                        </NavLink>
                      ))}
                    </div>
                  )}
                  {item.title !== "Charts" &&
                    item.title !== "Keyword" &&
                    item.title !== "Ads" &&
                    item.title !== "Ads." &&
                    item.title !== "Media Planning" &&
                    item.title !== "Ad Crafting" &&
                    item.title !== "Alerts" &&
                    item.title !== "Dashboard" &&
                    item.title !== "Seed Keyword Analysis" && (
                      <div
                        className={`text-lg lg:text-base ${
                          activeMenu ? "mx-4" : "mx-2"
                        }`}
                      >
                        {item.links.map((link) => (
                          <NavLink
                            to={`/${link.path}`}
                            key={link.name}
                            onClick={() => {
                              handleCloseSideBar();
                              handleLinkClick(link.name);
                            }}
                            style={({ isActive }) => ({
                              backgroundColor: isActive ? "#4e47e5" : "",
                            })}
                            className={({ isActive }) =>
                              `${isActive ? activeLink : normalLink} ${
                                isActive ? "text-white" : "text-[#e6e5e3]"
                              } ${
                                activeMenu ? "text-lg" : "text-xl rounded-full"
                              } ${
                                activeMenu ? "my-2" : "my-6 mx-0 justify-center"
                              }`
                            }
                          >
                            {link.icon}
                            <span
                              className={`capitalize  text-base  ${
                                activeMenu ? "block" : "hidden"
                              } `}
                            >
                              {link.name}
                            </span>
                          </NavLink>
                        ))}
                      </div>
                    )}

                  {item.title === "Media Planning" && (
                    <div
                      className={`text-lg lg:text-base ${
                        activeMenu ? "mx-4" : "mx-2"
                      }`}
                    >
                      {item.links.map((link) => (
                        <div key={link.name}>
                          <NavLink
                            to={`/${link.path}`}
                            onClick={() => {
                              handleCloseSideBar();
                              toggleDropdownMedia();
                            }}
                            style={({ isActive }) => ({
                              backgroundColor: isActive ? "#4e47e5" : "",
                            })}
                            className={({ isActive }) =>
                              `${isActive ? activeLink : normalLink} ${
                                isActive ? "text-white" : "text-[#e6e5e3]"
                              } ${activeMenu ? "my-2" : "my-6"} ${
                                activeMenu
                                  ? "text-lg"
                                  : "text-xl mx-0 justify-center rounded-full"
                              }`
                            }
                          >
                            {link.icon}
                            <div
                              className={`flex items-center justify-between w-full ${
                                activeMenu ? "block" : "hidden"
                              } `}
                            >
                              <p
                                onClick={toggleDropdownMedia}
                                className={`capitalize  text-base  ${
                                  activeMenu ? "text-lg" : "text-xl"
                                } ${activeMenu ? "my-0" : "my-6"}`}
                              >
                                {link.name}
                              </p>
                              {link.items && (
                                <p
                                  onClick={toggleDropdownMedia}
                                  className="text-right px-2 font-bold"
                                >
                                  {dropdownOpenMedia ? (
                                    <PiCaretUpLight />
                                  ) : (
                                    <PiCaretDownLight />
                                  )}
                                </p>
                              )}
                            </div>
                          </NavLink>

                          {dropdownOpenMedia && activeMenu && (
                            <div className="ml-8">
                              {link.items.map((subItem) => (
                                <>
                                  {subItem.name === "Demographics" && (
                                    <>
                                      <NavLink
                                        to={`/${subItem.path}`}
                                        onClick={() => {
                                          handleCloseSideBar();
                                          toggleDropdownDemographics();
                                        }}
                                        style={({ isActive }) => ({
                                          backgroundColor: isActive
                                            ? "#4e47e5"
                                            : "",
                                        })}
                                        className={({ isActive }) =>
                                          `${
                                            isActive ? activeLink : normalLink
                                          } ${
                                            isActive
                                              ? "text-white gap-2"
                                              : "text-[#e6e5e3] gap-2"
                                          }`
                                        }
                                        key={subItem.name}
                                      >
                                        <p className="font-bold">
                                          <LiaDotCircleSolid />
                                        </p>
                                        <div
                                          className={`flex items-center justify-between w-full ${
                                            activeMenu ? "block" : "hidden"
                                          } `}
                                        >
                                          <p
                                            onClick={toggleDropdownDemographics}
                                            className={`capitalize  text-base  ${
                                              activeMenu ? "text-lg" : "text-xl"
                                            } ${activeMenu ? "my-0" : "my-6"}`}
                                          >
                                            {subItem.name}
                                          </p>
                                          {subItem.items && (
                                            <p
                                              onClick={
                                                toggleDropdownDemographics
                                              }
                                              className="text-right px-2 font-bold"
                                            >
                                              {dropdownOpenDemographics ? (
                                                <PiCaretUpLight />
                                              ) : (
                                                <PiCaretDownLight />
                                              )}
                                            </p>
                                          )}
                                        </div>
                                      </NavLink>
                                      {dropdownOpenDemographics && (
                                        <div className="ml-8">
                                          {subItem.items.map((subSubItem) => (
                                            <NavLink
                                              to={`/${subSubItem.path}`}
                                              onClick={handleCloseSideBar}
                                              style={({ isActive }) => ({
                                                backgroundColor: isActive
                                                  ? "#4e47e5"
                                                  : "",
                                              })}
                                              className={({ isActive }) =>
                                                `${
                                                  isActive
                                                    ? activeLink
                                                    : normalLink
                                                } ${
                                                  isActive
                                                    ? "text-white gap-2"
                                                    : "text-[#e6e5e3] gap-2"
                                                }`
                                              }
                                              key={subSubItem.name}
                                            >
                                              <div className="flex  pl-0 items-center">
                                                <LiaDotCircleSolid />
                                                <span className="capitalize ml-2 text-sm">
                                                  {subSubItem.name}
                                                </span>
                                              </div>
                                            </NavLink>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  )}

                                  {subItem.name !== "Demographics" &&
                                    activeMenu && (
                                      <NavLink
                                        to={`/${subItem.path}`}
                                        onClick={handleCloseSideBar}
                                        style={({ isActive }) => ({
                                          backgroundColor: isActive
                                            ? "#4e47e5"
                                            : "",
                                        })}
                                        className={({ isActive }) =>
                                          `${
                                            isActive ? activeLink : normalLink
                                          } ${
                                            isActive
                                              ? "text-white gap-2"
                                              : "text-[#e6e5e3] gap-2"
                                          }`
                                        }
                                        key={subItem.name}
                                      >
                                        <p className="font-bold">
                                          <LiaDotCircleSolid />
                                        </p>
                                        <div
                                          className={`flex items-center w-full ${
                                            activeMenu ? "block" : "hidden"
                                          } `}
                                        >
                                          <p
                                            className={`capitalize  text-base  ${
                                              activeMenu ? "text-lg" : "text-xl"
                                            } ${activeMenu ? "my-0" : "my-6"}`}
                                          >
                                            {subItem.name}
                                          </p>
                                        </div>
                                      </NavLink>
                                    )}
                                </>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}

                  {item.title === "Seed Keyword Analysis" && (
                    <div
                      className={`text-lg lg:text-base ${
                        activeMenu ? "mx-4" : "mx-2"
                      }`}
                    >
                      {item.links.map((link) => (
                        <NavLink
                          to={`/${link.path}`}
                          key={link.name}
                          onClick={() => {
                            handleCloseSideBar();
                            handleLinkClick(link.name);
                          }}
                          style={({ isActive }) => ({
                            backgroundColor: isActive ? "#4e47e5" : "",
                          })}
                          className={({ isActive }) =>
                            `${isActive ? activeLink : normalLink} ${
                              isActive ? "text-white" : "text-[#e6e5e3]"
                            } ${
                              activeMenu ? "text-lg" : "text-xl rounded-full"
                            } ${
                              activeMenu ? "my-2" : "my-6 mx-0 justify-center"
                            }`
                          }
                        >
                          {link.icon}
                          <span
                            className={`capitalize  text-base  ${
                              activeMenu ? "block" : "hidden"
                            } `}
                          >
                            {link.name}
                          </span>
                        </NavLink>
                      ))}
                    </div>
                  )}

                  {showAdditionalOpetion && (
                    <>
                      {item.title === "Ads" && (
                        <div
                          className={`text-lg lg:text-base ${
                            activeMenu ? "mx-4" : "mx-2"
                          }`}
                        >
                          {item.links.map((link) => (
                            <div key={link.name}>
                              <NavLink
                                to={`/${link.path}`}
                                onClick={() => {
                                  handleCloseSideBar();
                                  toggleDropdownPaidAds();
                                }}
                                style={({ isActive }) => ({
                                  backgroundColor: isActive ? "#4e47e5" : "",
                                })}
                                className={({ isActive }) =>
                                  `${isActive ? activeLink : normalLink} ${
                                    isActive ? "text-white" : "text-[#e6e5e3]"
                                  } ${activeMenu ? "my-2" : "my-6"} ${
                                    activeMenu
                                      ? "text-lg"
                                      : "text-xl mx-0 justify-center rounded-full"
                                  }`
                                }
                              >
                                {link.icon}
                                <div
                                  className={`flex items-center justify-between w-full ${
                                    activeMenu ? "block" : "hidden"
                                  } `}
                                >
                                  <p
                                    onClick={toggleDropdownPaidAds}
                                    className={`capitalize text-base  ${
                                      activeMenu ? "text-lg" : "text-xl"
                                    } ${activeMenu ? "my-0" : "my-6"}`}
                                  >
                                    {link.name}
                                  </p>
                                  {link.items && (
                                    <p
                                      onClick={toggleDropdownPaidAds}
                                      className="text-right px-2 font-bold"
                                    >
                                      {dropdownOpenPaidAds ? (
                                        <PiCaretUpLight />
                                      ) : (
                                        <PiCaretDownLight />
                                      )}
                                    </p>
                                  )}
                                </div>
                              </NavLink>
                              {link.items &&
                                dropdownOpenPaidAds &&
                                activeMenu && (
                                  <div className="ml-8">
                                    {link.items.map((subItem) => (
                                      <>
                                        {subItem.name === "Google Ads" && (
                                          <div key={subItem.name}>
                                            <NavLink
                                              to={`/${subItem.path}`}
                                              onClick={() => {
                                                handleCloseSideBar();
                                                toggleDropdownGoogle();
                                              }}
                                              style={({ isActive }) => ({
                                                backgroundColor: isActive
                                                  ? "#4e47e5"
                                                  : "",
                                              })}
                                              className={({ isActive }) =>
                                                `${
                                                  isActive
                                                    ? activeLink
                                                    : normalLink
                                                } ${
                                                  isActive
                                                    ? "text-white"
                                                    : "text-[#e6e5e3]"
                                                }`
                                              }
                                            >
                                              <p
                                                className={`capitalize text-base  ${
                                                  activeMenu
                                                    ? "text-lg"
                                                    : "text-xl"
                                                } ${
                                                  activeMenu ? "my-0" : "my-6"
                                                }`}
                                              >
                                                {subItem.icon}
                                              </p>
                                              <div
                                                className={`flex items-center justify-between w-full ${
                                                  activeMenu
                                                    ? "block"
                                                    : "hidden"
                                                } `}
                                              >
                                                <p
                                                  onClick={toggleDropdownGoogle}
                                                  className={`capitalize  pl-0 text-base  ${
                                                    activeMenu
                                                      ? "text-lg"
                                                      : "text-xl"
                                                  } ${
                                                    activeMenu ? "my-0" : "my-6"
                                                  }`}
                                                >
                                                  {subItem.name}
                                                </p>
                                                {subItem.items && (
                                                  <p
                                                    onClick={
                                                      toggleDropdownGoogle
                                                    }
                                                    className="text-right  font-bold"
                                                  >
                                                    {dropdownOpenGoogle ? (
                                                      <PiCaretUpLight />
                                                    ) : (
                                                      <PiCaretDownLight />
                                                    )}
                                                  </p>
                                                )}
                                              </div>
                                            </NavLink>

                                            {dropdownOpenGoogle &&
                                              activeMenu && (
                                                <div className="pl-8">
                                                  {subItem.items.map(
                                                    (subSubItem) => (
                                                      <div key={subSubItem}>
                                                        {subSubItem.name ===
                                                          "Performance" && (
                                                          <NavLink
                                                            to={`/${subSubItem.path}`}
                                                            onClick={() => {
                                                              handleCloseSideBar();
                                                              toggleDropdownView();
                                                            }}
                                                            style={({
                                                              isActive,
                                                            }) => ({
                                                              backgroundColor:
                                                                isActive
                                                                  ? "#4e47e5"
                                                                  : "",
                                                            })}
                                                            className={({
                                                              isActive,
                                                            }) =>
                                                              `${
                                                                isActive
                                                                  ? activeLink
                                                                  : normalLink
                                                              } ${
                                                                isActive
                                                                  ? "text-white gap-0"
                                                                  : "text-[#e6e5e3] gap-0"
                                                              }`
                                                            }
                                                          >
                                                            <p
                                                              className={`capitalize pr-1 text-base  ${
                                                                activeMenu
                                                                  ? "text-lg"
                                                                  : "text-xl"
                                                              } ${
                                                                activeMenu
                                                                  ? "my-0"
                                                                  : "my-6"
                                                              }`}
                                                            >
                                                              {subSubItem.icon}
                                                            </p>
                                                            <div
                                                              className={`flex items-center justify-between w-full ${
                                                                activeMenu
                                                                  ? "block"
                                                                  : "hidden"
                                                              } `}
                                                            >
                                                              <p
                                                                onClick={
                                                                  toggleDropdownView
                                                                }
                                                                className={`capitalize text-base ml-2 ${
                                                                  activeMenu
                                                                    ? "text-lg"
                                                                    : "text-xl"
                                                                } ${
                                                                  activeMenu
                                                                    ? "my-0"
                                                                    : "my-6"
                                                                }`}
                                                              >
                                                                {
                                                                  subSubItem.name
                                                                }
                                                              </p>
                                                              {subSubItem.items && (
                                                                <p
                                                                  onClick={
                                                                    toggleDropdownView
                                                                  }
                                                                  className="text-right  font-bold"
                                                                >
                                                                  {dropdownView ? (
                                                                    <PiCaretUpLight />
                                                                  ) : (
                                                                    <PiCaretDownLight />
                                                                  )}
                                                                </p>
                                                              )}
                                                            </div>
                                                          </NavLink>
                                                        )}
                                                        {subSubItem.name ===
                                                          "Performance" &&
                                                          dropdownView &&
                                                          activeMenu && (
                                                            <div className="ml-8">
                                                              {subSubItem.items.map(
                                                                (subItem2) => (
                                                                  <NavLink
                                                                    to={`/${subItem2.path}`}
                                                                    onClick={
                                                                      handleCloseSideBar
                                                                    }
                                                                    style={({
                                                                      isActive,
                                                                    }) => ({
                                                                      backgroundColor:
                                                                        isActive
                                                                          ? "#4e47e5"
                                                                          : "",
                                                                    })}
                                                                    className={({
                                                                      isActive,
                                                                    }) =>
                                                                      `${
                                                                        isActive
                                                                          ? activeLink
                                                                          : normalLink
                                                                      } ${
                                                                        isActive
                                                                          ? "text-white"
                                                                          : "text-[#e6e5e3]"
                                                                      }`
                                                                    }
                                                                    key={
                                                                      subItem2.name
                                                                    }
                                                                  >
                                                                    <div className="flex  pl-0 items-center">
                                                                      <LiaDotCircleSolid />
                                                                      <span className="capitalize ml-2 text-sm">
                                                                        {
                                                                          subItem2.name
                                                                        }
                                                                      </span>
                                                                    </div>
                                                                  </NavLink>
                                                                )
                                                              )}
                                                            </div>
                                                          )}
                                                        {subSubItem.name ===
                                                          "Reporting" && (
                                                          <NavLink
                                                            to={`/${subSubItem.path}`}
                                                            onClick={() => {
                                                              handleCloseSideBar();
                                                              toggleDropdownGoogleReport();
                                                            }}
                                                            style={({
                                                              isActive,
                                                            }) => ({
                                                              backgroundColor:
                                                                isActive
                                                                  ? "#4e47e5"
                                                                  : "",
                                                            })}
                                                            className={({
                                                              isActive,
                                                            }) =>
                                                              `${
                                                                isActive
                                                                  ? activeLink
                                                                  : normalLink
                                                              } ${
                                                                isActive
                                                                  ? "text-white gap-0"
                                                                  : "text-[#e6e5e3] gap-0"
                                                              }`
                                                            }
                                                          >
                                                            <p
                                                              className={`capitalize pr-1 text-base  ${
                                                                activeMenu
                                                                  ? "text-lg"
                                                                  : "text-xl"
                                                              } ${
                                                                activeMenu
                                                                  ? "my-0"
                                                                  : "my-6"
                                                              }`}
                                                            >
                                                              {subSubItem.icon}
                                                            </p>
                                                            <div
                                                              className={`flex items-center justify-between w-full ${
                                                                activeMenu
                                                                  ? "block"
                                                                  : "hidden"
                                                              } `}
                                                            >
                                                              <p
                                                                onClick={
                                                                  toggleDropdownGoogleReport
                                                                }
                                                                className={`capitalize text-base ml-2 ${
                                                                  activeMenu
                                                                    ? "text-lg"
                                                                    : "text-xl"
                                                                } ${
                                                                  activeMenu
                                                                    ? "my-0"
                                                                    : "my-6"
                                                                }`}
                                                              >
                                                                {
                                                                  subSubItem.name
                                                                }
                                                              </p>
                                                              {subSubItem.items && (
                                                                <p
                                                                  onClick={
                                                                    toggleDropdownGoogleReport
                                                                  }
                                                                  className="text-right  font-bold"
                                                                >
                                                                  {dropdownView ? (
                                                                    <PiCaretUpLight />
                                                                  ) : (
                                                                    <PiCaretDownLight />
                                                                  )}
                                                                </p>
                                                              )}
                                                            </div>
                                                          </NavLink>
                                                        )}
                                                        {subSubItem.name ===
                                                          "Reporting" &&
                                                          dropdownOpenGoogleReport &&
                                                          activeMenu && (
                                                            <div className="ml-5">
                                                              {subSubItem.items.map(
                                                                (subItem2) => (
                                                                  <NavLink
                                                                    to={`/${subItem2.path}`}
                                                                    onClick={
                                                                      handleCloseSideBar
                                                                    }
                                                                    style={({
                                                                      isActive,
                                                                    }) => ({
                                                                      backgroundColor:
                                                                        isActive
                                                                          ? "#4e47e5"
                                                                          : "",
                                                                    })}
                                                                    className={({
                                                                      isActive,
                                                                    }) =>
                                                                      `${
                                                                        isActive
                                                                          ? activeLink
                                                                          : normalLink
                                                                      } ${
                                                                        isActive
                                                                          ? "text-white"
                                                                          : "text-[#e6e5e3]"
                                                                      }`
                                                                    }
                                                                    key={
                                                                      subItem2.name
                                                                    }
                                                                  >
                                                                    <div className="flex  pl-0 items-center text-sm">
                                                                      <LiaDotCircleSolid />
                                                                      <span className="capitalize ml-2 text-sm">
                                                                        {
                                                                          subItem2.name
                                                                        }
                                                                      </span>
                                                                    </div>
                                                                  </NavLink>
                                                                )
                                                              )}
                                                            </div>
                                                          )}
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              )}
                                          </div>
                                        )}

                                        {subItem.name === "Bing Ads" && (
                                          <div key={subItem.name}>
                                            <NavLink
                                              to={`/${subItem.path}`}
                                              onClick={() => {
                                                handleCloseSideBar();
                                                toggleDropdownBing();
                                              }}
                                              style={({ isActive }) => ({
                                                backgroundColor: isActive
                                                  ? "#4e47e5"
                                                  : "",
                                              })}
                                              className={({ isActive }) =>
                                                `${
                                                  isActive
                                                    ? activeLink
                                                    : normalLink
                                                } ${
                                                  isActive
                                                    ? "text-white"
                                                    : "text-[#e6e5e3]"
                                                }`
                                              }
                                            >
                                              <p
                                                className={`capitalize text-base  ${
                                                  activeMenu
                                                    ? "text-lg"
                                                    : "text-xl"
                                                } ${
                                                  activeMenu ? "my-0" : "my-6"
                                                }`}
                                              >
                                                {subItem.icon}
                                              </p>
                                              <div
                                                className={`flex items-center justify-between w-full ${
                                                  activeMenu
                                                    ? "block"
                                                    : "hidden"
                                                } `}
                                              >
                                                <p
                                                  onClick={toggleDropdownBing}
                                                  className={`capitalize  pl-0 text-base  ${
                                                    activeMenu
                                                      ? "text-lg"
                                                      : "text-xl"
                                                  } ${
                                                    activeMenu ? "my-0" : "my-6"
                                                  }`}
                                                >
                                                  {subItem.name}
                                                </p>
                                                {subItem.items && (
                                                  <p
                                                    onClick={toggleDropdownBing}
                                                    className="text-right  font-bold"
                                                  >
                                                    {dropdownOpenBing ? (
                                                      <PiCaretUpLight />
                                                    ) : (
                                                      <PiCaretDownLight />
                                                    )}
                                                  </p>
                                                )}
                                              </div>
                                            </NavLink>

                                            {dropdownOpenBing && (
                                              <div className="pl-8">
                                                {subItem.items.map(
                                                  (subSubItem) => (
                                                    <div
                                                      key={subSubItem}
                                                      onClick={
                                                        toggleDropdownBingView
                                                      }
                                                    >
                                                      {subSubItem.name ===
                                                        "Performance" && (
                                                        <NavLink
                                                          to={`/${subSubItem.path}`}
                                                          onClick={
                                                            handleCloseSideBar
                                                          }
                                                          style={({
                                                            isActive,
                                                          }) => ({
                                                            backgroundColor:
                                                              isActive
                                                                ? "#4e47e5"
                                                                : "",
                                                          })}
                                                          className={({
                                                            isActive,
                                                          }) =>
                                                            `${
                                                              isActive
                                                                ? activeLink
                                                                : normalLink
                                                            } ${
                                                              isActive
                                                                ? "text-white gap-0"
                                                                : "text-[#e6e5e3] gap-0"
                                                            }`
                                                          }
                                                        >
                                                          <p
                                                            className={`capitalize pr-1 text-base  ${
                                                              activeMenu
                                                                ? "text-lg"
                                                                : "text-xl"
                                                            } ${
                                                              activeMenu
                                                                ? "my-0"
                                                                : "my-6"
                                                            }`}
                                                          >
                                                            {subSubItem.icon}
                                                          </p>
                                                          <div
                                                            className={`flex items-center justify-between w-full ${
                                                              activeMenu
                                                                ? "block"
                                                                : "hidden"
                                                            } `}
                                                          >
                                                            <p
                                                              onClick={
                                                                toggleDropdownBingView
                                                              }
                                                              className={`capitalize text-base ml-2 ${
                                                                activeMenu
                                                                  ? "text-lg"
                                                                  : "text-xl"
                                                              } ${
                                                                activeMenu
                                                                  ? "my-0"
                                                                  : "my-6"
                                                              }`}
                                                            >
                                                              {subSubItem.name}
                                                            </p>
                                                            {subSubItem.items && (
                                                              <p
                                                                onClick={
                                                                  toggleDropdownBingView
                                                                }
                                                                className="text-right  font-bold"
                                                              >
                                                                {dropdownOpenBingView ? (
                                                                  <PiCaretUpLight />
                                                                ) : (
                                                                  <PiCaretDownLight />
                                                                )}
                                                              </p>
                                                            )}
                                                          </div>
                                                        </NavLink>
                                                      )}
                                                      {subSubItem.name ===
                                                        "Performance" &&
                                                        dropdownOpenBingView && (
                                                          <div className="ml-8">
                                                            {subSubItem.items.map(
                                                              (subItem2) => (
                                                                <NavLink
                                                                  to={`/${subItem2.path}`}
                                                                  onClick={
                                                                    handleCloseSideBar
                                                                  }
                                                                  style={({
                                                                    isActive,
                                                                  }) => ({
                                                                    backgroundColor:
                                                                      isActive
                                                                        ? "#4e47e5"
                                                                        : "",
                                                                  })}
                                                                  className={({
                                                                    isActive,
                                                                  }) =>
                                                                    `${
                                                                      isActive
                                                                        ? activeLink
                                                                        : normalLink
                                                                    } ${
                                                                      isActive
                                                                        ? "text-white"
                                                                        : "text-[#e6e5e3]"
                                                                    }`
                                                                  }
                                                                  key={
                                                                    subItem2.name
                                                                  }
                                                                >
                                                                  <div className="flex  pl-0 items-center">
                                                                    <LiaDotCircleSolid />
                                                                    <span className="capitalize ml-2 text-sm">
                                                                      {
                                                                        subItem2.name
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </NavLink>
                                                              )
                                                            )}
                                                          </div>
                                                        )}
                                                      {subSubItem.name ===
                                                        "Reporting" && (
                                                        <NavLink
                                                          to={`/${subSubItem.path}`}
                                                          onClick={
                                                            handleCloseSideBar
                                                          }
                                                          style={({
                                                            isActive,
                                                          }) => ({
                                                            backgroundColor:
                                                              isActive
                                                                ? "#4e47e5"
                                                                : "",
                                                          })}
                                                          className={({
                                                            isActive,
                                                          }) =>
                                                            `${
                                                              isActive
                                                                ? activeLink
                                                                : normalLink
                                                            } ${
                                                              isActive
                                                                ? "text-white"
                                                                : "text-[#e6e5e3]"
                                                            }`
                                                          }
                                                          key={subSubItem.name}
                                                        >
                                                          <div className="flex  pl-1 items-center">
                                                            {subSubItem.icon}
                                                            <span className="capitalize ml-2 text-sm">
                                                              {subSubItem.name}
                                                            </span>
                                                          </div>
                                                        </NavLink>
                                                      )}
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </>
                                    ))}
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      )}
                      {item.title === "Ads." && (
                        <div
                          className={`text-lg lg:text-base ${
                            activeMenu ? "mx-4" : "mx-2"
                          }`}
                        >
                          {item.links.map((link) => (
                            <div key={link.name}>
                              <NavLink
                                to={`/${link.path}`}
                                onClick={() => {
                                  handleCloseSideBar();
                                  toggleDropdownAds();
                                }}
                                style={({ isActive }) => ({
                                  backgroundColor: isActive ? "#4e47e5" : "",
                                })}
                                className={({ isActive }) =>
                                  `${isActive ? activeLink : normalLink} ${
                                    isActive ? "text-white" : "text-[#e6e5e3]"
                                  } ${activeMenu ? "my-2" : "my-6"} ${
                                    activeMenu
                                      ? "text-lg"
                                      : "text-xl mx-0 justify-center rounded-full"
                                  }`
                                }
                              >
                                {link.icon}
                                <div
                                  className={`flex items-center justify-between w-full ${
                                    activeMenu ? "block" : "hidden"
                                  } `}
                                >
                                  <p
                                    onClick={toggleDropdownAds}
                                    className={`capitalize text-base  ${
                                      activeMenu ? "text-lg" : "text-xl"
                                    } ${activeMenu ? "my-0" : "my-6"}`}
                                  >
                                    {link.name}
                                  </p>
                                  
                                </div>
                              </NavLink>
                              {link.items &&
                                dropdownOpenAds &&
                                activeMenu && (
                                  <div className="ml-8">
                                    {link.items.map((subItem) => (
                                      <>
                                        {subItem.name === "PromoRecap" && (
                                          <div key={subItem.name}>
                                            <NavLink
                                              to={`/${subItem.path}`}
                                              onClick={() => {
                                                handleCloseSideBar();
                                                toggleDropdownGoogle();
                                              }}
                                              style={({ isActive }) => ({
                                                backgroundColor: isActive
                                                  ? "#4e47e5"
                                                  : "",
                                              })}
                                              className={({ isActive }) =>
                                                `${
                                                  isActive
                                                    ? activeLink
                                                    : normalLink
                                                } ${
                                                  isActive
                                                    ? "text-white"
                                                    : "text-[#e6e5e3]"
                                                }`
                                              }
                                            >
                                              <p
                                                className={`capitalize text-base  ${
                                                  activeMenu
                                                    ? "text-lg"
                                                    : "text-xl"
                                                } ${
                                                  activeMenu ? "my-0" : "my-6"
                                                }`}
                                              >
                                                {subItem.icon}
                                              </p>
                                              <div
                                                className={`flex items-center justify-between w-full ${
                                                  activeMenu
                                                    ? "block"
                                                    : "hidden"
                                                } `}
                                              >
                                                <p
                                                  onClick={toggleDropdownGoogle}
                                                  className={`capitalize  pl-0 text-base  ${
                                                    activeMenu
                                                      ? "text-lg"
                                                      : "text-xl"
                                                  } ${
                                                    activeMenu ? "my-0" : "my-6"
                                                  }`}
                                                >
                                                  {subItem.name}
                                                </p>
                                                {subItem.items && (
                                                  <p
                                                    onClick={
                                                      toggleDropdownGoogle
                                                    }
                                                    className="text-right  font-bold"
                                                  >
                                                    {dropdownOpenGoogle ? (
                                                      <PiCaretUpLight />
                                                    ) : (
                                                      <PiCaretDownLight />
                                                    )}
                                                  </p>
                                                )}
                                              </div>
                                            </NavLink>
                                          </div>
                                        )}

                                        {subItem.name === "Bing Ads" && (
                                          <div key={subItem.name}>
                                            <NavLink
                                              to={`/${subItem.path}`}
                                              onClick={() => {
                                                handleCloseSideBar();
                                                toggleDropdownBing();
                                              }}
                                              style={({ isActive }) => ({
                                                backgroundColor: isActive
                                                  ? "#4e47e5"
                                                  : "",
                                              })}
                                              className={({ isActive }) =>
                                                `${
                                                  isActive
                                                    ? activeLink
                                                    : normalLink
                                                } ${
                                                  isActive
                                                    ? "text-white"
                                                    : "text-[#e6e5e3]"
                                                }`
                                              }
                                            >
                                              <p
                                                className={`capitalize text-base  ${
                                                  activeMenu
                                                    ? "text-lg"
                                                    : "text-xl"
                                                } ${
                                                  activeMenu ? "my-0" : "my-6"
                                                }`}
                                              >
                                                {subItem.icon}
                                              </p>
                                              <div
                                                className={`flex items-center justify-between w-full ${
                                                  activeMenu
                                                    ? "block"
                                                    : "hidden"
                                                } `}
                                              >
                                                <p
                                                  onClick={toggleDropdownBing}
                                                  className={`capitalize  pl-0 text-base  ${
                                                    activeMenu
                                                      ? "text-lg"
                                                      : "text-xl"
                                                  } ${
                                                    activeMenu ? "my-0" : "my-6"
                                                  }`}
                                                >
                                                  {subItem.name}
                                                </p>
                                                {subItem.items && (
                                                  <p
                                                    onClick={toggleDropdownBing}
                                                    className="text-right  font-bold"
                                                  >
                                                    {dropdownOpenBing ? (
                                                      <PiCaretUpLight />
                                                    ) : (
                                                      <PiCaretDownLight />
                                                    )}
                                                  </p>
                                                )}
                                              </div>
                                            </NavLink>

                                            {dropdownOpenBing && (
                                              <div className="pl-8">
                                                {subItem.items.map(
                                                  (subSubItem) => (
                                                    <div
                                                      key={subSubItem}
                                                      onClick={
                                                        toggleDropdownBingView
                                                      }
                                                    >
                                                      {subSubItem.name ===
                                                        "Performance" && (
                                                        <NavLink
                                                          to={`/${subSubItem.path}`}
                                                          onClick={
                                                            handleCloseSideBar
                                                          }
                                                          style={({
                                                            isActive,
                                                          }) => ({
                                                            backgroundColor:
                                                              isActive
                                                                ? "#4e47e5"
                                                                : "",
                                                          })}
                                                          className={({
                                                            isActive,
                                                          }) =>
                                                            `${
                                                              isActive
                                                                ? activeLink
                                                                : normalLink
                                                            } ${
                                                              isActive
                                                                ? "text-white gap-0"
                                                                : "text-[#e6e5e3] gap-0"
                                                            }`
                                                          }
                                                        >
                                                          <p
                                                            className={`capitalize pr-1 text-base  ${
                                                              activeMenu
                                                                ? "text-lg"
                                                                : "text-xl"
                                                            } ${
                                                              activeMenu
                                                                ? "my-0"
                                                                : "my-6"
                                                            }`}
                                                          >
                                                            {subSubItem.icon}
                                                          </p>
                                                          <div
                                                            className={`flex items-center justify-between w-full ${
                                                              activeMenu
                                                                ? "block"
                                                                : "hidden"
                                                            } `}
                                                          >
                                                            <p
                                                              onClick={
                                                                toggleDropdownBingView
                                                              }
                                                              className={`capitalize text-base ml-2 ${
                                                                activeMenu
                                                                  ? "text-lg"
                                                                  : "text-xl"
                                                              } ${
                                                                activeMenu
                                                                  ? "my-0"
                                                                  : "my-6"
                                                              }`}
                                                            >
                                                              {subSubItem.name}
                                                            </p>
                                                            {subSubItem.items && (
                                                              <p
                                                                onClick={
                                                                  toggleDropdownBingView
                                                                }
                                                                className="text-right  font-bold"
                                                              >
                                                                {dropdownOpenBingView ? (
                                                                  <PiCaretUpLight />
                                                                ) : (
                                                                  <PiCaretDownLight />
                                                                )}
                                                              </p>
                                                            )}
                                                          </div>
                                                        </NavLink>
                                                      )}
                                                      {subSubItem.name ===
                                                        "Performance" &&
                                                        dropdownOpenBingView && (
                                                          <div className="ml-8">
                                                            {subSubItem.items.map(
                                                              (subItem2) => (
                                                                <NavLink
                                                                  to={`/${subItem2.path}`}
                                                                  onClick={
                                                                    handleCloseSideBar
                                                                  }
                                                                  style={({
                                                                    isActive,
                                                                  }) => ({
                                                                    backgroundColor:
                                                                      isActive
                                                                        ? "#4e47e5"
                                                                        : "",
                                                                  })}
                                                                  className={({
                                                                    isActive,
                                                                  }) =>
                                                                    `${
                                                                      isActive
                                                                        ? activeLink
                                                                        : normalLink
                                                                    } ${
                                                                      isActive
                                                                        ? "text-white"
                                                                        : "text-[#e6e5e3]"
                                                                    }`
                                                                  }
                                                                  key={
                                                                    subItem2.name
                                                                  }
                                                                >
                                                                  <div className="flex  pl-0 items-center">
                                                                    <LiaDotCircleSolid />
                                                                    <span className="capitalize ml-2 text-sm">
                                                                      {
                                                                        subItem2.name
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </NavLink>
                                                              )
                                                            )}
                                                          </div>
                                                        )}
                                                      {subSubItem.name ===
                                                        "Reporting" && (
                                                        <NavLink
                                                          to={`/${subSubItem.path}`}
                                                          onClick={
                                                            handleCloseSideBar
                                                          }
                                                          style={({
                                                            isActive,
                                                          }) => ({
                                                            backgroundColor:
                                                              isActive
                                                                ? "#4e47e5"
                                                                : "",
                                                          })}
                                                          className={({
                                                            isActive,
                                                          }) =>
                                                            `${
                                                              isActive
                                                                ? activeLink
                                                                : normalLink
                                                            } ${
                                                              isActive
                                                                ? "text-white"
                                                                : "text-[#e6e5e3]"
                                                            }`
                                                          }
                                                          key={subSubItem.name}
                                                        >
                                                          <div className="flex  pl-1 items-center">
                                                            {subSubItem.icon}
                                                            <span className="capitalize ml-2 text-sm">
                                                              {subSubItem.name}
                                                            </span>
                                                          </div>
                                                        </NavLink>
                                                      )}
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </>
                                    ))}
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      )}

                      {item.title === "Ad Crafting" && (
                        <div
                          className={`text-lg lg:text-base ${
                            activeMenu ? "mx-4" : "mx-2"
                          }`}
                        >
                          {item.links.map((link) => (
                            <NavLink
                              to={`/${link.path}`}
                              key={link.name}
                              onClick={handleCloseSideBar}
                              style={({ isActive }) => ({
                                backgroundColor: isActive ? "#4e47e5" : "",
                              })}
                              className={({ isActive }) =>
                                `${isActive ? activeLink : normalLink} ${
                                  isActive ? "text-white" : "text-[#e6e5e3]"
                                } ${
                                  activeMenu
                                    ? "text-lg"
                                    : "text-xl rounded-full"
                                } ${
                                  activeMenu
                                    ? "my-2"
                                    : "my-6 mx-0 justify-center"
                                }`
                              }
                            >
                              {link.icon}
                              <span
                                className={`capitalize  text-base  ${
                                  activeMenu ? "block" : "hidden"
                                } `}
                              >
                                {link.name}
                              </span>
                            </NavLink>
                          ))}
                        </div>
                      )}

                      {item.title === "Alerts" && (
                        <div
                          className={`text-lg lg:text-base ${
                            activeMenu ? "mx-4" : "mx-2"
                          }`}
                        >
                          {item.links.map((link) => (
                            <>
                              <NavLink
                                to={`/${link.path}`}
                                key={link.name}
                                onClick={() => {
                                  handleCloseSideBar();
                                  toggleDropdownAlerts();
                                }}
                                style={({ isActive }) => ({
                                  backgroundColor: isActive ? "#4e47e5" : "",
                                })}
                                className={({ isActive }) =>
                                  `${isActive ? activeLink : normalLink} ${
                                    isActive ? "text-white" : "text-[#e6e5e3]"
                                  } ${
                                    activeMenu
                                      ? "text-lg"
                                      : "text-xl rounded-full"
                                  } ${
                                    activeMenu
                                      ? "my-2"
                                      : "my-6 mx-0 justify-center"
                                  }`
                                }
                              >
                                {link.icon}

                                <div
                                  className={`flex items-center justify-between w-full ${
                                    activeMenu ? "block" : "hidden"
                                  } `}
                                >
                                  <p
                                    onClick={toggleDropdownAlerts}
                                    className={`capitalize  text-base  ${
                                      activeMenu ? "text-lg" : "text-xl"
                                    } ${activeMenu ? "my-0" : "my-6"}`}
                                  >
                                    {link.name}
                                  </p>
                                  {link.items && (
                                    <p
                                      onClick={toggleDropdownAlerts}
                                      className="text-right px-2 font-bold"
                                    >
                                      {dropdownOpenAlerts ? (
                                        <PiCaretUpLight />
                                      ) : (
                                        <PiCaretDownLight />
                                      )}
                                    </p>
                                  )}
                                </div>
                              </NavLink>
                              {dropdownOpenAlerts && activeMenu && (
                                <div className="ml-8">
                                  {link.items.map((subItem) => (
                                    <>
                                      {activeMenu && (
                                        <NavLink
                                          to={`/${subItem.path}`}
                                          onClick={handleCloseSideBar}
                                          style={({ isActive }) => ({
                                            backgroundColor: isActive
                                              ? "#4e47e5"
                                              : "",
                                          })}
                                          className={({ isActive }) =>
                                            `${
                                              isActive ? activeLink : normalLink
                                            } ${
                                              isActive
                                                ? "text-white gap-2"
                                                : "text-[#e6e5e3] gap-2"
                                            }`
                                          }
                                          key={subItem.name}
                                        >
                                          <p className="font-bold">
                                            <LiaDotCircleSolid />
                                          </p>
                                          <div
                                            className={`flex items-center w-full ${
                                              activeMenu ? "block" : "hidden"
                                            } `}
                                          >
                                            <p
                                              className={`capitalize  text-base  ${
                                                activeMenu
                                                  ? "text-lg"
                                                  : "text-xl"
                                              } ${
                                                activeMenu ? "my-0" : "my-6"
                                              }`}
                                            >
                                              {subItem.name}
                                            </p>
                                          </div>
                                        </NavLink>
                                      )}
                                    </>
                                  ))}
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className={`mb-4 fixed bottom-0 mt-4 `}>
        {activeMenu ? (
          <>
            <div className="flex flex-col">
              {/* <div className="flex items-center rounded-lg text-[#e6e5e3] px-2 py-3  w-60  hover:bg-[#495057]  text-base ml-6">
                <ProjectSidebar />
              </div> */}
              {/* <div className="flex ">
                <NavLink
                  to="/profile"
                  className="flex items-center rounded-lg text-[#e6e5e3] px-4 py-3 w-60  hover:bg-[#495057]  text-base ml-6"
                >
                  <CiSettings />

                  <span className="ml-2">Account Settings</span>
                </NavLink>
              </div> */}
              {/* <div className="flex ">
                <NavLink
                  to="/billing-support"
                  className="flex items-center rounded-lg text-[#e6e5e3] px-4 py-3 w-60  hover:bg-[#495057]  text-base ml-6"
                >
                  <LiaMoneyBillWaveAltSolid />

                  <span className="ml-2">Billing</span>
                </NavLink>
              </div> */}
              {/* <div className="flex ">
                <NavLink
                  to="/customer-support"
                  className="flex items-center rounded-lg text-[#e6e5e3] px-4 py-3 w-60  hover:bg-[#495057]  text-base ml-6"
                >
                  <RiCustomerService2Line />

                  <span className="ml-2">Support</span>
                </NavLink>
              </div> */}
            </div>
            <div className="flex flex-col space-y-2">
              <NavLink to={"/change-log"}
              className="relative ml-10 text-[#e6e5e3]  mt-2 items-center flex justify-center py-2  text-sm lg:text-base font-medium rounded-xl bg-transparent">Change Log</NavLink>
              
              <button
                className={`group relative ml-10 text-[#e6e5e3]  mt-2 items-center flex justify-center py-2  text-sm lg:text-base font-medium rounded-xl bg-transparent  ${
                  activeMenu ? "flex-row" : "flex-col"
                }`}
                onClick={handleLogout}
              >
                Log Out
                <span
                  className={`ml-2 ${
                    activeMenu ? "block transform -rotate-90" : "hidden"
                  }`}
                >
                  <AiOutlineLogout />
                </span>
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="flex  flex-col">
              <div className=" flex justify-center items-center rounded-full w-20 ">
                <ProjectSidebar />
              </div>
              <div className=" flex justify-center items-center w-20">
                <NavLink
                  to="/profile"
                  className="text-[#e6e5e3] items-center flex justify-center font-extrabold text-lg lg:text-2xl rounded-full bg-transparent hover:bg-[#495057] px-4 mb-2  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="my-4">
                    <CiSettings />
                  </span>
                </NavLink>
              </div>
              <div className=" flex justify-center items-center w-20">
                <NavLink
                  to="/billing-support"
                  className="text-[#e6e5e3] items-center flex justify-center font-extrabold text-lg lg:text-2xl rounded-full bg-transparent hover:bg-[#495057] px-4 mb-2  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="my-4">
                    <LiaMoneyBillWaveAltSolid />
                  </span>
                </NavLink>
              </div>
              <div className=" flex justify-center items-center w-20">
                <NavLink
                  to="/customer-support"
                  className="text-[#e6e5e3] items-center flex justify-center font-extrabold text-lg lg:text-2xl rounded-full bg-transparent hover:bg-[#495057] px-4 mb-2  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="my-4">
                    <RiCustomerService2Line />
                  </span>
                </NavLink>
              </div>
            </div>
            <div className=" flex justify-center items-center w-20">
              <button
                className={`group relative transform -rotate-90   text-[#e6e5e3] items-center flex justify-center font-extrabold text-lg lg:text-3xl rounded-full bg-transparent hover:bg-[#5147de] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                  activeMenu ? "flex-row" : "flex-col"
                }`}
                onClick={handleLogout}
              >
                <AiOutlineLogout />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
