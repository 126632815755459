import React, { useState } from 'react'
import { IoMdClose } from 'react-icons/io';
import GenderChat from "./Charts/GenderChat";
import Agechart from "./Charts/Agechart";

const AddChart = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [isAudienceSelected, setIsAudienceSelected] = useState(false); // State to control showing the audience component
    const [isGenderInfoSelected, setIsGenderInfoSelected] = useState(false);
    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };
    const handleAudienceOptionClick = () => {
        setIsAudienceSelected(true); // Show the AudienceDataComponent when "Audience" is selected
        setShowDropdown(false); // Close dropdown
    };
    const handleGenderInfoOptionClick = () => {
        setIsGenderInfoSelected(true); // Show the AudienceDataComponent when "Audience" is selected
        setShowDropdown(false); // Close dropdown
    };
    return (
        <>
            <div className="text-center mt-4 flex flex-col ">
                <button
                    onClick={toggleDropdown}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center justify-center  w-fit"
                >
                    Add Chart
                </button>

                {/* Dropdown Menu */}
                {showDropdown && (
                    <div className="mt-2 bg-white border border-gray-200 rounded-lg shadow-lg inline-block w-fit">
                        <ul className="py-2">
                            <li
                                onClick={handleAudienceOptionClick}
                                className="cursor-pointer hover:bg-gray-100 px-4 py-2"
                            >
                                Age
                            </li>
                            <li
                                onClick={handleGenderInfoOptionClick}
                                className="cursor-pointer hover:bg-gray-100 px-4 py-2"
                            >
                                Gender
                            </li>
                            <li
                                // onClick={handleGenderInfoOptionClick}
                                className="cursor-pointer hover:bg-gray-100 px-4 py-2"
                            >
                                {/* <AddCampaignTableWeekly /> */}
                            </li>
                        </ul>
                    </div>
                )}
            </div>
            <div className="flex ">
            {isAudienceSelected && (
                <div className="mt-8 mr-20">
                    <div className="flex items-center justify-between">
                        <div>
                            <h2 className="text-2xl font-semibold text-center text-blue-600">Audience Data</h2>
                        </div>

                    </div>
                    <Agechart/>
                </div>
            )}
            {isGenderInfoSelected && (
                <div className="mt-8 ml-20">
                    <div className="flex items-center justify-between">
                        <div>
                            <h2 className="text-2xl font-semibold text-center text-blue-600">GenderInfo</h2>
                        </div>
                        <div className="">
                            <button className='p-2 text-lg bg-red-600 text-white rounded-sm'
                                onClick=
                                ''><IoMdClose /></button>
                        </div>
                    </div>

                    {/* Audience Data Table */}
                    <GenderChat />
                </div>
            )}
            </div>
        </>
    )
}

export default AddChart;
