import axios from 'axios'
import React, { useEffect, useReducer, useState } from 'react'
import LoadingAnimation from '../components/LoadingAnimation'

function ChangeLog() {
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([
        {title:"Date", key:"date",visible:true},
        {title:"Name/Owner", key:"name_owner",visible:true},
        {title:"Engine", key:"engine",visible:true},
        {title:"Account", key:"account",visible:true},
        {title:"Campagin/AdGroup", key:"campaign_ad_group",visible:true},
        {title:"Change", key:"change_description",visible:true},
    ])
    const [isLoading, setIsLoading] = useState(true)
    const [dataIsNull, setDataIsNull] = useState(true)

    const [campaigns,setCampaigns] = useState([])
    
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [random, setRandom] = useState(Math.random())

    // Form 
    const [formSymbol, setFormSymbol] = useState("+")
    const [formVisible, setFormVisible] = useState(false)
    const [formValues, setFormValues] = useState({
        name_owner:"",
        engine:"",
        account:"",
        campaign_ad_group:"",
        change_description:"",
        email:localStorage.getItem("email")
    })

    const handleFormChange = (e) => {
        setFormValues({...formValues,[e.target.name]:e.target.value})
        // console.log( formValues);
    }
    const handleFormSubmit = () => {
        console.log(formValues);
        // return
        axios.post("https://api.confidanto.com/change-log/create",formValues)
        .then(res=>{
            console.log("Data Added",res)
            setFormVisible(false)

            // forceUpdate()
            setRandom(Math.random())
        })
        .catch(error=>{
            console.log("Error");
        })
    }

    useEffect(()=>{
        axios.post("https://api.confidanto.com/get-campaigns-list",{
            customer_id: "4643036315",
            // start_date: "2024-01-01",
            // end_date: "2024-10-16",
        }).then(res=>{
            console.log("Campaigns ",res);
            setCampaigns(res.data)
        })
    },[])
    useEffect(()=>{
        setIsLoading(true)
        axios.post("https://api.confidanto.com/change-log/get",{
            email:localStorage.getItem("email")
        }).then(res=>{
            console.log(res.data.data);
            setData(res.data.data)
            
            setIsLoading(false)
            setDataIsNull(false)
        }).catch(res=>{
            setIsLoading(false)
            setDataIsNull(true)
        })
    },[random])
    return (
    <div className='mx-4'>
        <div className="table w-full my-4">

            <table 
            className="min-w-full bg-white rounded-md overflow-hidden shadow-md font-roboto"
            >
                <thead>
                    <tr className="bg-gray-200 normal-case text-sm leading-normal">
                        {columns.filter(col=>col.visible).map(col=>{
                            return (
                            <th 
                            key={col.key}
                            className="py-3 px-6 text-left font-medium"
                            >{col.title}</th>
                            )
                        })}
                    </tr>
                </thead>
                
                    {isLoading ? <>
                        {/* <div className="flex-row justify-center items-center w-full  h-40 mt-8">
                            <LoadingAnimation />
                        </div> */}
                    </>:<>
                        {dataIsNull?
                        <>
                            {/* <div className='bg-green-300 w-full'>
                                No data Found
                            </div> */}
                        </>
                        :<>
                        <tbody className='w-full '>

                            {data.map((item,index)=>
                                (
                                <tr key={index}>
                                    {columns.filter(col=>col.visible).map(col=>{
                                        return (<td key={col.key} className="py-3 px-6 text-left">
                                            {col.key !== "status" && (
                                            Array.isArray(item[col.key]) ? item[col.key].join(', ') : item[col.key]
                                            )}
                                        </td>)
                                    })}
                                </tr>
                                )
                            )}
                        </tbody>
                            
                        </>}
                    </>}
            </table>

            <div className="loading w-full flex items-center justify-center text-center p-10">
                {isLoading ? <>
                    <div className="flex-row justify-center items-center w-full  h-40 mt-8">
                        <LoadingAnimation />
                    </div>
                </>:
                    <>
                        {dataIsNull?
                        <div className='text-2xl w-full'>
                            No data Found
                        </div>
                        :<>                            
                    </>}
                </>}
            </div>
            
        </div>
        <div className="form w-full mb-40">
            <button 
            className='text-4xl bg-blue-500 text-white rounded-lg px-2 '
            onClick={()=>{setFormVisible(!formVisible)}}
            >{formSymbol}</button> 

            {formVisible && 
            <div className='w-full'>
                <h2 className='text-2xl text-center'>Add Log</h2>
                <div>
                    <div className='flex flex-col m-2'>
                        <label htmlFor=""
                        className='text-xl'>Name</label>
                        <input type="text" 
                        className='p-2 border-1 border-slate-400 rounded-md'
                        name='name_owner'
                        onChange={(e)=>{handleFormChange(e)}}
                        />
                    </div>

                    <div className='flex flex-col m-2'>
                        <label htmlFor=""
                        className='text-xl'>Engine</label>
                        
                        <select 
                        name='engine'
                        className='p-2 border-1 border-slate-400 rounded-md'
                        onChange={(e)=>{handleFormChange(e)}}
                        >
                            <option value="0">Select Engine</option>
                            <option value="Google">Google</option>
                            <option value="Microsoft">Microsoft</option>                            
                        </select>
                    </div>

                    <div className='flex flex-col m-2'>
                        <label htmlFor=""
                        className='text-xl'>Account</label>
                        
                        <select 
                        name='account'
                        className='p-2 border-1 border-slate-400 rounded-md'
                        onChange={(e)=>{handleFormChange(e)}}
                        >
                            <option value="0">Select Account</option>
                            <option value="SBB">SBB</option>
                            <option value="Checking">Checking</option>                            
                        </select>
                    </div>

                    <div className='flex flex-col m-2'>
                        <label htmlFor=""
                        className='text-xl'>Campaign/AdGroup</label>

                        <select 
                        name='campaign_ad_group'
                        className='p-2 border-1 border-slate-400 rounded-md'
                        onChange={(e)=>{handleFormChange(e)}}
                        >
                            <option value="0">Select Campaign</option>
                            {campaigns.map(item=>{
                                return <option value={item.name}>{item.name}</option>
                            })}
                        </select>
                    </div>

                    <div className='flex flex-col m-2'>
                        <label htmlFor=""
                        className='text-xl'>Change</label>
                        <input type="text" 
                        className='p-2 border-1 border-slate-400 rounded-md'
                        name='change_description'
                        onChange={(e)=>{handleFormChange(e)}}
                        />
                    </div>

                    <div className=' m-2'>
                        <button className='bg-blue-500 p-2 font-semibold text-white rounded-sm'
                        onClick={(e)=>{handleFormSubmit()}}
                        >Add</button>
                    </div>
                </div>
            </div>
            }
        </div>
    </div>
    )
}

export default ChangeLog