import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  IoIosArrowDown,
  IoIosArrowRoundDown,
  IoIosArrowRoundUp,
} from "react-icons/io";
import LoadingAnimation from "../components/LoadingAnimation";
import { weeklyReportInitialParagraph } from "./weeklyReportHelper";
import CategoryWeekly from "./CategoryWeekly";
import AddCampaignTableWeekly from "./AddCampaignTableWeekly";
import AddChart from "./ChartAdd";

import { IoMdShareAlt } from "react-icons/io";


function GoogleAdsWeeklyReporting() {
  const [reportData, setReportData] = useState(null);
  const [reportDataDetailed, setReportDataDetailed] = useState(null);
  const [receiverEmail, setReceiverEmail] = useState("");
  const [initialParagraph, setInitialParagraph] = useState("");

  const [showColumnsMenu, setShowColumnsMenu] = useState(false);

  const [visibleColumns, setVisibleColumns] = useState({
    date: true,
    cost: true,
    clicks: true,
    impressions: true,
    ctr: true,
    avgCpc: true,
    conversions: true,
    costPerConv: true,
    convRate: true,
  });

  const handleColumnToggle = (column) => {
    setVisibleColumns((prevState) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };

  const componentRef = useRef(null);

  // Call the function inside useEffect to avoid infinite re-renders
  useEffect(() => {
    if (reportData) {
      weeklyReportInitialParagraph(reportData, setInitialParagraph);
    }
  }, [reportData]); // This effect will run only when reportData changes

  useEffect(() => {
    fetch(
      "https://api.confidanto.com/weekly-reporting-google-ads/week-curr-prev-compare",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customer_id: 4643036315,
        }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); // Parse the JSON response
      })
      .then((data) => {
        setReportData(data); // Update state with the fetched data
      })
      .catch((error) => {
        console.error("Error fetching the report data:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://api.confidanto.com/weekly-reporting-google-ads/compare-campaigns-data"
      )
      .then((response) => {
        setReportDataDetailed(response.data);
      })
      .catch((error) => {
        console.error("Error fetching the report data:", error);
      });
  }, []);

  if (!reportData || !reportDataDetailed) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingAnimation />
      </div>
    );
  }

  // Filter significant drops and increases
  const filterSignificantChanges = (campaigns, status) => {
    return campaigns.filter(
      (campaign) =>
        campaign.status === status &&
        parseFloat(Math.abs(campaign.changePercent)) > 10
    );
  };
  //console.log(filterSignificantChanges);

  const brandDrops = filterSignificantChanges(
    reportData.brandCampaigns,
    "down"
  );
  const nonBrandDrops = filterSignificantChanges(
    reportData.nonBrandCampaigns,
    "down"
  );
  //console.log(nonBrandDrops);

  const brandIncreases = filterSignificantChanges(
    reportData.brandCampaigns,
    "up"
  );
  const nonBrandIncreases = filterSignificantChanges(
    reportData.nonBrandCampaigns,
    "up"
  );

  ////console.log(reportData.brandCampaigns);
  ////console.log(brandDrops);
  // Generate the result message
  const numberOfSignificantDrops = brandDrops.length + nonBrandDrops.length;
  //console.log(numberOfSignificantDrops);
  const numberOfSignificantIncreases =
    brandIncreases.length + nonBrandIncreases.length;

  let resultMessage = `Spend was \$${reportData.totalCostThisWeek} ${reportData.statusCost} from ${reportData.thisWeekStartDate} ${reportData.thisWeekStartDay} due to `;
  if (numberOfSignificantDrops > 0) {
    resultMessage += `a huge drop seen in the following campaigns:`;
  } else if (numberOfSignificantIncreases > 0) {
    resultMessage += `a huge increase seen in the following campaigns:`;
  } else {
    resultMessage += "no significant changes in campaigns.";
  }

  const formatPercent = (value) => {
    //console.log(value);
    const formattedValue = value;
    const icon = value >= 0 ? "fa-arrow-up" : "fa-arrow-down";
    return (
      <span className="flex text-base">
        {value > 0 ? (
          <IoIosArrowRoundUp className="text-green-500 text-lg" />
        ) : value == 0 ? (
          ""
        ) : (
          <IoIosArrowRoundDown className="text-red-500 text-lg" />
        )}
        {value > 0
          ? `+${formattedValue}%`
          : value == 0
            ? `${formattedValue}%`
            : `${formattedValue}%`}
      </span>
    );
  };

  const handleSendDailyReport = async () => {
    if (componentRef.current) {
      const htmlContent = componentRef.current.innerHTML;

      try {
        const response = await fetch(
          "https://api.confidanto.com/send-daily-report-on-email",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              to: receiverEmail, // replace with actual recipient
              subject: "Daily Report",
              htmlContent: htmlContent,
            }),
          }
        );

        if (response.ok) {
          alert("Email sent successfully");
          setReceiverEmail("");
        } else {
          alert("Error sending email:", response.statusText);
        }
      } catch (error) {
        console.error("Network error:", error);
      }
    }
  };

  const cancelChanges = () => {
    setShowColumnsMenu(false);
  };

  const openColumnsMenu = () => {
    setShowColumnsMenu(true);
  };

  // Date Helper Functions
  const nthNumber = (number) => {
    if (number > 3 && number < 21) return "th";
    switch (number % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const getDayFromDate = (date) => {
    let d = new Date(date);
    // //console.log("DATE:",d.getDate(),nthNumber(d),d)
    let dateString = d.getDate() + nthNumber(d);
    return dateString;
  };

  const getDayAndMonthFromDate = (date) => {
    let d = new Date(date);
    // //console.log("DATE:",d.getDate(),nthNumber(d),d)
    let dateString =
      d.getDate() +
      nthNumber(d) +
      " " +
      d.toLocaleString("default", { month: "long" });
    return dateString;
  };

  return (
    <div>
      <div className="w-full flex justify-end items-start p-4 m-4">
        <div className="flex mx-4">
          <input
            type="text"
            id="email"
            className="bg-white text-gray-900 text-base block w-64 p-2 mx-4 border border-gray-300 focus:outline-none"
            placeholder="Enter email"
            onChange={(e) => setReceiverEmail(e.target.value)}
            required
          />
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center justify-center"
            onClick={handleSendDailyReport}
          >
            Send Report
          </button>
        </div>

      </div>
      <div
        className="bg-white m-4 rounded-md p-6 font-roboto"
        ref={componentRef}
      >
          <div className="">
            <h1 className="text-4xl font-bold">Weekly  Reporting</h1>
          </div>
        

        <div className="flex justify-between items-center my-4">
          <div>
            <h1 className="text-2xl text-gray-600 font-semibold">
              Week:{" "}
              
            </h1>
          </div>
          <div className="flex">
            <div className="flex-col justify-center items-center mx-4">
              <h1 className="text-base text-gray-500 text-center">
                Media Spend
              </h1>
              <h1 className="text-2xl text-center">
                {reportData.totalCostThisWeek}
              </h1>
            </div>
            <div className="flex-col justify-center items-center mx-4">
              <h1 className="text-base text-gray-500 text-center">Clicks</h1>
              <h1 className="text-2xl text-center">
                {reportData.totalClicksThisWeek}
              </h1>
            </div>
            <div className="flex-col justify-center items-center mx-4">
              <h1 className="text-base text-gray-500 text-center">
                Impressions
              </h1>
              <h1 className="text-2xl text-center">
                {reportData.totalImpressionsThisWeek}
              </h1>
            </div>
          </div>
        </div>
        {/* Horizontal Table for additional fields */}
        <div className="bg-white my-4 rounded-md  mb-8">
          
          <div className="flex justify-between">
            <div className="">
            <h2 className="text-xl font-semibold text-gray-600">
            Metrics Comparison
          </h2>
            </div>
          <div className="relative flex flex-row-reverse justify-around items-center">
          <button
            className="bg-blue-500 text-gray-200 px-4 py-2 rounded hover:shadow-lg"
            onClick={openColumnsMenu}
          >
            Columns
          </button>
          {showColumnsMenu && (
            <div className="p-4 bg-white rounded-lg shadow-md max-w-md mx-auto">
              <h2 className="text-lg font-semibold mb-4">
                Modify columns for ad groups
              </h2>
              <div className="grid grid-cols-3 gap-4 mb-4">
                {Object.keys(visibleColumns).map((column) => (
                  <div key={column} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={visibleColumns[column]}
                      onChange={() => handleColumnToggle(column)}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <label className="ml-2 text-gray-700">
                      {column.charAt(0).toUpperCase() + column.slice(1)}
                    </label>
                  </div>
                ))}
              </div>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={cancelChanges}
                  className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
          <button
            className="bg-transparent  text-gray-600 px-4 py-2 rounded  hover:bg-slate-100"
            // onClick={() => setShowDownloadOptions(!showDownloadOptions)}
          >
            <IoMdShareAlt className="ml-5 text-2xl" />
            Share
          </button>
        </div>
        </div>
          <table className="min-w-full bg-gray-100 border border-gray-200 mt-4">
            <thead>
              <tr className="bg-gray-200 text-center">
                {visibleColumns.date && <th className="p-2 border-b">Date</th>}
                {visibleColumns.cost && <th className="p-2 border-b">Cost</th>}
                {visibleColumns.clicks && (
                  <th className="p-2 border-b">Clicks</th>
                )}
                {visibleColumns.impressions && (
                  <th className="p-2 border-b">Impressions</th>
                )}
                {visibleColumns.ctr && <th className="p-2 border-b">CTR</th>}
                {visibleColumns.avgCpc && (
                  <th className="p-2 border-b">Avg CPC</th>
                )}
                {visibleColumns.conversions && (
                  <th className="p-2 border-b">Conversions</th>
                )}
                {visibleColumns.costPerConv && (
                  <th className="p-2 border-b">Cost/Conv</th>
                )}
                {visibleColumns.convRate && (
                  <th className="p-2 border-b">Conv Rate</th>
                )}
                {/*{visibleColumns.imprAbsTop && (
                  <th className="p-2 border-b">Impr Abs Top</th>
                )}
                {visibleColumns.imprTop && (
                  <th className="p-2 border-b">Impr Top</th>
                )}*/}
              </tr>
            </thead>
            <tbody>
              <tr className="border-t bg-gray-100 text-center">
                {visibleColumns.date && (
                  <td className="p-2 border-b">
                    {getDayFromDate(reportData.thisWeekStartDate)} -{" "}
                    {getDayAndMonthFromDate(reportData.thisWeekEndDate)}
                  </td>
                )}
                {visibleColumns.cost && (
                  <td className="p-2 border-b">
                    ${reportData.additionalFieldsThisWeek.total_cost.toFixed(2)}
                  </td>
                )}
                {visibleColumns.clicks && (
                  <td className="p-2 border-b">
                    {Math.floor(reportData.additionalFieldsThisWeek.total_clicks)}
                  </td>
                )}
                {visibleColumns.impressions && (
                  <td className="p-2 border-b">
                    {Math.floor(reportData.additionalFieldsThisWeek.total_impressions)}
                  </td>
                )}
                {visibleColumns.ctr && (
                  <td className="p-2 border-b">
                    {reportData.additionalFieldsThisWeek.total_ctr.toFixed(2)}
                  </td>
                )}
                {visibleColumns.avgCpc && (
                  <td className="p-2 border-b">
                    $
                    {reportData.additionalFieldsThisWeek.total_avg_cpc.toFixed(
                      2
                    )}
                  </td>
                )}
                {visibleColumns.conversions && (
                  <td className="p-2 border-b">
                    {reportData.additionalFieldsThisWeek.total_conversions}
                  </td>
                )}
                {visibleColumns.costPerConv && (
                  <td className="p-2 border-b">
                    $
                    {reportData.additionalFieldsThisWeek.total_cost_per_conv &&
                      reportData.additionalFieldsThisWeek.total_cost_per_conv.toFixed(
                        2
                      ) / 1000000}
                  </td>
                )}
                {visibleColumns.convRate && (
                  <td className="p-2 border-b">
                    {reportData.additionalFieldsThisWeek.total_conv_rate.toFixed(
                      2
                    )}
                  </td>
                )}
                {/*<td className="p-2 border-b">
                  {reportData.additionalFieldsThisWeek.total_impr_abs_top.toFixed(
                    2
                  )}
                </td>
                <td className="p-2 border-b">
                  {reportData.additionalFieldsThisWeek.total_impr_top.toFixed(
                    2
                  )}
                </td>*/}
              </tr>
              <tr className="border-t bg-gray-100 text-center">
                {visibleColumns.date && (
                  <td className="p-2 border-b">
                    {getDayFromDate(reportData.lastWeekStartDate)} -{" "}
                    {getDayAndMonthFromDate(reportData.lastWeekEndDate)}
                  </td>
                )}
                {visibleColumns.cost && (
                  <td className="p-2 border-b">
                    ${reportData.additionalFieldsLastWeek.total_cost.toFixed(2)}
                  </td>
                )}
                {visibleColumns.clicks && (
                  <td className="p-2 border-b">
                    {Math.floor(reportData.additionalFieldsLastWeek.total_clicks)}
                  </td>
                )}
                {visibleColumns.impressions && (
                  <td className="p-2 border-b">
                    {Math.floor(reportData.additionalFieldsLastWeek.total_impressions)}
                  </td>
                )}
                {visibleColumns.ctr && (
                  <td className="p-2 border-b">
                    {reportData.additionalFieldsLastWeek.total_ctr.toFixed(2)}
                  </td>
                )}
                {visibleColumns.avgCpc && (
                  <td className="p-2 border-b">
                    $
                    {reportData.additionalFieldsLastWeek.total_avg_cpc.toFixed(
                      2
                    )}
                  </td>
                )}
                {visibleColumns.conversions && (
                  <td className="p-2 border-b">
                    {reportData.additionalFieldsLastWeek.total_conversions}
                  </td>
                )}
                {visibleColumns.costPerConv && (
                  <td className="p-2 border-b">
                    $
                    {reportData.additionalFieldsLastWeek.total_cost_per_conv &&
                      reportData.additionalFieldsLastWeek.total_cost_per_conv.toFixed(
                        2
                      ) / 1000000}
                  </td>
                )}
                {visibleColumns.convRate && (
                  <td className="p-2 border-b">
                    {reportData.additionalFieldsLastWeek.total_conv_rate.toFixed(
                      2
                    )}
                  </td>
                )}
                {/*<td className="p-2 border-b">
                  {reportData.additionalFieldsLastWeek.total_impr_abs_top.toFixed(
                    2
                  )}
                </td>
                <td className="p-2 border-b">
                  {reportData.additionalFieldsLastWeek.total_impr_top.toFixed(
                    2
                  )}
                </td>*/}
              </tr>
              <tr className="border-t bg-gray-300 ">
                {visibleColumns.date && (
                  <td className="p-2 border-b text-center">Difference</td>
                )}
                {visibleColumns.cost && (
                  <td className="p-2 border-b text-center">
                    {reportData.changePercentCost &&
                      formatPercent(reportData.changePercentCost)}
                  </td>
                )}
                {visibleColumns.clicks && (
                  <td className="p-2 border-b text-center">
                    {reportData.changePercentClicks &&
                      formatPercent(reportData.changePercentClicks)}
                  </td>
                )}
                {visibleColumns.impressions && (
                  <td className="p-2 border-b text-center">
                    {reportData.changePercentImpressions &&
                      formatPercent(reportData.changePercentImpressions)}
                  </td>
                )}
                {visibleColumns.ctr && (
                  <td className="p-2 border-b text-center">
                    {reportData.changePercentCTR &&
                      formatPercent(reportData.changePercentCTR)}
                  </td>
                )}
                {visibleColumns.avgCpc && (
                  <td className="p-2 border-b text-center">
                    {reportData.changePercentAvgCPC &&
                      formatPercent(reportData.changePercentAvgCPC)}
                  </td>
                )}
                {visibleColumns.conversions && (
                  <td className="p-2 border-b text-center">
                    {reportData.changePercentConversions &&
                      formatPercent(reportData.changePercentConversions)}
                  </td>
                )}
                {visibleColumns.costPerConv && (
                  <td className="p-2 border-b text-center">
                    {reportData.changePercentCostPerConv &&
                      formatPercent(reportData.changePercentCostPerConv)}
                  </td>
                )}
                {visibleColumns.convRate && (
                  <td className="p-2 border-b text-center">
                    {reportData.changePercentConvRate &&
                      formatPercent(reportData.changePercentConvRate)}
                  </td>
                )}
                {/*<td className="p-2 border-b">
                  {reportData.additionalFieldsLastWeek.total_impr_abs_top.toFixed(
                    2
                  )}
                </td>
                <td className="p-2 border-b">
                  {reportData.additionalFieldsLastWeek.total_impr_top.toFixed(
                    2
                  )}
                </td>*/}
              </tr>
            </tbody>
          </table>
        </div>
        {initialParagraph && (
          <div className="bg-gray-100 my-4 rounded-md p-6">
            <p className="text-lg">{initialParagraph}</p>
          </div>
        )}
        <div className="bg-gray-100 my-4 rounded-md p-6 text-xl">
          <p className="text-xl">{resultMessage}</p>
          <div className="mt-4">
            {numberOfSignificantDrops > 0 && (
              <div>
                <h2 className="text-lg font-semibold">Significant Drops:</h2>
                {brandDrops.length > 0 && (
                  <>
                    <h3 className="font-semibold">Brand Specific Campaigns:</h3>
                    <div>
                      {brandDrops.map((campaign) => (
                        <p key={campaign.campaign}>
                          {campaign.campaign} -{" "}
                          {Math.abs(campaign.changePercent)}%
                        </p>
                      ))}
                    </div>
                  </>
                )}
                {nonBrandDrops.length > 0 && (
                  <>
                    <h3 className="font-semibold mt-4">
                      Non-Brand Specific Campaigns:
                    </h3>
                    <div>
                      {nonBrandDrops.map((campaign) => (
                        <p key={campaign.campaign}>
                          {campaign.campaign} -{" "}
                          {Math.abs(campaign.changePercent)}%
                        </p>
                      ))}
                    </div>
                  </>
                )}
              </div>
            )}
            {numberOfSignificantIncreases > 0 && (
              <div className="mt-4">
                <h2 className="text-lg font-semibold">
                  Significant Increases:
                </h2>
                {brandIncreases.length > 0 && (
                  <>
                    <h3 className="font-semibold">Brand Specific Campaigns:</h3>
                    <div>
                      {brandIncreases.map((campaign) => (
                        <p key={campaign.campaign}>
                          {campaign.campaign} - {campaign.changePercent}%
                        </p>
                      ))}
                    </div>
                  </>
                )}
                {nonBrandIncreases.length > 0 && (
                  <>
                    <h3 className="font-semibold mt-4">
                      Non-Brand Specific Campaigns:
                    </h3>

                    <div>
                      {nonBrandIncreases.map((campaign) => (
                        <p key={campaign.campaign}>
                          {campaign.campaign} - {campaign.changePercent}%
                        </p>
                      ))}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <CategoryWeekly />
        </div>


        <div className="flex flex-col">
          <AddCampaignTableWeekly />
        </div>
        <div className="flex flex-col mb-40">
          <AddChart />
        </div>
      </div>
    </div>
  );
}

export default GoogleAdsWeeklyReporting;
