import axios from 'axios'
import React, { useState, useEffect  } from 'react'
import LoadingAnimation from '../components/LoadingAnimation'

function AdGroupDateSegment(props) {
    const [currencySymbol, setCurrencySymbol] = useState("Rs ")  

    const [columns,setColumns] = useState([
        {key:"date", title:"date", visible:true} ,
        {key:"cost", title:"cost", visible:true} ,
        {key:"clicks", title:"clicks", visible:true} ,
        {key:"impressions", title:"impressions", visible:true} ,
        {key:"conversions", title:"conversions", visible:true} ,
        {key:"cost_per_conversion", title:"cost_per_conversion", visible:true} ,
        {key:"ctr", title:"ctr", visible:true} ,
        {key:"average_cpc", title:"average_cpc", visible:true} ,
        {key:"interaction_rate", title:"interaction_rate", visible:true} ,
        {key:"interactions", title:"interactions", visible:true} ,
    // {key:"ad_group_id", title:"ad_group_id", visible:true} ,
    // {key:"ad_group_name", title:"ad_group_name", visible:true} ,
    // {key:"ad_id", title:"ad_id", visible:true} ,
    // {key:"ad_name", title:"ad_name", visible:true} ,
    // {key:"campaign_id", title:"campaign_id", visible:true} ,
    // {key:"campaign_name", title:"campaign_name", visible:true} ,
    // {key:"descriptions", title:"descriptions", visible:true} ,
    // {key:"final_urls", title:"final_urls", visible:true} ,
    // {key:"headlines", title:"headlines", visible:true} ,
    // {key:"labels", title:"labels", visible:true} ,
    // {key:"primary_status", title:"primary_status", visible:true} ,
    // {key:"status", title:"status", visible:true} ,

    ])

    const [data,setData] = useState([])
    let [total, setTotal] = useState({
        "impressions":0,
        "cost":0,
        "clicks":0,

        "impressions":0,
        "conversions":0,
        "cost_per_conversion":0,
        "ctr":0,
        "average_cpc":0,
        "conversion_rate":0
    })
    

    useEffect(()=>{
        try {
            axios.post("https://api.confidanto.com/get-segment-ad-group-ad-data",{
                customer_id:4643036315,
                start_date:String(props.startDate),
                end_date:String(props.endDate),
                group_by:props.groupBy
            })
            .then((res)=>{
                console.log(res);
                setData(res.data)
                console.log("bhugctdxrezxrctfvygbhn",props.startDate,props.endDate,props.groupBy)

            })
        } catch (e) {console.log(e)}

    },[props.groupBy,props.startDate,props.endDate])

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    
    function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  return (
    <div>
        <div className=' flex flex-row justify-between items-center'>
            <h2 className="text-2xl font-semibold capitalize py-4">
                {capitalizeFirstLetter(props.groupBy)+"-By-"+capitalizeFirstLetter(props.groupBy)} Ad Group
            </h2>
            {/* <button className='bg-blue-600 text-white py-2 px-4 rounded-lg font-semibold w-fit'>
                Columns
            </button> */}
        </div>

    
    <div className="flex-grow overflow-auto mb-12">
        {data.length > 0 ? (
        <table className="min-w-full bg-white rounded-lg overflow-hidden shadow-md font-roboto">
            <thead>
            <tr className="bg-gray-200 normal-case text-sm leading-normal">
                
                {columns
                .filter((col) => col.visible)
                .map((col) => (
                    <th
                    key={col.key}
                    className="py-3 px-6 text-left font-medium"
                    >
                    {col.title}
                    </th>
                ))}
            </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light font-roboto">
            {data.map((item, index) => (
                          <tr
                            key={index}
                          >

                            {columns.filter(col => col.visible).map(col => (
                              <td key={col.key} className="py-3 px-6 text-left">
                                {col.key !== "status" && (
                                  Array.isArray(item[col.key]) ? item[col.key].join(', ') : item[col.key]
                                )}
                                {/* Render the status cell */}
                                {col.key === "status" ? (
                                  <div className="flex items-center">
                                    {item.status === "ENABLED" && (
                                      <div className="w-3 h-3 bg-green-500 rounded-full mr-2"></div>
                                    )}
                                    {item.status === "PAUSED" && (
                                      <div className="w-3 h-3 bg-gray-500 rounded-full mr-2"></div>
                                    )}
                                    {item.status === "REMOVED" && (
                                      <div className="w-3 h-3 bg-red-500 rounded-full mr-2"></div>
                                    )}
                                    {item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase()}
                                  </div>
                                ) : null}
                              </td>
                            ))}
                          </tr>
                        ))}
            </tbody>
               
                <tfoot className="bg-gray-100">
                  <tr className="text-gray-700 font-semibold">
                  {/* <td className="px-4 py-2 ">Total</td> */}

                          {
                            columns.filter(col => col.visible).map(col => {
                              // //console.log("KEY",col.key)
                              total[col.key] = 0
                            })

                          }
                          {
                            data.map(d => {
                              Object.keys(d).forEach(val => {
                                Object.keys(total).forEach(totalVal => {
                                  if (totalVal == val) {
                                    total[val] = total[val] + d[val]
                                  }
                                })
                              })
                            })
                          }

                          {
                            Object.entries(total).map((t, k) => {
                              //console.log("type",typeof(t[1]))
                              let tempval = ""
                              let ignoreColumns = ['id', 'customer_id', 'amount_micros']
                              if (typeof (t[1]) == "number") {
                                if (ignoreColumns.indexOf(t[0]) == -1) {
                                  tempval = (t[1].toFixed(2))
                                }
                              }
                              return <td className="py-3 px-6 text-left">{tempval}</td>
                            }
                            )

                          }
                   
                  </tr>
                </tfoot>
        </table>
        ) : (
        <div className="flex justify-center items-center h-40 mt-8">
            <LoadingAnimation />
        </div>
        )}
    </div>
    
    </div>
  )
}

export default AdGroupDateSegment