import React, { useState, useEffect } from "react";
import axios from "axios";

const SelectProject = ({ onSelectProject }) => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");

  const email = localStorage.getItem("email");

  const fetchProjects = async () => {
    try {
      const response = await axios.post(
        "https://api.confidanto.com/projects-data/fetch-project-list",
        { email }
      );
      setProjects(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [email]);

  const handleProjectChange = (event) => {
    const projectId = event.target.value;
    setSelectedProject(projectId);
    onSelectProject(projectId); // Call the callback function
  };

  return (
    <select
      // value={selectedProject}
      onChange={handleProjectChange}
      className="border border-blue-100 p-2 rounded text-gray-600"
      // defaultChecked={localStorage.getItem("project_id")}
      // defaultValue={localStorage.getItem("project_name")}
      // value={localStorage.getItem("project_id")}
    >
      <option value="" selected={localStorage.getItem("project_id") == -1}> Project</option>
      {projects.map((project) => (
        <option key={project.id} value={project.id}
          selected={localStorage.getItem("project_id") == project.id}
        >
          {project.name}
        </option>
      ))}
    </select>
  );
};

export default SelectProject;
