import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Card, CardContent, Typography } from '@mui/material';
import axios from 'axios';
import { IoMdClose } from "react-icons/io";


ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChartGroups = (props) => {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState(null); // Store chart data here
  const [error, setError] = useState(null); // Store error state


  const [titleArray,setTitleArray] = useState(props.labels)

  const [chartParameter,setChartParameter] = useState("impressions")
  const [allParameters, setAllParameters] = useState(
    [
      {title:"Avg Cost", key:"avg_cost"},
      {title:"Avg Cpm", key:"avg_cpm"},
      {title:"Clicks", key:"clicks"},
      {title:"Conversion", key:"conversion"},
      {title:"Cost/Conv", key:"cost_per_conv"},
      {title:"Ctr", key:"ctr"},
      {title:"Impressions", key:"impressions"},
      {title:"Interaction Rate", key:"interaction_rate"},
      {title:"Interactions", key:"interactions"},
      {title:"Invalid Click rate", key:"invalid_click_rate"},
      {title:"Invalid Clicks", key:"invalid_clicks"} 
      ]
  )

  const [allValZeroFlag, setAllValZeroFlag] = useState(false)

  function changeChartParameter(e){
    //console.log(e.target.value,chartParameter);
    setChartParameter(e.target.value)
    //console.log(e.target.value,chartParameter);
    
  }

  useEffect(() => {
    setLoading(true);


    let totalValues = 0 
    props.data.map((i)=>{
      totalValues = totalValues + i.total[chartParameter]
      }
    )

    if(totalValues <= 0){
      setAllValZeroFlag(true)
    }else{
      setAllValZeroFlag(false)
    }
    


    //console.log(" totalValues: ",totalValues, " AllValZeroFlag", allValZeroFlag);


    const TitleWithNumbers = props.data.map((i)=>{
      let string = i.group_name + " " +i.total[chartParameter]

      return string
      // //console.log(i);
    })

    // //console.log("TitleWithNumbers ",TitleWithNumbers);

    const chartData = {
        // labels: titleArray,
        labels: TitleWithNumbers,
        datasets: [
        {
            label: '',  
            data: props.data.map(i=>i.percentage[chartParameter]),
            // backgroundColor: ['#36A2EB', '#4BC0C0', '#FFCE56', '#FF6384', '#36A2EB'],
            hoverOffset: 4,
        },
        ],
    };

        
    setChartData({ chartData }); // Set the chart data with total count
    setLoading(false);

  }, [chartParameter]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          boxWidth: 10,
        },
      }
    },
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Card className='my-4'>
      <CardContent>
        <div className="flex flex-row justify-between items-center">
          <div className="space-y-2">
            <h4 className=' font-semibold text-2xl'>
              {titleArray.map((i,index)=>{
                let comma = ","
                if(index == titleArray.length -1 ){
                  comma = "."
                }
                return <span>{i+comma} </span>
              })}
            </h4>
            <select name="chartParameter" id="chartParameter" 
            className='p-2'
            onChange={(e)=>{changeChartParameter(e)}}
          
            >
              {allParameters.map(i=>(
                <option value={i.key}
                selected={chartParameter==i.key}
                >{i.title}</option>
              ))}
            </select>
          </div>
          <button
          className='p-2 text-lg bg-red-600 text-white rounded-sm'  
          onClick={(e)=>{props.removeArrayIndex(props.arrayIndex)}}><IoMdClose /></button>
        </div>
        <div className="flex justify-center items-center w-full h-full">
          <div className="w-full h-[444px]">
            {chartData ? (
              <>
              {allValZeroFlag?
                <>
                <div className='w-full h-full flex flex-col justify-center items-center'>
                  <h4 className='text-xl'>No Data Found</h4>
                  <p className='text-md text-slate-400'>Try changing the date range</p>
                </div>
                </>
                :
                <>
                  <Doughnut data={chartData.chartData}  
                  options={options}
                  />
                </>}
              </>
              
            ) : (
              <div>No Data Available</div>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default DonutChartGroups;
