import React from 'react';

import { Header } from '../../components';

const Demographics = () => {

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Demographics" />
      <p>Demographics</p>
    </div>
  );
};

export default Demographics;
